<div *ngIf="!(isSidebarLoading$ | async); else loadingScreen" class="h-full flex flex-col">
  <div class="bg-white">
    <app-commitment-sidebar-title
      [showBackButton]="!isEdit || (currentPage$ | async) !== COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE"
      [title]="isEdit ? 'Edit Contract' : 'Add Contract'"
      (goBack)="goBack()"
      (needHelpClicked)="needHelpClicked($event)"
      [showHelpButton]="true"
    ></app-commitment-sidebar-title>
  </div>

  <ng-container *ngIf="showHelp; then help; else generalContent"></ng-container>
  <ng-template #help>
    <div class="px-7 bg-shade_7 h-full">
      <app-commitments-sidebar-help
        [type]="COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT"
      ></app-commitments-sidebar-help>
    </div>
  </ng-template>

  <ng-template #generalContent>
    <app-commitment-entry-text
      *ngIf="(currentPage$ | async) === 1"
      entryType="GeneralInfo"
      text="General Information"
    ></app-commitment-entry-text>

    <ng-scrollbar
      [ngClass]="{ 'first-page': (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }"
      class="std-scrollbar wider-track"
      appearance="compact"
      visibility="native"
      orientation="vertical"
    >
      <div
        [ngClass]="{ 'mb-5 mt-4 horizontal-spacing': (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }"
        class="scrollable-content"
      >
        <form
          class="flex flex-col flex-1"
          #firstPageForm="ngForm"
          role="form"
          *ngIf="(currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE; else secondPage"
          [@fadeInGeneral]
        >
          <div class="flex-1 flex flex-col">
            <app-upload-commitments-sidebar
              [commitmentType]="COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT"
            ></app-upload-commitments-sidebar>

            <div class="flex justify-between items-center gap-2.5">
              <div class="w-full">
                <div
                  (click)="isDropdownShown.service_provider = true"
                  *ngIf="!isCompanyInput"
                  class="w-full p-float-label p-input-icon-right mb-2 mt-6 cursor-pointer"
                >
                  <input
                    pInputText
                    required
                    readonly
                    #optionsTrigger="cdkOverlayOrigin"
                    cdkOverlayOrigin
                    class="w-full cursor-pointer pr-8 overflow-hidden text-nowrap text-ellipsis"
                    id="serviceProviderUserId"
                    name="serviceProviderUserId"
                    #serviceProviderModel="ngModel"
                    [(ngModel)]="model.service_provider.name"
                    [ngClass]="{
                      'ng-invalid ng-dirty': serviceProviderModel.invalid && serviceProviderModel.touched
                    }"
                  />
                  <label>Company Name</label>
                  <small
                    [ngClass]="{
                      hidden: serviceProviderModel.valid || serviceProviderModel.untouched
                    }"
                    class="p-error text-uppercase"
                    >REQUIRED</small
                  >
                  <i
                    class="-mt-[4px]"
                    [ngClass]="isDropdownShown.service_provider ? 'dropdown-icon opened' : 'dropdown-icon closed'"
                  ></i>

                  <app-overlay-general
                    [(show)]="isDropdownShown.service_provider"
                    [trigger]="optionsTrigger"
                    [overlayPositions]="dropdownOverlayPositions"
                  >
                    <app-company-dropdown
                      (optionSelected)="selectedCompany($event, serviceProviderModel)"
                      [hideAddCompany]="!user?.isManager"
                    ></app-company-dropdown>
                  </app-overlay-general>
                </div>

                <div
                  class="form w-full company-input p-float-label p-input-icon-right mb-2 mt-6"
                  *ngIf="isCompanyInput"
                >
                  <input
                    required
                    class="w-full"
                    type="text"
                    pInputText
                    placeholder="Temporary Company Name"
                    name="serviceProviderUserId"
                    #serviceProviderUserId="ngModel"
                    [(ngModel)]="model.service_provider.name"
                    [ngClass]="{
                      'ng-invalid ng-dirty': serviceProviderUserId.invalid && serviceProviderUserId.touched
                    }"
                    (change)="updateContract()"
                  />
                  <i>
                    <span
                      (click)="revertToDropDown($event, serviceProviderUserId)"
                      class="icon icon-add cursor-pointer hover:text-color_reject before:rotate-45"
                    >
                    </span>
                  </i>
                  <label>Temporary Company Name</label>
                  <small
                    [ngClass]="{
                      hidden: serviceProviderUserId.valid || serviceProviderUserId.untouched
                    }"
                    class="p-error text-uppercase"
                    >REQUIRED</small
                  >
                </div>
              </div>

              <app-floating-input
                id="title"
                label="Contract Title"
                placeholder="Contract Title"
                name="title"
                (change)="updateContract()"
                [(model)]="model.title"
                class="w-full"
                required
              ></app-floating-input>
            </div>

            <div class="flex justify-between items-center gap-2.5">
              <app-input-calendar
                #added_at_calendar
                [required]="true"
                [date]="model.added_at"
                [minStartDate]="projectData?.start_date"
                [maxDate]="firstSpendEntryDate"
                (dateChanged)="updateContractDate($event)"
                placeholder="Contract Date"
                class="w-full"
                [iconClass]="{
                  iconPosition: 'p-input-icon-right',
                  icon: 'icon-calendar-box text-color_secondary'
                }"
              ></app-input-calendar>

              <app-dropdown
                [optionLabel]="null"
                [optionValue]="null"
                [disabled]="
                  (hasCommitments$ | async) ||
                  (!user.isManager && model?.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED)
                "
                [required]="true"
                placeholder="Contract Status"
                label="Contract Status"
                id="approval_status"
                name="approval_status"
                [(model)]="model.approval_status"
                (modelChange)="updateContract()"
                [options]="getApprovalStatuses()"
                class="w-full"
              >
                <ng-template #selectedContent #optionContent let-option>
                  <div class="capitalize text-color_primary text-medium">{{ option }}</div>
                </ng-template>
              </app-dropdown>
            </div>

            <app-floating-textarea
              [autoResize]="false"
              class="w-full"
              id="description"
              name="description"
              [(model)]="model.description"
              (change)="updateContract()"
              label="Contract Description (optional)"
              placeholder="Contract Description (optional)"
            ></app-floating-textarea>
          </div>
        </form>
        <ng-template #secondPage>
          <div [@fadeInGeneral]>
            <div *ngFor="let entry of spendEntriesData; let index = index; trackBy: trackByIndex">
              <app-spend-entry
                #spendEntries
                [commitmentAddedAt]="model.added_at"
                [model]="entry"
                [projectData]="projectData"
                [index]="index"
                [commitmentType]="COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT"
                [disableAddDescription]="model.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED && !!model?.id"
                [disableRemovedEntry]="model.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED && !!model?.id"
                [disabledAddDescriptionTooltip]="
                  model.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED && !!model?.id
                    ? 'Cannot add description to an approved contract'
                    : 'Select a line item first'
                "
              ></app-spend-entry>
            </div>
            <div class="horizontal-spacing mt-5 mb-2">
              <app-simple-button
                size="large"
                [wrapperClass]="{
                  'border-2 bg-transparent w-full rounded-[3px] ': true,
                  'border-color_secondary text-color_secondary': (canAddContractSpendEntries$ | async),
                  'border-shade_3 text-shade_3': !(canAddContractSpendEntries$ | async)
                }"
                (click)="addSpendEntry()"
                [disabled]="!(canAddContractSpendEntries$ | async)"
                >Add Spend Entry
              </app-simple-button>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-scrollbar>

    <div
      [ngClass]="{
        bordered: (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.LAST_PAGE
      }"
      class="bottom-container text-shade_1 text-base font-bold"
    >
      <span [ngClass]="{ invisible: (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }" class="flex-1"
        >Total Contract</span
      >
      <span
        class="flex-1 justify-center flex"
        [ngClass]="{
          negative: (totalContractCost$ | async) < 0,
          invisible: (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE
        }"
      >
        <span *ngIf="(totalContractCost$ | async) !== 0">{{ totalContractCost$ | async | money: 'positiveComa' }}</span>
        <span *ngIf="(totalContractCost$ | async) === 0">$ {{ totalContractCost$ | async | number }}</span>
      </span>
      <div class="flex items-center">
        @if ((currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE && !isLoading) {
          <app-simple-button
            [wrapperClass]="{
              rounded: true
            }"
            [disabled]="isLoading"
            (click)="incrementPage()"
          >
            Next
          </app-simple-button>
        } @else if (!isLoading) {
          <app-simple-button
            [wrapperClass]="{
              'bg-color_accent rounded': true
            }"
            [disabled]="isLoading"
            (click)="save()"
          >
            Submit
          </app-simple-button>
        } @else {
          <app-simple-button
            [wrapperClass]="{
              rounded: true
            }"
            [disabled]="isLoading"
          >
            <span class="icon-refresh animate-spin"></span>
          </app-simple-button>
        }
      </div>
    </div>
  </ng-template>
</div>

<ng-template #loadingScreen>
  <div class="flex w-full h-full items-center justify-center">
    <div class="notify-msg animate-pulsating-border">
      <div class="icon-logo-box-hex"></div>
    </div>
  </div>
</ng-template>
