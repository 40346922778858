import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { REST_DRIVE_FILES } from 'src/app/restApi/RestRoutes';
import { NotificationsService } from '../../../services/notifications.service';
import {
  discardMessagingGroupCache,
  loadSearchedMessagingUsers,
  removeNewGroupDiscussionMember,
  setMessagingView,
  updateNewGroupData,
} from '../../../store/messages/messages.actions';
import {
  MESSAGING_VIEWS,
  MessagingUsersTableAction,
} from '../../../store/messages/messages.interfaces';
import {
  getLastNonDraftSentMessageId,
  getNewDiscussionMembers,
} from '../../../store/messages/messages.selectors';
import { getErrorMessage } from '../../constants/messages.constants';
import { RichTextEditorComponent } from '../../inputs/rich-text-editor/rich-text-editor.component';
import { OverlayGeneralComponent } from '../../overlays/overlay-general/overlay-general.component';
import { DiscussionViewCommonComponent } from '../discussion-view-common/discussion-view-common.component';
import { MessagingUsersTableComponent } from '../messaging-users-table/messaging-users-table.component';

@Component({
  selector: 'app-discussions-create',
  templateUrl: './discussions-create.component.html',
  styleUrls: ['./discussions-create.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    CdkOverlayOrigin,
    OverlayGeneralComponent,
    MessagingUsersTableComponent,
    NgFor,
    MatInput,
    NgIf,
    MatError,
    RichTextEditorComponent,
    AsyncPipe,
  ],
})
export class DiscussionsCreateComponent
  extends DiscussionViewCommonComponent
  implements OnInit, OnDestroy
{
  @ViewChild('subjectInput') set subjectInput(elementRef: ElementRef<MatInput>) {
    // here you get access only when element is rendered (or destroyed)
    elementRef?.nativeElement.focus();
    // cdr is needed to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
  }
  messageForm: UntypedFormGroup;
  getErrorMessage = getErrorMessage;

  lastNonDraftSentMessageId$ = this.store.select(getLastNonDraftSentMessageId);
  REST_DRIVE_FILES = REST_DRIVE_FILES;
  uploadMetaData: { message_id: number };

  discussionMembers$ = this.store.select(getNewDiscussionMembers);
  showRelatedUsers = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notif: NotificationsService,
    private cdr: ChangeDetectorRef,
  ) {
    super();

    this.messageForm = this.formBuilder.group({
      subject: [''],
      participants: [''],
      message: [''],
    });
  }

  ngOnInit(): void {
    this.handleChatMembers();
    super.handleUpload(this.discardUploads);
  }

  registerOption(event: MessagingUsersTableAction) {
    if (event.action === 'select') {
      this.store.dispatch(updateNewGroupData({ member: event.user }));
      this.messageForm.get('participants').setValue('');
      return;
    }

    if (event.action === 'remove') {
      this.store.dispatch(removeNewGroupDiscussionMember({ member: event.user }));
    }
  }

  override handleChatMembers() {
    combineLatest([this.searchedMessagingMembers$, this.discussionMembers$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([usersRelated, discussionMembers]) => {
        this.itemOptions = usersRelated.map((user) => user.name);
        this.usersData = {
          usersRelated,
          discussionMembers,
        };
      });
  }

  loadUsers() {
    this.store.dispatch(
      loadSearchedMessagingUsers({ search: this.messageForm.get('participants').value }),
    );
  }

  updateMessagingGroup(event: string, field: string) {
    const body: { message?: string; member?: any; name?: string } = {
      [field]: event,
    };
    this.store.dispatch(updateNewGroupData(body));
  }

  getOpenPosition(dropDownOverlay: HTMLElement) {
    const pos = dropDownOverlay.getClientRects()[0];

    if (this.usersData.discussionMembers.length) {
      return { x: pos?.x, y: pos?.bottom - 10 };
    }
    return { x: pos?.x, y: pos?.y + 50 };
  }

  saveDraft(message: string) {
    this.store.dispatch(updateNewGroupData({ message }));
  }

  discardUploads() {
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
    this.store.dispatch(discardMessagingGroupCache());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.notif.close();
  }
}
