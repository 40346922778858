import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AboutSectionComponent } from './about-section/about-section.component';
import { FeaturesSectionComponent } from './features-section/features-section.component';
import { LandingFooterComponent } from './landing-footer/landing-footer.component';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
import { HeroSectionComponent } from './hero-section/hero-section.component';
import { PartnersSectionComponent } from './partners-section/partners-section.component';
import { PricingSectionComponent } from './pricing-section/pricing-section.component';
import { UseCasesSectionComponent } from './use-cases-section/use-cases-section.component';
import { WorkflowSectionComponent } from './workflow-section/workflow-section.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LandingHeaderComponent,
    HeroSectionComponent,
    FeaturesSectionComponent,
    UseCasesSectionComponent,
    WorkflowSectionComponent,
    PricingSectionComponent,
    PartnersSectionComponent,
    AboutSectionComponent,
    LandingFooterComponent,
  ],
})
export class LandingComponent {}
