import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ElementRef,
  Host,
  OnInit,
  Optional,
} from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import { NOTIFICATION_TYPE } from '../constants/notification.constants';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';

/**
 * It's purpose is to have a notification sent to the user.
 * Supported states: Error, Success, Loading.
 */
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class NotificationComponent implements OnInit, AfterViewInit {
  static closeCounter = 0;
  static openCounter = 0;
  static openTime = 4000;

  isLoading = false;
  isResolved = true;
  isOpened = false;
  isNotification = true;
  message = 'This is a message';
  rootElement;
  innerHtmlCache = '';
  isSpinOpen = false;
  notificationClass = '';

  private registeredCloseFirst = false;

  constructor(
    private el: ElementRef,
    private notif: NotificationsService,
    private applicationRef: ApplicationRef,
    private router: Router,
    @Optional() @Host() private app: AppComponent,
  ) {
    NotificationsService.showEvent.subscribe((event) => {
      const message = event.msg;
      this.notificationClass = event.notificationClass || '';
      switch (event.type) {
        case NOTIFICATION_TYPE.LOADING:
          this.showLoading(message);
          break;

        case NOTIFICATION_TYPE.SUCCESS:
          if (typeof message === 'string' || (message as any) instanceof String) {
            this.showSuccess(message);
          } else {
            this.showSuccess('Success!');
          }
          break;

        case NOTIFICATION_TYPE.ERROR:
          if (typeof message === 'string' || (message as any) instanceof String) {
            this.showError(message);
          } else {
            this.showError('An error occurred.');
          }
          break;

        case NOTIFICATION_TYPE.POPUP:
          this.showPopup(message);
          break;

        case NOTIFICATION_TYPE.CLOSE:
          this.rejectPopup();
          this.close();
          break;

        case NOTIFICATION_TYPE.POPUP_RESPONSE:
          break;

        default:
          console.log('no such event in notifications');
          break;
      }
    });
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.rootElement = this.app.el; // this.el;//(this.applicationRef.components[0].instance as AppComponent).el;
  }

  listener = (ev: Event) => {
    // console.log('Popup event click registered', ev);
    if (
      !this.el.nativeElement.contains(ev.target) &&
      !this.isNotification &&
      !this.isLoading &&
      this.isOpened
    ) {
      this.rejectPopup();
    }
  };

  showError(msg) {
    this.message = msg;
    this.isLoading = false;
    this.isResolved = false;
    this.isOpened = true;
    this.isNotification = true;

    this.show();
  }

  showSuccess(msg) {
    this.message = msg;
    this.isLoading = false;
    this.isResolved = true;
    this.isOpened = true;
    this.isNotification = true;

    this.show();
  }

  showPopup(msg) {
    this.message = msg;
    this.isLoading = false;
    this.isNotification = false;
    this.isOpened = true;

    setTimeout(() => {
      document.addEventListener('click', this.listener);
    }, 100);
  }

  showLoading(message) {
    this.message = message;
    this.isLoading = true;
    this.isOpened = true;
    this.isNotification = true;
    this.show();
    this.addSpin();
  }

  addSpin() {
    let notif;
    this.isReady('notify-msg').then((data) => {
      if (this.rootElement) {
        notif = this.rootElement.nativeElement.getElementsByClassName('notify-msg')[0];
      }
      if (notif) {
        this.isSpinOpen = true;
        notif.classList.remove('visibility-hidden');
        if (this.innerHtmlCache === '') {
          this.innerHtmlCache = notif.innerHTML;
        }
        notif.innerHTML =
          '    <div class="notify-msg animate-pulsating-border">' +
          '            <span class="icon-logo-box-hex"></span>' +
          '    </div>';
      }
    });

    // this.rootElement.nativeElement.getElementsByClassName('menu-items')[0].classList.add('menu-disabled');
  }

  removeSpin() {
    const initialUrl = this.router.url;

    this.isReady('notify-msg').then((data) => {
      const notif = this.rootElement.nativeElement.getElementsByClassName('notify-msg')[0];
      // console.log(notif, this.innerHtmlCache, this.isSpinOpen);
      if (notif && initialUrl === this.router.url && this.isSpinOpen) {
        if (
          this.innerHtmlCache.split('animate-pulsating-border').length === 1 &&
          this.innerHtmlCache !== ''
        ) {
          this.isSpinOpen = false;

          notif.innerHTML = this.innerHtmlCache;
        }
        this.innerHtmlCache = '';
      }
    });

    // this.rootElement.nativeElement.getElementsByClassName('menu-items')[0].classList.remove('menu-disabled');
  }

  isReady(cssClass) {
    return new Promise((res) => {
      let notif;
      if (this.rootElement) {
        notif = this.rootElement.nativeElement.getElementsByClassName(cssClass)[0];
      }
      if (notif) {
        res(true);
      } else {
        setTimeout(() => {
          this.isReady(cssClass).then(() => {
            res(true);
          });
        }, 100);
      }
    });
  }

  close() {
    NotificationComponent.closeCounter++;

    this.registeredCloseFirst = this.isOpened === false;

    if (this.isOpened) {
      this.removeSpin();
      this.isOpened = false;
    }
  }

  rejectPopup() {
    document.removeEventListener('click', this.listener);

    NotificationsService.showEvent.emit({
      type: NOTIFICATION_TYPE.POPUP_RESPONSE,
      data: false,
    });
  }

  acceptPopup() {
    document.removeEventListener('click', this.listener);

    NotificationsService.showEvent.emit({
      type: NOTIFICATION_TYPE.POPUP_RESPONSE,
      data: true,
    });
  }

  private show() {
    NotificationComponent.openCounter++;

    if (!this.registeredCloseFirst || !this.isLoading) {
      this.timeoutClose();
    } else {
      this.timeoutCloseLoading(this.router.url);
      this.registeredCloseFirst = false;
    }

    // console.log('Open ', NotificationComponent.openCounter);
  }

  private timeoutClose() {
    const currentCounter = NotificationComponent.openCounter;
    setTimeout(() => {
      if (
        !this.isLoading &&
        currentCounter === NotificationComponent.openCounter &&
        this.isNotification
      ) {
        // console.log(NotificationComponent.openCounter, this.isLoading);
        // console.log('auto close');
        this.close();
      }
    }, NotificationComponent.openTime);
  }

  private timeoutCloseLoading(currentUrl) {
    setTimeout(() => {
      if (
        this.isLoading &&
        this.isOpened &&
        currentUrl === this.router.url &&
        this.isNotification
      ) {
        // console.log('auto timeout close');
        this.close();
      }
    }, NotificationComponent.openTime);
  }
}
