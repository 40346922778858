import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectToManagerOrContractor } from '../../guards/is-manager-or-contractor.guard';
import { AuthGuard } from '../../guards/auth-guard.service';
import { IsApproved } from '../../guards/isUserActivated-guard';
import { isManager } from '../../guards/isManager-guard';
import { DocumentsResolver } from '../../store/documents/documents.resolver';
import { ContractorsComponent } from './contractors/contractors.component';
import { CashFlowComponent } from './cash-flow/cash-flow.component';
import { SpendTemplatesComponent } from './spend-templates/spend-templates.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { DriveComponent } from './drive/drive.component';
import { ProjectsContractorComponent } from './projects/projects-contractor/projects-contractor.component';
import { CanActivateProjectsContractorGuard } from '../../guards/can-activate-projects-contractor.guard';
import { CanActivateProjectsManagerGuard } from '../../guards/can-activate-projects-manager.guard';
import { WebappComponent } from './webapp.component';
import { SubscribeComponent } from './subscribe/subscribe.component';

export const webappRoutes: Routes = [
  {
    path: 'subscribe',
    canActivate: [AuthGuard],
    component: SubscribeComponent,
  },
  {
    path: '',
    component: WebappComponent,
    canActivate: [AuthGuard],
    children: [
      {
        // this is just a dummy route, it redirects based on role
        // to 'contractor/projects' or 'manager/projects'
        path: 'projects',
        // this is not a true canActivate, it just redirects
        canActivate: [redirectToManagerOrContractor],
        children: [],
      },
      {
        path: 'projects/contractor',
        component: ProjectsContractorComponent,
        canActivate: [CanActivateProjectsContractorGuard],
      },
      {
        path: 'projects/manager',
        loadComponent: () => import('./rollups/rollups.component').then((m) => m.RollupsComponent),
        canActivate: [CanActivateProjectsManagerGuard],
      },
      {
        path: 'contractors',
        component: ContractorsComponent,
        canActivate: [IsApproved],
      },
      {
        path: 'documents',
        component: DriveComponent,
        canActivate: [IsApproved],
        resolve: {
          documents: DocumentsResolver,
        },
      },
      {
        path: 'documents/:uuid',
        component: DriveComponent,
        resolve: {
          documents: DocumentsResolver,
        },
      },
      {
        path: 'settings',
        loadComponent: () =>
          import('../webapp/settings/user-settings/user-settings.component').then(
            (m) => m.UserSettingsComponent,
          ),
      },
      {
        // todo remove
        path: 'team-management',
        component: TeamManagementComponent,
      },
      {
        path: 'teams',
        loadComponent: () => import('./teams/teams.component').then((m) => m.TeamsComponent),
      },
      {
        path: 'spend-templates',
        component: SpendTemplatesComponent,
      },
      {
        path: 'cashflow',
        component: CashFlowComponent,
      },
      {
        path: 'settings/properties',
        loadComponent: () =>
          import('./settings/properties/properties.component').then((m) => m.PropertiesComponent),
      },
      {
        path: 'projects/:id',
        loadComponent: () =>
          import('./projects/view-project/view-project.component').then(
            (m) => m.ViewProjectComponent,
          ),
        resolve: {
          documents: DocumentsResolver,
        },
      },
      {
        path: 'activities',
        loadComponent: () =>
          import('./activities/activities/activities.component').then((m) => m.ActivitiesComponent),
        canActivate: [isManager],
      },
      { path: '', redirectTo: 'activities', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(webappRoutes)],
  exports: [RouterModule],
})
export class WebappRoutingModule {}
