import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { CurrentUserService } from '../../services/current-user.service';
import { MENU_PAGES } from '../constants/view.constants';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { MenuRouteService } from '../../services/menu-route.service';
import { ProjectApiService } from '../../services/project-api.service';
import { NotificationsService } from '../../services/notifications.service';
import {
  INTERACTION_BAR_STATES,
  InteractionBarState,
} from '../constants/interaction-bar.constants';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ReportingService } from '../../services/reporting.service';
import {
  IReportingPayload,
  POSSIBLE_PRINT_PAGES,
  PRINT_PAGE_TYPES,
  REPORTING_ALL_STATUSES,
} from '../interaction-bar/reporting/reporting.constants';
import { CashFlowService } from '../../services/cash-flow.service';
import { setLineItems } from '../../store/spend/spend.actions';
import { AppState } from '../../store/app-state';
import { Store } from '@ngrx/store';
import { FiscalService } from '../../services/fiscal.service';
import { AsyncPipe, Location, NgClass, NgIf } from '@angular/common';
import { setMessagingView } from '../../store/messages/messages.actions';
import { MessagesStateService } from '../../services/messages-state.service';
import { MESSAGING_VIEWS } from '../../store/messages/messages.interfaces';
import { hasUnreadMessage } from '../../store/messages/messages.selectors';
import { NotificationsApiService } from '../../services/notifications-api.service';
import { IUnseenStatus } from '../constants/notification.constants';
import { Intercom } from '@supy-io/ngx-intercom';
import { IntercomService } from '../../services/intercom.service';
import { ROLLUP_VIEW_TYPES } from '../../store/rollups/rollups.interface';
import { PROJECT_VIEWS } from '../constants/view-project.constants';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { ArrowButtonBoxComponent } from '../dropdown-button-box/arrow-button-box.component';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  standalone: true,
  imports: [
    MatSidenavContainer,
    NgClass,
    ArrowButtonBoxComponent,
    MatSidenav,
    NgIf,
    RouterLink,
    MatTooltip,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
  ],
})
export class MenuBarComponent implements OnInit, AfterViewInit, OnDestroy {
  isMobileViewOpen = false;
  isApproved = false;
  isManager: boolean;
  drawerState: InteractionBarState;

  picLoading = true;

  disablePrintPage = true;
  currentPrintPage: PRINT_PAGE_TYPES = undefined;
  subs = new Subscription();

  MENU_PAGES = MENU_PAGES;

  activeButton = MENU_PAGES.PROJECTS;
  INTERACTION_BAR_STATES = INTERACTION_BAR_STATES;
  hasUnreadMessages$ = this.store.select(hasUnreadMessage);
  unreadStatus: IUnseenStatus = {
    has_unread_messages: false,
    has_unseen_notifications: false,
  };

  constructor(
    private el: ElementRef,
    private router: Router,
    public user: CurrentUserService,
    public barState: InteractionBarStateService,
    private menuState: MenuRouteService,
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
    private reportingService: ReportingService,
    private cashFlowService: CashFlowService,
    private store: Store<AppState>,
    private fiscalService: FiscalService,
    private location: Location,
    private messageService: MessagesStateService,
    private notificationsApi: NotificationsApiService,
    private intercom: Intercom,
    private intercomService: IntercomService,
  ) {
    this.isApproved = this.user.isApproved();
    this.user.isManagerF().then((isManager) => {
      this.isManager = isManager;
    });
  }

  ngOnInit() {
    const sub1 = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.handleUrlChange(event.urlAfterRedirects || event.url);
        }
      });
    const sub2 = this.reportingService.selectedProjectView.subscribe(this.handleProjectViewChange);
    const sub3 = this.reportingService.selectedRollupView.subscribe(this.handleRollupViewChange);
    this.subs.add(sub1);
    this.subs.add(sub2);
    this.subs.add(sub3);
    this.loadUnseenStatus();
  }

  ngAfterViewInit(): void {
    this.handleUrlChange(this.router.url);
    const sub = this.barState.announceState.subscribe((ev) => {
      this.drawerState = ev;
      switch (ev.action) {
        case INTERACTION_BAR_STATES.SUCCESS_CLOSED: {
          this.loadUnseenStatus();
          break;
        }
        case INTERACTION_BAR_STATES.REPORTING: {
          this.notif.close();
          break;
        }
      }
    });
    this.subs.add(sub);
  }

  private loadUnseenStatus() {
    this.notificationsApi.getUnseenStatus().then((response) => {
      this.unreadStatus = response;
    });
  }

  handleProjectViewChange = (selectedView) => {
    switch (selectedView) {
      case PROJECT_VIEWS.PROJECT_SPEND: {
        this.currentPrintPage = PRINT_PAGE_TYPES.PROJECT_VIEW_SPEND_DETAIL;
        break;
      }
      case PROJECT_VIEWS.CASHFLOW: {
        this.currentPrintPage = PRINT_PAGE_TYPES.PROJECT_VIEW_CASHFLOW;
        break;
      }
      case PROJECT_VIEWS.COMMITMENTS: {
        this.currentPrintPage = PRINT_PAGE_TYPES.PROJECT_VIEW_COMMITMENTS;
        break;
      }
      default:
        this.currentPrintPage = undefined;
        break;
    }
    this.setPrintPageDisabled();
  };

  handleRollupViewChange = (selectedView: ROLLUP_VIEW_TYPES) => {
    switch (selectedView) {
      case ROLLUP_VIEW_TYPES.PROJECTS: {
        this.currentPrintPage = PRINT_PAGE_TYPES.ROLLUP_PROJECTS;
        break;
      }
      case ROLLUP_VIEW_TYPES.BUDGET_LINE_ITEMS: {
        this.currentPrintPage = PRINT_PAGE_TYPES.ROLLUP_BUDGET_LINES;

        break;
      }
      case ROLLUP_VIEW_TYPES.TAGS: {
        this.currentPrintPage = PRINT_PAGE_TYPES.ROLLUP_BUDGET_TAGS;
        break;
      }
      default:
        this.currentPrintPage = undefined;
        break;
    }
    this.setPrintPageDisabled();
  };

  handleUrlChange(url: string) {
    if (!url) {
      return;
    }
    this.setActiveButtonFromUrl(url);
    this.setCurrentPrintPage(url);
  }

  setActiveButtonFromUrl(url: string) {
    const activePage = Object.values(MENU_PAGES).find((page) => url.includes(page));
    if (activePage) {
      this.activeButton = activePage;
    }
  }

  setCurrentPrintPage(url: string) {
    if (url.startsWith('/webapp/projects/manager')) {
      this.currentPrintPage = PRINT_PAGE_TYPES.ROLLUP_PROJECTS;
    } else if (url.startsWith('/webapp/cashflow')) {
      this.currentPrintPage = PRINT_PAGE_TYPES.CASHFLOW;
    } else {
      this.currentPrintPage = undefined;
    }
    this.setPrintPageDisabled();
  }

  setPrintPageDisabled() {
    if (POSSIBLE_PRINT_PAGES.includes(this.currentPrintPage)) {
      this.disablePrintPage = false;
      return;
    }
    this.disablePrintPage = true;
  }

  setActive(activeElement: MENU_PAGES) {
    this.barState.close();

    this.activeButton = activeElement;
  }

  async openReports() {
    this.notif.showLoading();

    try {
      const properties = await this.projectApi.getProperties();
      const projects = await this.projectApi.getProjects();
      this.barState.openReporting({
        properties,
        projects,
      });
      this.notif.close();
    } catch (err) {
      console.warn('err getProjects', err);
      this.notif.showError(err ?? 'Failed to load projects');
    }
  }

  printPageReport() {
    const currentYear = this.fiscalService.fiscalYear;
    const allStatuses = REPORTING_ALL_STATUSES;
    const selectedPropertyIds = this.reportingService.selectedProperty;
    const selectedYear = this.reportingService.selectedYear;
    const selectedProjectIds = this.reportingService.selectedProjectId;

    const reportingData: IReportingPayload = {
      include_portfolio_budget_lines: false,
      include_portfolio_budget_tags: false,
      include_portfolio_cashflow: false,
      include_portfolio_project_summary: false,
      include_portfolio_summary: false,
      include_project_cashflow: false,
      include_project_change_order_detail: false,
      include_project_contract_detail: false,
      include_project_contracts: false,
      include_project_direct_cost_detail: false,
      include_project_invoice_detail: false,
      include_project_progress_items: false,
      include_project_schedule: false,
      include_project_spend_detail: false,
      include_project_updates: false,
      include_property_budget_lines: false,
      include_property_budget_tags: false,
      include_property_cashflow: false,
      include_property_project_summary: false,
      include_invoice_log: false,
      start_year: currentYear,
      end_year: currentYear,
      project_ids: selectedProjectIds,
      property_ids: selectedPropertyIds,
      project_status_ids: allStatuses,
      tmp_sp_ids: [],
      sp_ids: [],
    };

    console.log('selectedProjectIds', selectedProjectIds);

    switch (this.currentPrintPage) {
      case PRINT_PAGE_TYPES.ROLLUP_PROJECTS: {
        reportingData.include_property_project_summary = true;
        if (selectedPropertyIds?.length > 0) {
          reportingData.property_ids = selectedPropertyIds;
        }
        break;
      }
      case PRINT_PAGE_TYPES.ROLLUP_BUDGET_LINES: {
        reportingData.include_property_budget_lines = true;
        if (selectedPropertyIds?.length > 0) {
          reportingData.property_ids = selectedPropertyIds;
        }
        break;
      }
      case PRINT_PAGE_TYPES.ROLLUP_BUDGET_TAGS: {
        reportingData.include_property_budget_tags = true;
        if (selectedPropertyIds?.length > 0) {
          reportingData.property_ids = selectedPropertyIds;
        }
        break;
      }
      case PRINT_PAGE_TYPES.CASHFLOW: {
        reportingData.include_property_cashflow = true;
        reportingData.start_year = selectedYear;
        reportingData.end_year = selectedYear;
        break;
      }
      case PRINT_PAGE_TYPES.PROJECT_VIEW_SPEND_DETAIL: {
        reportingData.include_project_spend_detail = true;
        reportingData.property_ids = 'all'; // it should be none, but that doesnt work
        reportingData.project_ids = selectedProjectIds;
        break;
      }
      case PRINT_PAGE_TYPES.PROJECT_VIEW_CASHFLOW: {
        reportingData.include_project_cashflow = true;
        reportingData.start_year = selectedYear;
        reportingData.end_year = selectedYear;
        break;
      }
      case PRINT_PAGE_TYPES.PROJECT_VIEW_COMMITMENTS: {
        reportingData.include_project_contracts = true;
        reportingData.property_ids = 'all'; // it should be none, but that doesnt work
        reportingData.project_ids = selectedProjectIds;
        break;
      }
    }

    this.reportingService
      .generateReportOnServer(reportingData)
      .then((response) => {
        console.log(response);
        this.notif.showSuccess(response?.message ?? 'Report Generated.');
      })
      .catch((error) => {
        console.warn(error);
        this.notif.showError(error?.error?.message ?? 'An error occurred.');
      });
  }

  async logout() {
    this.user.logout();
    await this.router.navigateByUrl('/');
    setTimeout(() => {
      this.intercomService.bootIntercom();
    }, 200);
  }

  openDrawer(interactionBarView: INTERACTION_BAR_STATES) {
    this.barState.announceState.next({ action: interactionBarView });
  }

  openActivitySidebar() {
    this.barState.announceState.next({ action: INTERACTION_BAR_STATES.ACTIVITY_SIDEBAR });
  }

  openMessages() {
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
    this.messageService.openMessagesInteractionBar();
  }

  profilePicLoaded() {
    this.picLoading = false;
  }

  clearLineItems() {
    this.store.dispatch(
      setLineItems({
        lineItems: [],
        projectId: null,
        modifiedItems: {
          lineIds: [],
          committedItemIds: [],
        },
      }),
    );
  }

  openIntercom() {
    this.intercom.show();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openProjectSetup() {
    this.barState.openProjectSetup(false);
  }

  async openInvoiceReports() {
    this.notif.showLoading();

    try {
      const [properties, projects] = await Promise.all([
        this.projectApi.getProperties(),
        this.projectApi.getProjects(),
      ]);
      this.barState.openInvoiceReportPanel({
        properties,
        projects,
      });
      this.notif.close();
    } catch (err) {
      this.notif.showError(err?.error?.message ?? 'Failed to load data.');
      console.warn('get properties/projects error', err);
    }
  }
}
