import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

interface TabContent {
  title: string;
  content: string;
  imageUrl: string;
  imageAlt: string;
}

@Component({
  selector: 'app-use-cases-section',
  templateUrl: './use-cases-section.component.html',
  styleUrls: ['./use-cases-section.component.scss'],
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('fadeAnimation', [
      state('false', style({ opacity: 0 })), // Hidden state
      state('true', style({ opacity: 1 })), // Visible state

      transition('false => true', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
      transition('true => false', [animate('500ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class UseCasesSectionComponent {
  activeTab = 'Asset Managers';
  tabs: TabContent[] = [
    {
      title: 'Asset Managers',
      content:
        'Asset managers overseeing multiple properties can leverage BuildEvo to streamline their portfolio management. The platform enables real-time monitoring of renovation projects across various locations, ensuring consistent quality and timely completion. By centralizing communication and documentation, asset managers can make data-driven decisions faster, potentially increasing property values through more efficient project execution and reduced vacancy periods during renovations.',
      imageUrl: 'assets/images/landing-success-section/2.webp',
      imageAlt: 'Asset Management Success',
    },
    {
      title: 'Healthcare Facilities',
      content:
        'Healthcare providers can transform their project management with BuildEvo, especially for complex undertakings like operating room renovations. Imagine streamlining communication between medical staff, contractors, and project managers through a single platform. With BuildEvo, hospitals could potentially reduce administrative overhead by up to 30% and complete projects weeks ahead of schedule. This efficiency not only saves time and money but also minimizes disruption to critical patient care services.',
      imageUrl: 'assets/images/landing-success-section/1.webp',
      imageAlt: 'Healthcare Project Success',
    },

    {
      title: 'Contractors',
      content:
        "Construction contractors benefit from BuildEvo's comprehensive project management capabilities, allowing them to coordinate multiple teams, track material deliveries, and manage client approvals seamlessly. The platform's document management system ensures all permits, specifications, and change orders are instantly accessible to field teams. This level of organization has helped contractors reduce rework by up to 25% and improve client satisfaction through transparent progress reporting and proactive issue resolution.",
      imageUrl: 'assets/images/landing-success-section/3.webp',
      imageAlt: 'Contractor Success',
    },
  ];

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }
}
