<section id="use-cases" class="flex justify-center items-center bg-grey_200">
  <div class="landing-section pr-0 flex gap-x-10 max-lg:flex-col max-lg:px-0">
    <div class="flex-1 py-16 max-lg:px-6 max-lg:py-8">
      <h2 class="mt-0 mb-10 text-2xl font-semibold text-black_500">
        Real-World Success: How BuildEvo Transforms Construction Projects
      </h2>
      <div class="flex gap-8 pb-3 mb-3 overflow-x-auto text-nowrap">
        <a
          *ngFor="let tab of tabs"
          class="be-link text-grey_400"
          [class.active]="activeTab === tab.title"
          (click)="setActiveTab(tab.title)"
        >
          {{ tab.title }}
        </a>
      </div>
      <div class="relative grid grid-stack">
        @for (tab of tabs; track tab.title) {
          <p [@fadeAnimation]="activeTab === tab.title" class="text-xl leading-snug stacked-item">
            {{ tab.content }}
          </p>
        }
      </div>
    </div>
    <div class="flex-1 grid grid-stack">
      @for (tab of tabs; track tab.title) {
        <img
          [@fadeAnimation]="activeTab === tab.title"
          class="w-full h-full stacked-item object-cover"
          [src]="tab.imageUrl"
          [alt]="tab.imageAlt"
        />
      }
    </div>
  </div>
</section>
