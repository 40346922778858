import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';
import { OverlayGeneralComponent } from '../overlays/overlay-general/overlay-general.component';
import { ControlContainer, NgForm } from '@angular/forms';
import { dropdownOverlayPositions } from '../overlays/option-list.constants';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-custom-input-overlay-dropdown',
  template: ` <app-floating-input
      #optionsTrigger="cdkOverlayOrigin"
      #floatingInputComponent
      (click)="showDropdown()"
      (modelChange)="modelChange.emit($event)"
      [(model)]="model"
      [autoComplete]="autoComplete"
      [iconClass]="iconClass"
      [label]="label"
      [name]="name"
      [classes]="{
        wrapper: isReadOnly ? 'cursor-pointer' : '',
        input: isReadOnly ? 'cursor-pointer' : ''
      }"
      [placeholder]="placeholder"
      [required]="required"
      [readonly]="isReadOnly"
      (focusout)="isReadOnly = true"
      cdkOverlayOrigin
    >
      @if (isEdited) {
        <i
          secondaryIcon
          (click)="editTemplateName($event)"
          pTooltip="Rename checklist"
          class="text-color_secondary p-input-icon-right icon-pencil-block right-8"
        ></i>
      }
    </app-floating-input>

    <app-overlay-general
      (optionSelected)="optionSelected.emit($event)"
      [(show)]="isDropdownShown"
      [overlayPositions]="dropdownOverlayPositions"
      [trigger]="optionsTrigger"
      [withAnimation]="withAnimation"
    >
      <ng-content></ng-content>
    </app-overlay-general>`,
  standalone: true,
  imports: [
    AsyncPipe,
    CdkOverlayOrigin,
    FloatingInputComponent,
    OverlayGeneralComponent,
    TooltipModule,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CustomInputOverlayDropdownComponent implements OnInit {
  @ViewChild('floatingInputComponent') floatingInputComponent: FloatingInputComponent;

  @Input() model: any;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() name: string;
  @Input() autoComplete: boolean;
  @Input() required: boolean;
  @Input() withAnimation = true;
  @Input() iconClass: {
    position: 'p-input-icon-left' | 'p-input-icon-right';
    icon: string;
  } = {
    position: null,
    icon: null,
  };
  isDropdownShown = false;
  @Input() isEdited = false;

  @Output() modelChange = new EventEmitter();
  @Output() optionSelected = new EventEmitter();

  protected readonly dropdownOverlayPositions = dropdownOverlayPositions;
  isReadOnly = true;

  showDropdown() {
    this.isDropdownShown = true;
  }

  hideDropdown() {
    this.isDropdownShown = false;
  }

  ngOnInit(): void {}

  editTemplateName(event: MouseEvent) {
    event.stopPropagation();
    this.isReadOnly = false;
    this.floatingInputComponent.inputElement.nativeElement.focus();
  }
}
