import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SvgComponent } from 'src/app/framework/svg/svg.component';

@Component({
  selector: 'app-about-section',
  templateUrl: './about-section.component.html',
  styleUrls: ['./about-section.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgComponent],
})
export class AboutSectionComponent {}
