<div [formGroup]="messageForm" class="discussion-view-wrapper h-100 flex flex-col">
  <ng-container>
    <div class="py-3 flex border-b border-solid border-shade_5">
      <app-messaging-bubble
        [isFilled]="!selectedGroup?.is_read"
        [type]="getBubbleType(selectedGroup)"
      ></app-messaging-bubble>
      <div class="ml-6 text-shade_1 font-semibold">{{ (selectedGroup$ | async)?.name }}</div>
    </div>
    <div
      #selectedUserWrapper
      class="form dropdown-form hide-underline flex border-b border-solid border-shade_5 overflow-visible"
    >
      <!-- note: overflow-visible fixes display bug on firefox -->
      <mat-form-field
        subscriptSizing="dynamic"
        (click)="showRelatedUsers = false; optionsListGeneralComponent.toggleShow()"
        #userTableTrigger="cdkOverlayOrigin"
        cdkOverlayOrigin
        class="borderless"
      >
        <app-overlay-general
          #optionsListGeneralComponent
          (showChange)="!!$event ? loadUsers() : ''"
          [trigger]="userTableTrigger"
          [withAnimation]="false"
        >
          <app-messaging-users-table
            [listItems]="itemOptions"
            [usersRelated]="usersData.usersRelated"
            [discussionMembers]="usersData.discussionMembers"
            (selectedOption)="onMemberOptionSelected($event); optionsListGeneralComponent.toggleShow()"
          ></app-messaging-users-table>
        </app-overlay-general>
        <span
          (click)="$event.stopPropagation(); showRelatedUsers = true; optionsListGeneralComponent.toggleShow()"
          [ngClass]="{ 'icon-new-message-plus-chat-unfilled': !showRelatedUsers, 'icon-at-sign': showRelatedUsers }"
          class="icon"
        ></span>
        <span class="user-selected-wrapper ml-4">
          <ng-container *ngFor="let user of usersData.discussionMembers">
            <span [ngClass]="{ 'font-bold': user?.is_admin }" class="pr-5 text-shade_1 font-normal font-sm">
              {{ user.name }}
            </span>
          </ng-container>
        </span>
        <input
          #input
          class="discussion-dropdown-input"
          [ngClass]="{ 'disp-none': !showRelatedUsers }"
          autocomplete="off"
          formControlName="participants"
          (ngModelChange)="loadUsers($event)"
          matInput
        />
        <div matSuffix class="flex items-center cursor-pointer pl-2">
          <app-arrow-button-box
            [direction]="isDropDownOpen ? 'up' : 'down'"
            [isFilled]="isDropDownOpen"
          ></app-arrow-button-box>
        </div>
      </mat-form-field>
    </div>
  </ng-container>
  <!--  class can be found in global styles-->
  <mat-progress-bar
    *ngIf="isPaginationLoading$ | async"
    class="custom-loading-progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>
  <div class="flex-1 min-h-0">
    <div class="h-100 flex justify-center items-center" *ngIf="isLoading$ | async">
      <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
    </div>
    <ng-scrollbar
      #scrollbar
      *ngIf="!(isLoading$ | async)"
      class="std-scrollbar"
      orientation="vertical"
      appearance="native"
    >
      <div [ngClass]="{ 'mr-3': scrollbar.isVerticallyScrollable() }">
        <ng-container *ngFor="let message of allMessages; trackBy: trackByFn">
          <div
            *ngIf="!(message.body | isMessageEmpty) || message.attachments?.length"
            id="{{ 'message-' + message.id }}"
            [ngClass]="{ 'py-5 border-b border-solid border-shade_5 first-child:py-0': !message.is_announcement }"
          >
            <app-message-item *ngIf="!message?.is_announcement" [message]="message"></app-message-item>
            <app-message-item-announcement
              *ngIf="message?.is_announcement"
              [message]="message"
            ></app-message-item-announcement>
          </div>
        </ng-container>
      </div>
    </ng-scrollbar>
  </div>

  <div class="border-separator"></div>
  @if (!draftLoaded) {
    <div class="py-2 flex w-full items-center justify-center h-[244px]">
      <div class="notify-msg animate-pulsating-border">
        <div class="icon-logo-box-hex"></div>
      </div>
    </div>
  } @else {
    <app-rich-text-editor
      [editorValue]="editorValue"
      (editorValueChange)="onEditorValueChange($event)"
      [userMentions]="usersData.discussionMembers"
      [projectMentions]="projectMentions$ | async"
      [allowFileUpload]="true"
      [filesToUpload]="filesToUpload$ | async"
      (filesToUploadChange)="registerFileUpload($event)"
      (removedFileToUpload)="removeFile($event)"
      enterKeyPressBehaviour="custom"
      (enterKeyPress)="footerService.emitSave()"
      class="max-h-96"
    ></app-rich-text-editor>
  }
</div>
