import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Hidden file input UI
 * It's role is to open a file input window on command, and emit selected files
 */
@Component({
  selector: 'app-hidden-upload-input',
  standalone: true,
  imports: [CommonModule],
  template: ` <input
    #fileInput
    (change)="onFileInputChange($event)"
    [multiple]="allowMultipleFiles"
    class="upload disp-none"
    type="file"
  />`,
  styles: [],
})
export class UploadHiddenInputComponent {
  @ViewChild('fileInput') fileElement: ElementRef<HTMLInputElement>;
  @Input() allowMultipleFiles = false;
  @Input() allowSameFileUpload = false; // allows upload of a file multiple times in a row
  /**
   * Emits selected files as an array of File objects
   */
  @Output() filesChange = new EventEmitter<File[]>();

  /**
   * Emits raw change event as it is received from the file input
   * Useful in cases where you need to access the file input event object directly e.g. profile picture cropper
   */
  @Output() filesChangeRaw = new EventEmitter<Event>();

  onFileInputChange(file: Event) {
    const files: File[] = this.convertInputEventToFileArray(file);

    this.filesChangeRaw.emit(file);
    this.filesChange.emit(files);
  }

  openFileUploadWindow() {
    this.fileElement.nativeElement.click();
  }

  private convertInputEventToFileArray(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files?.length > 0) {
      if (this.allowSameFileUpload) {
        target.value = ''; // allow re-upload of the same files
      }
      return Array.from(target.files);
    }
    return [];
  }
}
