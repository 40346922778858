<section id="about-us" class="text-black_500 flex justify-center items-center bg-grey_100">
  <div class="landing-section py-20 flex flex-col max-lg:py-8">
    <div class="flex gap-x-32 pb-14 max-lg:flex-col">
      <h2 class="flex-1 text-nowrap text-2xl font-semibold max-lg:text-wrap max-lg:text-xl">
        Meet the team behind BuildEvo
      </h2>
      <div class="flex flex-col gap-x-10 columns-1 text-lg max-lg:text-base">
        <p class="mb-6">
          BuildEvo is born from a fusion of construction management expertise and cutting-edge software development.
          With over 30 years of experience in managing complex projects, our team understands the industry's challenges
          and has crafted BuildEvo to meet those needs. We've designed an intuitive platform that aligns with real-world
          processes, ensuring seamless integration into daily operations.
        </p>
        <p class="mb-6">
          Our commitment is to continuous improvement, driven by user feedback and industry trends. This dedication to
          innovation and user-centric design sets BuildEvo apart as a leader in construction management software. Our
          mission is to provide a robust, intuitive tool that empowers construction professionals to excel in their
          projects, delivering a solution rooted in real industry experience.
        </p>
      </div>
    </div>
    <div class="flex gap-x-10 max-lg:flex-col">
      <app-svg class="w-10 h-10 text-blue_500" src="assets/icons/quote.svg"></app-svg>
      <p class="text-4xl my-10 font-semibold leading-tight">
        With BuildEvo, we're not just offering software - we're delivering a solution born from real industry experience
        and tailored to drive your success.
      </p>
    </div>
  </div>
</section>
