<section class="py-10 flex justify-center items-center bg-grey_100">
  <div class="carousel landing-section">
    <div
      class="carousel-track"
      [style.width]="spread * multiplicationCount + '%'"
      [style.animation-duration]="duration + 's'"
    >
      <div class="carousel-item" *ngFor="let partner of partnersMultiplied">
        <img [src]="partner.imageUrl" [alt]="partner.name" [style.height]="partner.imageHeight + 'rem'" />
      </div>
    </div>
  </div>
</section>
