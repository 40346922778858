import { Pipe, PipeTransform } from '@angular/core';

/**
 * Return the name of the file without the extension, if it contains any.
 *
 * Works by seraching for the last '.' in the filename and removing everything after it.
 *
 * ex. text.png -> test
 */

@Pipe({
  name: 'removeFileExtension',
  standalone: true,
})
export class RemoveFileExtensionPipe implements PipeTransform {
  transform(filename: string): string {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }
}
