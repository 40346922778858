import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FeatureCardComponent } from './feature-card.component';
import { DragScrollDirective } from 'src/app/directives/drag-scroll.directive';

interface Feature {
  title: string;
  description: string;
  image: {
    src: string;
    alt: string;
  };
}

@Component({
  selector: 'app-features-section',
  templateUrl: './features-section.component.html',
  styleUrls: ['./features-section.component.scss'],
  standalone: true,
  imports: [CommonModule, FeatureCardComponent, DragScrollDirective],
})
export class FeaturesSectionComponent {
  features: Feature[] = [
    {
      title: '1. Intuitive User Experience',
      description:
        'BuildEvo offers a user-friendly interface with a short learning curve, ensuring quick onboarding and immediate productivity gains for your team.',
      image: {
        src: 'assets/images/landing-features/1.png',
        alt: 'Cashflow',
      },
    },
    {
      title: '2. Comprehensive Project Oversight',
      description:
        'Easily track project budgets, progress, schedules, and expenditures in one centralized platform, providing a clear overview of your projects at all times.',
      image: {
        src: 'assets/images/landing-features/2.png',
        alt: 'Calendar',
      },
    },
    {
      title: '3. Streamlined Document Management',
      description:
        'Efficiently organize and store all project-related documents, contracts, and file structures by project and service provider, ensuring easy access and collaboration.',
      image: {
        src: 'assets/images/landing-features/3.png',
        alt: 'Documents',
      },
    },
    {
      title: '4. Enhanced Team Collaboration',
      description:
        'Foster seamless communication and cooperation across all projects and locations, breaking down silos and improving overall project efficiency.',
      image: {
        src: 'assets/images/landing-features/4.png',
        alt: 'Discussions',
      },
    },
    {
      title: '5. Powerful Reporting Capabilities',
      description:
        'Generate detailed project reports by location or for your entire company, enabling data-driven decision-making and strategic planning.',
      image: {
        src: 'assets/images/landing-features/5.png',
        alt: 'Reports',
      },
    },
    {
      title: '6. Seamless Integrations',
      description:
        'BuildEvo integrates smoothly with popular accounting software, creating a unified ecosystem for all your construction management needs.',
      image: {
        src: 'assets/images/landing-features/6.png',
        alt: 'Integrations',
      },
    },
  ];
}
