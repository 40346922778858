import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  private userAgent = navigator.userAgent || navigator.vendor;

  isAndroid(): boolean {
    return /android/i.test(this.userAgent);
  }

  isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(this.userAgent) && !window['MSStream'];
  }
}
