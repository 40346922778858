<footer class="bg-black_500 flex justify-center items-center">
  <div
    class="landing-section py-14 flex flex-wrap justify-between items-end mx-auto my-0 max-sm:items-start max-sm:flex-col gap-8"
  >
    <div class="flex flex-col gap-8">
      <div class="max-sm:h-6">
        <img class="w-auto h-full" src="assets/icons/buildevo_logo_full_white.svg" alt="Buildevo Logo" />
      </div>
      <div class="flex gap-8 max-sm:flex-col-reverse">
        <span class="text-xs text-white opacity-40">© 2025 BuildEvo Inc. All Rights Reserved.</span>
        <div class="flex gap-8">
          <a (click)="openPrivacyPolicy()" class="footer-link">Privacy Policy</a>
          <a (click)="openTermsOfService()" class="footer-link">Terms of Service</a>
        </div>
      </div>
    </div>
    <div class="flex text-lg gap-6 text-white">
      <a class="social-media-icon" target="_blank" href="https://www.linkedin.com/company/buildevo">
        <img class="white-icon" src="assets/images/landing-footer/linkedin-white.svg" />
        <img class="black-icon" src="assets/images/landing-footer/linkedin-black.svg" />
      </a>
      <!--
      <a class="social-media-icon" target="_blank" href="https://x.com/skillhop">
        <img class="white-icon" src="assets/images/landing-footer/x-white.svg" />
        <img class="black-icon" src="assets/images/landing-footer/x-black.svg" />
      </a>
      <a class="social-media-icon" target="_blank" href="https://www.youtube.com/@skillhopinc.9040/videos">
        <img class="white-icon" src="assets/images/landing-footer/youtube-white.svg" />
        <img class="black-icon" src="assets/images/landing-footer/youtube-black.svg" />
      </a>
      <a class="social-media-icon" target="_blank" href="https://www.instagram.com/skillhop/">
        <img class="white-icon" src="assets/images/landing-footer/instagram-white.svg" />
        <img class="black-icon" src="assets/images/landing-footer/instagram-black.svg" />
      </a>
      <a class="social-media-icon" target="_blank" href="https://skillhop.medium.com/">
        <img class="white-icon" src="assets/images/landing-footer/medium-white.svg" />
        <img class="black-icon" src="assets/images/landing-footer/medium-black.svg" />
      </a>-->
    </div>
  </div>
</footer>
