import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IStyledOption } from '../framework/constants/documents.constants';
import { CustomOverlayComponent } from '../framework/custom-overlay/custom-overlay.component';
import { IDefaultOverlayListItem } from '../framework/custom-overlay/mention-overlay/mention-overlay.component';

type BaseConfig = {
  height: string;
  width: string;
  hasBackdrop: boolean;
  closeOnBackdropClick: boolean;
  backdropClass: string;
  keyboardSelectionIsActive: boolean; // TODO: make keyboard selection active on all overlays
};

type RelativeConfig = {
  position: 'relative';
  x: number;
  y: number;
};

type GlobalConfig = {
  position: 'global';
  left: string;
  right: string;
  top: string;
  bottom: string;
  centerHorizontally: boolean;
  centerVertically: boolean;
};

export type CustomOverlayConfig = BaseConfig & (RelativeConfig | GlobalConfig);

export enum CUSTOM_OVERLAY_VIEWS {
  DEFAULT,
  STYLED_OPTIONS_LIST,
  CROPPER,
}

@Injectable({
  providedIn: 'root',
})
export class CustomOverlayService {
  overlayRef: OverlayRef;
  componentRef: ComponentRef<any>;
  private _data$ = new BehaviorSubject<
    File | { listItems: IStyledOption[] } | IDefaultOverlayListItem[]
  >(null);
  private _selectedView$ = new BehaviorSubject<CUSTOM_OVERLAY_VIEWS>(null);
  private _outputData$ = new Subject();
  private _isOpened$ = new BehaviorSubject(false);

  private _keyboardSelectionIsActive = false;
  private _keyboardSelection = 0;

  constructor(private overlay: Overlay) {}

  get data$() {
    return this._data$.asObservable();
  }

  get selectedView$() {
    return this._selectedView$.asObservable();
  }

  get outputData$() {
    return this._outputData$.asObservable().pipe(filter((data) => !!data));
  }

  get keyboardSelection() {
    return this._keyboardSelection;
  }

  get keyboardSelectionIsActive() {
    return this._keyboardSelectionIsActive;
  }

  openOverlay(
    customConfig: Partial<CustomOverlayConfig>,
    data: File | { listItems: IStyledOption[] } | IDefaultOverlayListItem[],
    selectedView: CUSTOM_OVERLAY_VIEWS,
  ) {
    if (this.overlayRef?.hasAttached()) {
      console.info('An overlay is already open, updating data instead.');
      this.setInputData(data);
      return;
    }

    this._keyboardSelectionIsActive = customConfig.keyboardSelectionIsActive;

    customConfig = {
      closeOnBackdropClick: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      ...customConfig,
    };

    this._data$.next(data);

    this._isOpened$.next(true);

    this._selectedView$.next(selectedView);

    const config: OverlayConfig = {};

    let positionStrategy: GlobalPositionStrategy | FlexibleConnectedPositionStrategy;

    if (customConfig.position === 'global') {
      positionStrategy = this.overlay.position().global();

      if (customConfig.centerHorizontally) {
        positionStrategy = positionStrategy.centerHorizontally();
      }

      if (customConfig.centerVertically) {
        positionStrategy = positionStrategy.centerVertically();
      }

      if (customConfig.left) {
        positionStrategy = positionStrategy.left(customConfig.left);
      }
      if (customConfig.right) {
        positionStrategy = positionStrategy.right(customConfig.right);
      }
      if (customConfig.top) {
        positionStrategy = positionStrategy.top(customConfig.top);
      }
      if (customConfig.bottom) {
        positionStrategy = positionStrategy.bottom(customConfig.bottom);
      }
    }

    const positions = [
      new ConnectionPositionPair(
        { originX: 'center', originY: 'bottom' },
        { overlayX: 'center', overlayY: 'top' },
      ),
      new ConnectionPositionPair(
        { originX: 'center', originY: 'top' },
        { overlayX: 'center', overlayY: 'bottom' },
      ),
    ];

    if (customConfig.position === 'relative') {
      positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo({ x: customConfig?.x ?? 0, y: customConfig?.y ?? 0 })
        .withPositions(positions);
    }

    config.positionStrategy = positionStrategy;
    config.height = customConfig.height;
    config.width = customConfig.width;
    config.hasBackdrop = customConfig?.hasBackdrop ?? true;
    config.backdropClass = customConfig.backdropClass;
    this.overlayRef = this.overlay.create(config);
    const componentPortal = new ComponentPortal(CustomOverlayComponent);
    this.componentRef = this.overlayRef.attach(componentPortal);

    this.overlayRef.backdropClick().subscribe((_) => {
      if (!customConfig.closeOnBackdropClick) {
        console.log('closeOnBackdropClick prevented');
        return;
      }

      this.overlayRef.dispose();
      this._isOpened$.next(false);
      this._outputData$.next({});
    });

    // close overlay on right click
    this.overlayRef.backdropElement.oncontextmenu = (e) => {
      e.preventDefault();
      this.overlayRef.dispose();
      this._isOpened$.next(false);
      this._outputData$.next({});
    };

    // This is just in case if we ever need to handle events without backdrop
    // this.overlayRef.outsidePointerEvents().subscribe((_) => {
    //   if ((_.target as HTMLElement).id !== 'skillhop-app') {
    //     console.log('outsidePointerEvents', _);
    //     this.overlayRef.dispose();
    //     this._isOpened$.next(false);
    //   }
    // });
  }

  closeOverlay() {
    if (!this.overlayRef) console.info('No overlay was detected as being opened.');

    this.overlayRef?.dispose();
    this._isOpened$.next(false);
  }

  emitOutputData(data) {
    this._outputData$.next(data);
  }

  setInputData(data) {
    this._keyboardSelection = 0;
    this._data$.next(data);
  }

  setKeyboardSelection(index: number) {
    this._keyboardSelection = index;
  }

  /**
   * This method should be called when the keyboard selection is confirmed
   */
  emitKeyboardSelection() {
    // TODO: generalise across all overlay types, this currently only works for default overlay
    if (this._selectedView$.value !== CUSTOM_OVERLAY_VIEWS.DEFAULT) {
      console.error('This method is only supported for default overlay');
      return;
    }

    this.emitOutputData(this._data$.value[this._keyboardSelection]);
    this.closeOverlay();
  }

  isOpened() {
    return this.overlayRef?.hasAttached();
  }

  isOpened$() {
    return this._isOpened$.asObservable();
  }
}
