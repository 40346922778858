import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { NotificationsService } from '../services/notifications.service';

// extend the built-in types with the childrenEntries property
type EntryExtension = { entry: FileSystemEntry; childrenEntries: EntryExtension[] };

// every FileSystemEntry is mapped to a File or a Directory
export type UploadableContent = File | Directory;
export type Directory = {
  size: number; // size of all content inside folder
  totalFileCount: number; // recursive count of all files in the directory
  isDirectory: true;
  name: string;
  children: UploadableContent[];
};

/**
 * Directive to handle file and folder drag and drop - mostly for uploads. <br/>
 * <b>Important</b>: add the class <code>'upload-drop-area'</code> to the element where you want to use this directive. <br/>
 */
@Directive({
  selector: '[appUploadDrop]',
  standalone: true,
})
export class UploadDropDirective {
  // if set to false it simply skips folders
  @Input() disableAppUploadDrop = false;
  @Input() supportFolderUpload = false;
  @Output() fileDropped = new EventEmitter<UploadableContent[]>();
  @HostBinding('class.animate-shake') fileover: boolean;
  // do not upload files starting with a dot '.' like '.DS_Store' on macs
  excludeRegex = new RegExp(/^\..*/);
  shakeAnimationClass = 'animate-shake';
  // this class needs to be present on the element where the directive is used
  // otherwise cleanup won't be called thus the animation will not disappear
  uploadAreaClass = 'upload-drop-area';

  constructor(
    private cd: ChangeDetectorRef,
    private element: ElementRef,
    private notif: NotificationsService,
  ) {}

  /**
   * Add an animation class when the files are dragged over the element.
   * @param event
   */
  @HostListener('dragover', ['$event'])
  public onDragOver(event) {
    if (this.disableAppUploadDrop) return;

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.element.nativeElement.classList.add(this.shakeAnimationClass);
    this.fileover = true;
    this.cd.detach(); // not sure if we need this
  }

  /**
   * Remove the animation class when the files are dragged out of the element.
   * @param event
   */
  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: Event) {
    if (this.disableAppUploadDrop) return;

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    const target = event.target as HTMLElement;

    // this 'if' is really important because the dragleave event can be fired on multiple elements
    // and you end up with a flickering effect
    if (target.classList.contains(this.uploadAreaClass)) {
      this.cleanUp();
    }
  }

  /**
   * Called when the files are dropped.
   * It will emit an event with the files and folders.
   * @param event
   */
  @HostListener('drop', ['$event'])
  public async ondrop(event: DragEvent) {
    if (this.disableAppUploadDrop) return;

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.cd.reattach();

    if (!event?.dataTransfer?.items) {
      console.log('no items dataTransfer items');
      this.cleanUp();
      return;
    }

    // we need promises to get the contents of a folder, so we can't use a for loop
    // instead we will await all Promises at once
    const fileAndFolderPromises: Promise<UploadableContent>[] = [];

    // no for-of loop because it does not contain an iterator
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < event.dataTransfer.items.length; i++) {
      const item = event.dataTransfer.items[i];

      /*
       As of now (2024-01-30) MDN states that:
       Note: This function is implemented as webkitGetAsEntry() in non-WebKit browsers including Firefox at this time;
       it may be renamed to getAsEntry() in the future, so you should code defensively, looking for both.
       */
      let entry: FileSystemEntry;
      if (item.webkitGetAsEntry) {
        entry = item.webkitGetAsEntry();
        // @ts-ignore
      } else if (item.getAsEntry) {
        // @ts-ignore
        entry = item.getAsEntry();
      }

      if (!entry) {
        // not sure how this can happen, but it did happen on production once. It might need more investigation.
        this.notif.showError('Could not get the file or folder, please try again.');
        return;
      }

      if (entry.isFile && !this.excludeRegex.test(entry.name)) {
        // if is file then it's a FileSystemFileEntry, but TS does not know that

        // create a wrapper Promise which resolves immidiately with the file - it used to retain structure
        fileAndFolderPromises.push(new Promise((resolve) => resolve(item.getAsFile())));
        continue;
      }

      // if there is a directory, and we don't support folder upload, then skip it
      if (entry.isDirectory && this.supportFolderUpload) {
        // if is directory then it's a FileSystemDirectoryEntry, but TS does not know that

        // if a directory is dropped, get it's content by a recursive function call
        // then convert it to the uploadable format
        // wrapper promise needed because await does not work well in a for loop
        // TODO: handle errors (Promise rejection)
        const promise = new Promise<UploadableContent>((resolve) => {
          this.getAllEntriesRecursively(entry)
            .then((readDirectory) => this.convertEntriesToUploadFormat(readDirectory))
            .then((processedFilesAndDirectories) => resolve(processedFilesAndDirectories));
        });

        fileAndFolderPromises.push(promise);
        continue;
      }

      // this should not happen :)
      if (!entry.isFile && !entry.isDirectory) {
        console.warn(
          'Found a File System Entry which is nor File nor Directory, what is it then? 🤔🤔',
        );
      }
    }

    // this is the step where we get the files and folders
    // uploadable means that is formatted to our format, so a file is a vannilla JS File, and a directory is a Directory object
    const uploadableContent = await Promise.all(fileAndFolderPromises);

    if (uploadableContent.length > 0) {
      this.fileDropped.emit(uploadableContent);
    }

    this.cleanUp();
  }

  /**
   * Function to reset the UI state, and remove the animation
   * @private
   */
  private cleanUp() {
    this.cd.reattach();
    this.element.nativeElement.classList.remove(this.shakeAnimationClass);
    this.fileover = false;
  }

  /**
   * Reads a directory and returns all of it's content, even the content of the subdirectories<br/>
   * It can be called with a file, in which case it will return the file (base case)<br/>
   * If the topLevelEntry is a directory, then it will read it's content, and call itself recursively<br/>
   * It ignores files starting with a dot '.' like '.DS_Store' on macs. <br/>
   * Returns a Promise which resolves with the content of the directory.
   * TODO: handle errors (Promise rejection)
   * @param topLevelEntry
   * @private
   */
  private getAllEntriesRecursively(topLevelEntry: FileSystemEntry): Promise<EntryExtension | null> {
    return new Promise((resolve, reject) => {
      const entryExtension: EntryExtension = { entry: topLevelEntry, childrenEntries: [] };
      if (topLevelEntry.isFile) {
        resolve(entryExtension);
        return;
      }

      if (!topLevelEntry.isDirectory) {
        // if it's not a File nor a Directory, then reject
        reject();
        return;
      }

      // read directory and get its children
      this.getChildrenEntriesFromDirectory(topLevelEntry as FileSystemDirectoryEntry).then(
        (childrenEntries) => {
          // create a promise array of the recursive calls for each child
          // filter out ignored files and directories, otherwise we get an error
          const childrenEntryPromises = childrenEntries
            .filter((entry) => !this.excludeRegex.test(entry.name))
            .map((entry) => this.getAllEntriesRecursively(entry));

          // call the promise and set the children of the current directory
          Promise.all(childrenEntryPromises).then((entries) => {
            entryExtension.childrenEntries = entries.filter((entry) => !!entry); // filter ignored files
            resolve(entryExtension);
          });
        },
        // reject if the directory could not be read
        reject,
      );
    });
  }

  /**
   * Reads the content of a directory and returns its content with a Promise.<br/>
   * @param topLevelEntry
   * @private
   */
  private getChildrenEntriesFromDirectory(
    topLevelEntry: FileSystemDirectoryEntry,
  ): Promise<FileSystemEntry[]> {
    const directoryReader = topLevelEntry.createReader();
    return new Promise((resolve, reject) => directoryReader.readEntries(resolve, reject));
  }

  /**
   * Converts the entries from the recursive read to the uploadable format.<br/>
   * Uploadable means that a file will be a standard JS File, and a directory will be a custom Directory object.<br/>
   * @param topLevelEntry
   * @private
   */
  private async convertEntriesToUploadFormat(
    topLevelEntry: EntryExtension,
  ): Promise<UploadableContent> {
    if (topLevelEntry.entry.isFile) {
      return await this.getFileFromEntry(topLevelEntry.entry as FileSystemFileEntry);
    }

    const promises = topLevelEntry.childrenEntries.map((childrenEntry) =>
      this.convertEntriesToUploadFormat(childrenEntry),
    );
    const children = await Promise.all(promises);
    // files and directories have size too
    const size = children.reduce((acc, curr) => acc + curr.size, 0);
    const totalFileCount = children.reduce((acc, curr) => {
      if ((curr as Directory).isDirectory) {
        // if child is directory, add it's size
        return acc + (curr as Directory).totalFileCount;
      }
      // if child is file, add just one
      return acc + 1;
    }, 0);

    return {
      size,
      totalFileCount,
      name: topLevelEntry.entry.name,
      isDirectory: true,
      children,
    };
  }

  /**
   * Reads a file from a FileSystemFileEntry and returns it with a Promise
   * @param entry
   */
  private getFileFromEntry = (entry: FileSystemFileEntry): Promise<File> =>
    new Promise((resolve, reject) => {
      entry.file(resolve, reject);
    });
}
