import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Editor, mergeAttributes } from '@tiptap/core';
import Highlight from '@tiptap/extension-highlight';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { PluginKey } from '@tiptap/pm/state';
import StarterKit from '@tiptap/starter-kit';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgxTiptapModule } from 'ngx-tiptap';
import { TooltipModule } from 'primeng/tooltip';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { UploadDropDirective } from 'src/app/directives/upload-drop.directive';
import { RemoveFileExtensionPipe } from 'src/app/pipes/framework/remove-file-extension.pipe';
import { ShortenFileNamePipe } from 'src/app/pipes/framework/shorten-file-name.pipe';
import {
  CUSTOM_OVERLAY_VIEWS,
  CustomOverlayService,
} from 'src/app/services/custom-overlay.service';
import { IDefaultOverlayListItem } from '../../custom-overlay/mention-overlay/mention-overlay.component';
import { IFilesToUpload } from '../../messages/discussions-view/discussions-view.component';
import { UploadWindowComponent } from '../../upload/upload-window/upload-window.component';
import { PlaceholderInputComponent } from '../placeholder-input/placeholder-input.component';
import { linkExtension } from './rich-text-editor-extensions';

// TODO: would be nice to generalise this for the entire app, since this combination is used often
interface INameIdPair {
  id: number;
  name: string;
}

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    FormsModule,
    NgxTiptapModule,
    TooltipModule,
    PlaceholderInputComponent,
    NgScrollbar,
    RemoveFileExtensionPipe,
    ShortenFileNamePipe,
    UploadDropDirective,
    UploadWindowComponent,
    MatTooltipModule,
    NgIf,
    NgFor,
  ],
})
export class RichTextEditorComponent implements OnDestroy, OnInit {
  isDestroyed$: Subject<boolean> = new Subject<boolean>();

  @Input() theme: 'message' | 'editor' = 'message';

  @Input() enterKeyPressBehaviour: 'default' | 'custom' = 'default'; // set this to 'custom' to use the enterKeyPressed event
  @Output() enterKeyPress = new EventEmitter<string>(); // emits the current editor value when enter is pressed (used to submit messages on enter)

  @Input() allowFileUpload = true;
  @Input() filesToUpload: IFilesToUpload[] = [];
  @Output() filesToUploadChange = new EventEmitter<IFilesToUpload[]>();
  @Output() removedFileToUpload = new EventEmitter<{
    fileToUpload: IFilesToUpload;
    index: number;
  }>();

  @Input() placeholder = 'Type message here...';

  private isInternalChange = false; // Flag to track internal changes
  _editorValue = ''; // note: this is needed, since if this is not given to the editor, the "on change" event never gets triggered
  @Output() editorValueChange = new EventEmitter<string>();
  @Input() set editorValue(html: string) {
    if (this.isInternalChange) {
      this.isInternalChange = false; // Reset flag for next updates
      return;
    }

    this._editorValue = html;
    this.editor.commands.focus('end');
  }

  constructor(
    private overlayService: CustomOverlayService,
    private domSanitizer: DomSanitizer,
  ) {}

  showLinkInput = false;
  linkInputValue = '';

  _userMentions: INameIdPair[] = [];
  @Input() set userMentions(value: { user_id: number; name: string }[]) {
    if (!value) {
      console.error('User mentions are not set correctly. Recieved:', value);
      return;
    }
    this._userMentions = value.map((user) => ({ id: user.user_id, name: user.name }));
  }
  @Input() projectMentions: INameIdPair[] = [];

  mentionOverlayType: 'user-mention' | 'project-mention';
  mentionLastQueryLength = 0; // the last search query's length submitted to overlay by either user-mentions or project-mentions

  editor = new Editor({
    extensions: [
      StarterKit,
      Underline,
      Placeholder.configure({
        placeholder: this.placeholder!,
      }),
      Highlight.configure({
        multicolor: true,
      }),
      this.createMentionExtension(
        'user-mention',
        '@',
        (id) => `/users/${id}`,
        () => this._userMentions,
      ),
      this.createMentionExtension(
        'project-mention',
        '#',
        (id) => `webapp/projects/${id}`,
        () => this.projectMentions,
      ),
      linkExtension(),
    ],
    editorProps: {
      handleKeyDown: (_, event) => this.handleEditorKeydown(event),
    },
  });

  ngOnInit(): void {
    // Inserts the selected item from the overlay into the editor
    this.overlayService.outputData$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((data: IDefaultOverlayListItem) => {
        if (!data || !('id' in data)) return;

        const { from: cursorPos } = this.editor.state.selection; // Current selection range

        const deleteStart = cursorPos - this.mentionLastQueryLength - 1; // Start of deletion range (-1 for the '@')
        const deleteEnd = cursorPos;

        this.editor
          .chain()
          .focus()
          .deleteRange({ from: deleteStart, to: deleteEnd }) // Delete the '@' and the query
          .insertContentAt(deleteStart, [
            {
              type: this.mentionOverlayType,
              attrs: { id: data.id, label: data.name },
            },
          ])
          .run();
      });
  }

  emitEditorValueChange(val: string) {
    this.isInternalChange = true;
    this._editorValue = val;
    this.editorValueChange.emit(val);
  }

  openLinkInput() {
    this.showLinkInput = true;
    this.linkInputValue = this.editor.getAttributes('link').href;
  }
  confirmLink() {
    const url = this.linkInputValue;

    // empty
    if (!url) {
      this.discardLink();
      return;
    }

    // construct URL
    const parsedUrl = url.includes(':') ? new URL(url) : new URL(`https://${url}`);

    // update link
    this.showLinkInput = false;
    const linkInputMark = this.editor.chain().focus().extendMarkRange('link');
    linkInputMark.setLink({ href: parsedUrl.toString() }).run();
  }
  discardLink() {
    this.showLinkInput = false;
    const linkInputMark = this.editor.chain().focus().extendMarkRange('link');
    linkInputMark.unsetLink().run();
  }

  emitUploadFiles(files: File[]) {
    if (files.length > 0) {
      const newFilesToUpload = files.map((file) => {
        return { file, url: this.getFileURL(file) };
      });

      this.filesToUploadChange.emit(newFilesToUpload);
      this.filesToUpload = this.filesToUpload.concat(newFilesToUpload);
    }
  }
  emitRemoveFileToUpload(fileToUpload: IFilesToUpload, index: number) {
    this.removedFileToUpload.emit({
      fileToUpload,
      index,
    });
    this.filesToUpload.splice(index, 1);
  }
  // in case images have to be displayed
  private getFileURL(file): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  private handleEditorKeydown(event: KeyboardEvent): boolean | void {
    if (
      this.enterKeyPressBehaviour === 'custom' &&
      event.key === 'Enter' &&
      event.shiftKey === false &&
      event.ctrlKey === false &&
      !this.overlayService.isOpened()
    ) {
      this.enterKeyPress.emit(this._editorValue);
      event.preventDefault(); // Prevent Tiptap from adding a new line
      return true; // Prevent further handling of the event
    } else if (event.key === 'Escape') {
      // TODO: move the close command here, since it blocks link edit with hotkeys (esc should be used to discard) (for example)
      event.preventDefault(); // Prevent further Tiptap actions, as the Escape key is programmed (outside this component) to close the editor
      return true;
    }
    return false; // Allow default behavior for other keys
  }

  private createMentionExtension(
    extensionTag: 'user-mention' | 'project-mention',
    extensionChar: '@' | '#',
    extensionHref: (id: number) => string,
    extensionItems: () => INameIdPair[],
  ) {
    return Mention.extend({
      atom: true,
      name: extensionTag,
      addOptions: () => {
        return {
          deleteTriggerWithBackspace: true,
          HTMLAttributes: {
            class: extensionTag,
          },
          renderText({ options, node }) {
            return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
          },
          renderHTML({ options, node }) {
            return [
              'span',
              options.HTMLAttributes,
              [
                'a',
                mergeAttributes({
                  href: extensionHref(node.attrs.id),
                  target: '_blank', // Opens the link in a new tab
                  rel: 'noopener noreferrer', // Security best practice for external links
                }),
                `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
              ],
            ];
          },
          suggestion: {
            pluginKey: new PluginKey(extensionTag),
            char: extensionChar,
            items: ({ query }) => {
              this.mentionLastQueryLength = query.length;
              return extensionItems().filter((item) =>
                item.name.toLowerCase().includes(query.toLowerCase()),
              );
            },
            render: () => {
              return {
                onStart: (props) => {
                  if (props.items.length === 0) {
                    console.info('Prevented overlay open, due to empty items array.');
                    return;
                  }

                  const { from } = this.editor.view.state.selection;
                  const startPos = this.editor.view.coordsAtPos(from);
                  this.mentionOverlayType = extensionTag;
                  this.overlayService.openOverlay(
                    {
                      position: 'global',
                      left: `${startPos.left}px`,
                      top: `${startPos.top + 20}px`,
                      keyboardSelectionIsActive: true,
                    },
                    props.items,
                    CUSTOM_OVERLAY_VIEWS.DEFAULT,
                  );
                },
                onUpdate: (props) => {
                  if (props.items.length === 0) {
                    this.overlayService.closeOverlay();
                  } else {
                    const { from } = this.editor.view.state.selection;
                    const startPos = this.editor.view.coordsAtPos(from);
                    this.mentionOverlayType = extensionTag;
                    this.overlayService.openOverlay(
                      {
                        position: 'global',
                        left: `${startPos.left}px`,
                        top: `${startPos.top + 20}px`,
                        keyboardSelectionIsActive: true,
                      },
                      props.items,
                      CUSTOM_OVERLAY_VIEWS.DEFAULT,
                    );
                  }
                },
                onKeyDown: (props) => {
                  const event = props.event;
                  const items = extensionItems();

                  if (event.key === 'ArrowUp') {
                    this.overlayService.setKeyboardSelection(
                      (this.overlayService.keyboardSelection + items.length - 1) % items.length,
                    );
                    event.preventDefault();
                    return true;
                  } else if (event.key === 'ArrowDown') {
                    this.overlayService.setKeyboardSelection(
                      (this.overlayService.keyboardSelection + 1) % items.length,
                    );
                    event.preventDefault();
                    return true;
                  } else if (event.key === 'Enter') {
                    this.overlayService.emitKeyboardSelection();
                    event.preventDefault();
                    return true;
                  }

                  return false;
                },
                onExit: () => {
                  this.overlayService.closeOverlay();
                },
              };
            },
          },
        };
      },
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    this.editor.destroy();
  }
}
