/* eslint-disable */
// WARNING: saving this file with automatic import ordering causes issues. make sure to keep the import orders as is.
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebappRoutingModule } from './webapp-routing.module';
import { WebappComponent } from './webapp.component';
import { InteractionBarComponent } from '../../framework/interaction-bar/interaction-bar.component';
import { MenuBarComponent } from '../../framework/menu-bar/menu-bar.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { ProjectInfoContractorComponent } from './projects/bidding/project-info-contractor.component';
import { MiscellComponent } from './projects/miscell/miscell.component';
import { MoneyPipe } from '../../pipes/framework/money-short.pipe';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { NotificationsComponent } from '../../framework/interaction-bar/notifications/notifications.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ContractorsApiService } from '../../services/contractors-api.service';
import { SelectProjectComponent } from '../../framework/interaction-bar/select-project/select-project.component';
import { PhonePipe } from '../../pipes/framework/phone.pipe';
import { BytesPipe } from '../../pipes/framework/bytes.pipe';
import { IsApproved } from '../../guards/isUserActivated-guard';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImgLoadingComponent } from '../../framework/img-loading/img-loading.component';
import { ReportingBarComponent } from '../../framework/interaction-bar/reporting/reporting-bar.component';
import { UploadDropDirective } from '../../directives/upload-drop.directive';
import { AddPropertyComponent } from 'src/app/framework/interaction-bar/add-property/add-property.component';
import { AddPropertyViewComponent } from '../../framework/add-property-view/add-property-view.component';
import { DriveComponent } from './drive/drive.component';
import { StoreAppStateModule } from '../../store/store-app-state.module';
import { DocumentComponent } from './drive/document/document.component';
import { SheetComponent } from './drive/sheet/sheet.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GanttChartComponent } from '../../framework/gantt-chart/gantt-chart.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InputMoneyDirective } from '../../directives/input-money.directive';
import { ProjectBubbleComponent } from '../../framework/project-bubble/project-bubble.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProjectBudgetHistoryComponent } from './projects/view-project/spend/project-budget-history/project-budget-history.component';
import { ProjectsTableComponent } from '../../framework/projects-table/projects-table.component';
import { ProjectsContractorComponent } from './projects/projects-contractor/projects-contractor.component';
import { CaseTransformPipe } from '../../pipes/framework/case-transform.pipe';
import { ViewOrDownloadFileComponent } from './drive/view-or-download-file/view-or-download-file.component';
import { ProgressBarComponent } from '../../framework/progress-bar/progress-bar.component';
import { OverlayOptionsPipe } from '../../pipes/framework/overlay-options.pipe';
import { ShareDocumentComponent } from '../../framework/interaction-bar/share-document/share-document.component';
import { BottomNotificationComponent } from '../../framework/bottom-notification/bottom-notification.component';
import { SwitchFieldsPipe } from '../../pipes/framework/switch-fields.pipe';
import { SpreadsheetModule } from '@syncfusion/ej2-angular-spreadsheet';
import {
  DocumentEditorAllModule,
  DocumentEditorContainerModule,
} from '@syncfusion/ej2-angular-documenteditor';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { MessagesWrapperComponent } from '../../framework/messages/messages-wrapper/messages-wrapper.component';
import { MessagesHeaderComponent } from '../../framework/messages/messages-header/messages-header.component';
import { MessagesFooterComponent } from '../../framework/messages/messages-footer/messages-footer.component';
import { DiscussionsListComponent } from '../../framework/messages/discussions-list/discussions-list.component';
import { DiscussionsListItemComponent } from '../../framework/messages/discussions-list-item/discussions-list-item.component';
import { DiscussionsCreateComponent } from '../../framework/messages/discussions-create/discussions-create.component';
import { DiscussionsViewComponent } from '../../framework/messages/discussions-view/discussions-view.component';
import { MessageItemComponent } from '../../framework/messages/message-item/message-item.component';
import { DiscussionThreadViewComponent } from '../../framework/messages/discussion-thread-view/discussion-thread-view.component';
import { MessagingBubbleComponent } from '../../framework/messages/discussions-list-item/messaging-bubble/messaging-bubble.component';
import { SearchBarMinimalistComponent } from '../../framework/search-bar-minimalist/search-bar-minimalist.component';
import { BackButtonBoxComponent } from '../../framework/back-button-box/back-button-box.component';
import { BlobToBase64Pipe } from '../../pipes/framework/blob-to-base64.pipe';
import { CustomOverlayComponent } from '../../framework/custom-overlay/custom-overlay.component';
import { MessageItemAnnouncementComponent } from '../../framework/messages/message-item-announcement/message-item-announcement.component';
import { TemplateSelectorComponent } from './settings/templates/template-selector/template-selector.component';
import { BudgetTemplatesComponent } from './settings/templates/budget-templates/budget-templates.component';
import { GLAccountsComponent } from './settings/templates/glaccounts/glaccounts.component';
import { ShareDocumentLinkComponent } from '../../framework/interaction-bar/share-document-link/share-document-link.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { SpendTemplatesComponent } from './spend-templates/spend-templates.component';
import { BudgetTagTemplatesComponent } from './settings/templates/budget-tag-templates/budget-tag-templates.component';
import { AccountDropdownComponent } from '../../framework/account-dropdown/account-dropdown.component';
import { TemplatesHelpComponent } from './settings/templates/templates-help/templates-help.component';
import { SelectorInputComponent } from './settings/templates/template-selector/selector-input/selector-input.component';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoFocusModule } from 'primeng/autofocus';
import { TooltipModule } from 'primeng/tooltip';
import { DynamicTableComponent } from '../../framework/dynamic-table/dynamic-table.component';
import { FormattedMoneyComponent } from '../../framework/formated-money/formatted-money.component';
import { CommitmentSidebarTitleComponent } from '../../framework/commitments/sidebar/commitment-sidebar-title/commitment-sidebar-title.component';
import { CommitmentSidebarComponent } from '../../framework/commitments/sidebar/commitment-sidebar/commitment-sidebar.component';
import { ChangeOrderSidebarComponent } from '../../framework/commitments/sidebar/change-order-siderbar/change-order-sidebar.component';
import { InvoiceSidebarComponent } from '../../framework/commitments/sidebar/invoice-sidebar/invoice-sidebar.component';
import { MiscCostSidebarComponent } from '../../framework/commitments/sidebar/misc-cost-sidebar/misc-cost-sidebar.component';
import { IsValidUUIDPipe } from '../../pipes/framework/is-valid-uuid.pipe';
import { LineItemSpendSummaryComponent } from '../../framework/line-item-spend-summary/line-item-spend-summary.component';
import { OptionsListSimpleComponent } from '../../framework/overlays/options-list-simple/options-list-simple.component';
import { OverlayGeneralComponent } from '../../framework/overlays/overlay-general/overlay-general.component';
import { EditableInputComponent } from '../../framework/inputs/editable-input/editable-input.component';
import { MultiDropdownSelectComponent } from '../../framework/inputs/multi-dropdown-select/multi-dropdown-select.component';
import {
  CUSTOM_DATE_FORMATS,
  CustomDateAdapter,
} from '../../framework/inputs/calendar/CustomDateAdapter';
import { FloatingInputComponent } from '../../framework/inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../framework/inputs/dropdown/dropdown.component';
import { TagInputComponent } from '../../framework/inputs/tag-input/tag-input.component';
import { ArrayToComaSeparatedPipe } from '../../pipes/framework/array-to-list.pipe';
import { InputUploadComponent } from '../../framework/input-upload/input-upload.component';
import { ShortenFileNamePipe } from '../../pipes/framework/shorten-file-name.pipe';
import { FloatingTextareaComponent } from '../../framework/inputs/floating-textarea/floating-textarea.component';
import { CustomInputOverlayDropdownComponent } from '../../framework/custom-input-overlay-dropdown/custom-input-overlay-dropdown.component';
import { UploadHiddenInputComponent } from '../../framework/upload/upload-hidden-input/upload-hidden-input.component';
import { DefaultUploadUiComponent } from '../../framework/upload/default-upload-ui/default-upload-ui.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ChecklistItemComponent } from '../../framework/inputs/checklist-item/checklist-item.component';
import { UploadWindowComponent } from '../../framework/upload/upload-window/upload-window.component';
import { DefaultOptionsListComponent } from '../../framework/default-options-list/default-options-list.component';
import { UnStyledOptionsListComponent } from '../../framework/overlays/un-styled-options-list/un-styled-options-list.component';
import { OptionsPermissionsPipe } from '../../pipes/framework/options-permissions.pipe';
import { ContainsValuePipe } from '../../pipes/framework/contains-value.pipe';
import { StyledOptionsListComponent } from '../../framework/overlays/styled-options-list/styled-options-list.component';
import { OptionsListStyledComponent } from '../../framework/overlays/options-list-styled/options-list-styled.component';
import { DriveEmptyAreaComponent } from '../../framework/drive-empty-area/drive-empty-area.component';
import { PricingBannerComponent } from '../../framework/pricing/pricing-banner/pricing-banner.component';
import { ImgPreviewComponent } from '../../framework/img-preview/img-preview.component';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SmoothScroll } from 'ngx-scrollbar/smooth-scroll';
import { ItemTrackListingComponent } from '../../framework/activities/item-track-listing/item-track-listing.component';
import { TrackItemSidebarComponent } from '../../framework/interaction-bar/track-item-sidebar/track-item-sidebar.component';
import { InputCalendarComponent } from '../../framework/inputs/input-calendar/input-calendar.component';
import { ChecklistDropdownComponent } from '../../framework/inputs/checklist-dropdown/checklist-dropdown.component';
import { ExpansionPanelComponent } from '../../framework/expansion-panel/expansion-panel.component';
import { DynamicProjectedTableComponent } from '../../framework/dynamic-projected-table/dynamic-projected-table.component';
import { CheckboxDropdownComponent } from '../../framework/checkbox-dropdown/checkbox-dropdown.component';
import { ActivitySidebarComponent } from '../../framework/activity-sidebar/activity-sidebar.component';
import { DayjsFormatterPipe } from '../../pipes/framework/dayjs-formatter.pipe';
import { SortArrowComponent } from '../../framework/sort-arrow/sort-arrow.component';
import { ActivityCardAssignButtonComponent } from './activities/activity-card-assign-button/activity-card-assign-button.component';
import { ProgressBarProjectableComponent } from '../../framework/progress-bar-projectable/progress-bar-projectable.component';
import { DaysCountComponent } from '../../framework/progress-bar-projectable/days-count/days-count.component';
import { RollupsInteractionBarComponent } from './rollups/rollups-interaction-bar/rollups-interaction-bar.component';
import { AddButtonComponent } from '../../framework/buttons/add-button/add-button.component';
import { ManageTeamMembersSidebarComponent } from '../../framework/teams/manage-team-members-sidebar/manage-team-members-sidebar.component';
import { SharedTeamPropProjSidebarComponent } from '../../framework/teams/team-prop-proj-sidebar/shared-team-prop-proj-sidebar.component';
import { SimpleSmallButtonComponent } from '../../framework/buttons/simple-small-button/simple-small-button.component';
import { ManageProjectComponent } from './projects/view-project/manage-project/manage-project.component';
import { SpendDistributionComponent } from './projects/add-project/spend-distribution/spend-distribution.component';
import { PermissionToTextPipe } from '../../pipes/framework/permission-to-text.pipe';
import { DrivePermissionsPipe } from '../../pipes/framework/drivePermissions.pipe';
import { CommonPermissionsPipe } from '../../pipes/framework/common-permissions.pipe';
import { UncommonPermissionsPipe } from '../../pipes/framework/uncommon-permissions.pipe';
import { SimpleButtonComponent } from '../../framework/buttons/simple-medium-button/simple-button.component';
import { CashflowTableComponent } from '../../framework/cash-flow/cashflow-table/cashflow-table.component';
import { PageLoadingComponent } from '../../framework/page-loading/page-loading.component';
import { BaseChartDirective } from 'ng2-charts';
import { SpendCustomInputComponent } from '../../framework/inputs/spend-custom-input/spend-custom-input.component';
import { BudgetAdjustmentLogComponent } from '../../framework/interaction-bar/budget-adjustment-log/budget-adjustment-log.component';
import { AnticipatedCostsComponent } from '../../framework/anticipated-costs/anticipated-costs.component';
import { CommitmentsSummaryComponent } from '../../framework/commitments/commitments-summary/commitments-summary.component';
import { BudgetAdjustmentComponent } from '../../framework/budget-adjustment/budget-adjustment.component';
import { GeneralSidebarHeaderComponent } from '../../framework/general-sidebar-header/general-sidebar-header.component';
import { ContractSidebarComponent } from '../../framework/commitments/sidebar/contract-sidebar/contract-sidebar.component';
import { ArrowButtonBoxComponent } from '../../framework/dropdown-button-box/arrow-button-box.component';
import { SpendEntryComponent } from '../../framework/commitments/sidebar/spend-entry/spend-entry.component';
import { CommitmentsSidebarHelpComponent } from '../../framework/commitments/sidebar/commitments-sidebar-help/commitments-sidebar-help.component';
import { CommitmentEntryTextComponent } from '../../framework/commitments/sidebar/commitment-entry-text/commitment-entry-text.component';
import { UploadCommitmentsSidebarComponent } from '../../framework/commitments/sidebar/upload-commitments-sidebar/upload-commitments-sidebar.component';
import { CostDescriptionComponent } from '../../framework/commitments/sidebar/cost-description/cost-description.component';
import { BudgetTagTemplatesDropdownComponent } from '../../framework/commitments/budget-tag-templates-dropdown/budget-tag-templates-dropdown.component';
import { CompanyDropdownComponent } from '../../framework/commitments/sidebar/company-dropdown/company-dropdown.component';
import { DateCustomPipe } from '../../pipes/framework/date-custom.pipe';
import { FadedTextComponent } from '../../framework/faded-text/faded-text.component';
import { RemoveLoggedInUserPipe } from '../../pipes/framework/remove-logged-in-user.pipe';
import { HandleInteractionBarPositionPipe } from '../../pipes/framework/handle-interaction-bar-position.pipe';
import { MessagingUsersTableComponent } from '../../framework/messages/messaging-users-table/messaging-users-table.component';
import { IsMessageEmptyPipe } from '../../pipes/framework/is-message-empty.pipe';
import { RichTextEditorComponent } from 'src/app/framework/inputs/rich-text-editor/rich-text-editor.component';
import { SplashScreenComponent } from 'src/app/framework/splash-screen/splash-screen.component';
import { LandingComponent } from '../landing/landing.component';

@NgModule({
  imports: [
    CommonModule,
    WebappRoutingModule,
    MatSidenavModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    MatListModule,
    MatTooltipModule,
    NgScrollbarModule,
    NgSelectModule,
    ImageCropperModule,
    MatMenuModule,
    StoreAppStateModule,
    DragDropModule,
    MatExpansionModule,
    SpreadsheetModule,
    DialogModule,
    DocumentEditorContainerModule,
    DocumentEditorAllModule,
    MatProgressBarModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    CalendarModule,
    InputTextareaModule,
    AutoFocusModule,
    TooltipModule,
    FloatingInputComponent,
    DropdownComponent,
    TagInputComponent,
    TagInputComponent,
    ArrayToComaSeparatedPipe,
    InputUploadComponent,
    ShortenFileNamePipe,
    UploadDropDirective,
    FloatingTextareaComponent,
    CustomInputOverlayDropdownComponent,
    OverlayGeneralComponent,
    UploadHiddenInputComponent,
    DefaultUploadUiComponent,
    InputSwitchModule,
    UploadWindowComponent,
    ChecklistItemComponent,
    DefaultOptionsListComponent,
    UnStyledOptionsListComponent,
    OptionsPermissionsPipe,
    ContainsValuePipe,
    StyledOptionsListComponent,
    OptionsListStyledComponent,
    DriveEmptyAreaComponent,
    PricingBannerComponent,
    ImgPreviewComponent,
    MatButton,
    SmoothScroll,
    TrackItemSidebarComponent,
    InputCalendarComponent,
    ChecklistDropdownComponent,
    ExpansionPanelComponent,
    SplashScreenComponent,
    DynamicProjectedTableComponent,
    CheckboxDropdownComponent,
    ActivitySidebarComponent,
    DayjsFormatterPipe,
    SortArrowComponent,
    ActivityCardAssignButtonComponent,
    ProgressBarProjectableComponent,
    DaysCountComponent,
    AddButtonComponent,
    ManageTeamMembersSidebarComponent,
    BackButtonBoxComponent,
    SharedTeamPropProjSidebarComponent,
    SimpleSmallButtonComponent,
    ManageProjectComponent,
    SpendDistributionComponent,
    SimpleButtonComponent,
    PermissionToTextPipe,
    DrivePermissionsPipe,
    CommonPermissionsPipe,
    UncommonPermissionsPipe,
    SpendCustomInputComponent,
    BudgetAdjustmentLogComponent,
    CashflowTableComponent,
    PageLoadingComponent,
    BaseChartDirective,
    AnticipatedCostsComponent,
    CommitmentsSummaryComponent,
    BudgetAdjustmentComponent,
    GeneralSidebarHeaderComponent,
    CommitmentSidebarTitleComponent,
    ContractSidebarComponent,
    ArrowButtonBoxComponent,
    SpendEntryComponent,
    CommitmentsSidebarHelpComponent,
    CommitmentEntryTextComponent,
    UploadCommitmentsSidebarComponent,
    CostDescriptionComponent,
    BudgetTagTemplatesDropdownComponent,
    CompanyDropdownComponent,
    DateCustomPipe,
    FadedTextComponent,
    RemoveLoggedInUserPipe,
    HandleInteractionBarPositionPipe,
    MessagingUsersTableComponent,
    IsMessageEmptyPipe,
    ProjectBubbleComponent,
    InteractionBarComponent,
    MenuBarComponent,
    WebappComponent,
    ContractorsComponent,
    ProjectInfoContractorComponent,
    MiscellComponent,
    NotificationsComponent,
    SelectProjectComponent,
    PhonePipe,
    BytesPipe,
    SwitchFieldsPipe,
    OverlayOptionsPipe,
    ImgLoadingComponent,
    ReportingBarComponent,
    AddPropertyComponent,
    AddPropertyViewComponent,
    DocumentComponent,
    RichTextEditorComponent,
    DriveComponent,
    SheetComponent,
    ShareDocumentComponent,
    ShareDocumentLinkComponent,
    GanttChartComponent,
    InputMoneyDirective,
    ProjectBudgetHistoryComponent,
    ProjectsTableComponent,
    ProjectsContractorComponent,
    ViewOrDownloadFileComponent,
    ProgressBarComponent,
    BottomNotificationComponent,
    MessagesWrapperComponent,
    MessagesHeaderComponent,
    MessagesFooterComponent,
    DiscussionsListComponent,
    DiscussionsListItemComponent,
    DiscussionsCreateComponent,
    DiscussionsViewComponent,
    MessageItemComponent,
    DiscussionThreadViewComponent,
    MessagingBubbleComponent,
    SearchBarMinimalistComponent,
    BlobToBase64Pipe,
    IsValidUUIDPipe,
    CustomOverlayComponent,
    OptionsListSimpleComponent,
    MessageItemAnnouncementComponent,
    BudgetTemplatesComponent,
    GLAccountsComponent,
    TemplateSelectorComponent,
    TeamManagementComponent,
    SpendTemplatesComponent,
    BudgetTagTemplatesComponent,
    AccountDropdownComponent,
    TemplatesHelpComponent,
    EditableInputComponent,
    SelectorInputComponent,
    DynamicTableComponent,
    RollupsInteractionBarComponent,
    FormattedMoneyComponent,
    CommitmentSidebarComponent,
    ChangeOrderSidebarComponent,
    InvoiceSidebarComponent,
    MiscCostSidebarComponent,
    LineItemSpendSummaryComponent,
    MultiDropdownSelectComponent,
    ItemTrackListingComponent,
    LandingComponent,
  ],
  exports: [
    DynamicTableComponent,
    GanttChartComponent,
    ItemTrackListingComponent,
    DriveComponent,
    ProgressBarComponent,
    ProjectBudgetHistoryComponent,
  ],
  providers: [
    MoneyPipe,
    CaseTransformPipe,
    ContractorsApiService,
    PhonePipe,
    BytesPipe,
    OverlayOptionsPipe,
    SwitchFieldsPipe,
    BlobToBase64Pipe,
    IsValidUUIDPipe,
    IsApproved,
    NgForm,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    },
  ],
})
export class WebappModule {}
