import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { FormsModule, NgForm } from '@angular/forms';
import { InputCalendarComponent } from '../../inputs/input-calendar/input-calendar.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { TagInputComponent } from '../../inputs/tag-input/tag-input.component';
import {
  Project,
  PROJECT_STATUS_KEY,
  ProjectStatus,
} from '../../../pages/webapp/projects/projects.interface';
import { getStoreUpdates } from '../../../store/spend/spend.selectors';
import { Subject } from 'rxjs';
import { NotificationsService } from '../../../services/notifications.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app-state';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { ILineItem } from '../../../store/spend/spend.interfaces';
import { TooltipModule } from 'primeng/tooltip';
import dayjs from 'dayjs';
import { propertiesActions } from '../../../store/properties/properties.actions';
import { propertiesSelectors } from '../../../store/properties/properties.selector';
import { projectActions } from '../../../store/projects/projects.actions';
import {
  getProjectStatuses,
  isEditProjectLoading,
} from '../../../store/projects/projects.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { ROUTE_WEBAPP } from '../../constants/route.webapp.constants';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { loadSpends } from '../../../store/spend/spend.actions';
import { IProperty } from '../../../store/properties/properties.interfaces';
import { viewProjectActions } from '../../../store/view-project/view-project.actions';
import { PageLoadingComponent } from '../../page-loading/page-loading.component';
import { CurrentUserService } from '../../../services/current-user.service';
import { ProjectApiService } from '../../../services/project-api.service';

@Component({
  selector: 'app-project-setup',
  standalone: true,
  imports: [
    DropdownComponent,
    FloatingInputComponent,
    FormsModule,
    InputCalendarComponent,
    NgIf,
    NgScrollbar,
    NgClass,
    TagInputComponent,
    TooltipModule,
    PageLoadingComponent,
    AsyncPipe,
  ],
  templateUrl: './project-setup.component.html',
  styleUrl: './project-setup.component.scss',
})
export class ProjectSetupComponent implements OnInit, OnDestroy {
  @Input() isEdit: boolean;
  @ViewChild('setupForm') setupForm: NgForm;

  PROJECT_STATUS_KEY = PROJECT_STATUS_KEY;
  projectStatuses: ProjectStatus[] = [];
  properties: IProperty[] = [];
  earliestLineItemDate: string | null;
  oldSelectedProperty: number = null;

  properties$ = this.store.select(propertiesSelectors.getAllProperties);
  projectStatuses$ = this.store.select(getProjectStatuses);
  lastStoreUpdate$ = this.store.select(getStoreUpdates);
  isEditProjectLoading$ = this.store.select(isEditProjectLoading);
  isDestroyed$ = new Subject();

  hasCommitments = false;

  model: Partial<Project & { auto_generated_project_id: string }> = {
    status: null,
    title: '',
    start_date: null,
    property_id: null,
    tags: [],
    id: null,
    budget_template_id: null,
    budget_template: null,
    custom_project_id: '',
    auto_generated_project_id: null,
  };
  customIdEditable = false;
  originalAutoId = null;
  hasCustomId = false;

  constructor(
    private notif: NotificationsService,
    private router: Router,
    private store: Store<AppState>,
    private actions: Actions,
    private interactionBarService: InteractionBarStateService,
    private userService: CurrentUserService,
    private projectService: ProjectApiService,
  ) {}

  ngOnInit() {
    this.loadData();
    this.properties$.pipe(takeUntil(this.isDestroyed$)).subscribe((properties) => {
      this.properties = properties;
    });
    this.projectStatuses$.pipe(takeUntil(this.isDestroyed$)).subscribe((statuses) => {
      this.setAvailableProjectStatuses(statuses);
    });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.projectCreatedSuccessfully, projectActions.updateProjectSuccess),
        tap((action) => {
          const message =
            action.type === projectActions.projectCreatedSuccessfully.type ? 'created' : 'updated';
          this.notif.showSuccess(`Project successfully ${message}!`);
        }),
        delay(250),
      )
      .subscribe((action) => {
        if (action.type === projectActions.projectCreatedSuccessfully.type) {
          this.router.navigate([ROUTE_WEBAPP.BASE, ROUTE_WEBAPP.PROJECTS, action.projectId], {});
        } else {
          this.store.dispatch(viewProjectActions.refreshNeeded({}));
        }
        this.interactionBarService.close();
      });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.editProjectDataLoaded),
        tap((action) => {
          this.store.dispatch(loadSpends({ projectId: action.project.id }));
        }),
        delay(100),
      )
      .subscribe((action) => {
        this.model = {
          id: action.project.id,
          status: action.project.status,
          title: action.project.title,
          start_date: action.project.start_date,
          property_id: action.project.property_id,
          tags: [...action.project.tags],
          budget_template_id: action.project.budget_template_id,
          budget_template: action.project.budget_template,
          custom_project_id:
            action.project.custom_project_id ?? action.project.auto_generated_project_id,
        };
        // can edit template if no commitments
        this.hasCommitments = action.project.has_commitments_or_mods;
        this.originalAutoId = action.project.auto_generated_project_id;
        this.hasCustomId =
          action.project.custom_project_id !== action.project.auto_generated_project_id;
        if (this.hasCustomId && !this.hasCommitments) {
          this.customIdEditable = true;
        }

        this.changeProperty(this.model.property_id);
        this.determineEarliestLineItemDate();
      });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.successfulProjectDeletion),
        tap(() => {
          this.notif.showSuccess('Project successfully deleted!');
        }),
        delay(100),
        tap(() => {
          this.router.navigate([ROUTE_WEBAPP.BASE, ROUTE_WEBAPP.PROJECTS], {});
        }),
        delay(200),
      )
      .subscribe((_) => {
        this.interactionBarService.close();
      });
  }

  loadData() {
    this.store.dispatch(propertiesActions.loadProperties({}));
  }

  determineEarliestLineItemDate() {
    this.lastStoreUpdate$.pipe(takeUntil(this.isDestroyed$)).subscribe((storeUpdate) => {
      const { lineItems } = storeUpdate;

      if (!lineItems || lineItems.length === 0) {
        this.earliestLineItemDate = null;
        return;
      }

      const lineItemDates = lineItems.map((item) => this.lineItemMinDate(item));
      this.earliestLineItemDate = dayjs.min(lineItemDates).format('YYYY-MM-DD');
    });
  }

  lineItemMinDate(item: ILineItem) {
    return dayjs(item.start_date);
  }

  setAvailableProjectStatuses(statuses: ProjectStatus[]) {
    const hiddenStatuses: PROJECT_STATUS_KEY[] = [PROJECT_STATUS_KEY.DELETED];

    this.projectStatuses = statuses.filter((status) => {
      return !hiddenStatuses.includes(status.key as PROJECT_STATUS_KEY);
    });
  }

  /**
   * Validates and saves project data.
   */
  save() {
    this.setupForm.form.markAllAsTouched();

    const startDateControl = this.setupForm.form.controls.start_date;
    if (startDateControl?.hasError('matDatepickerMax')) {
      this.notif.showError(
        "Start date needs to be before or equal the line item's budget or forecast start date!",
      );
      return;
    }

    if (this.setupForm.invalid) {
      this.notif.showError('Please fill in all required fields.');
      return;
    }

    if (this.isEdit) {
      const editedProject = { ...this.model };

      if (this.originalAutoId === this.model.custom_project_id) {
        editedProject.custom_project_id = null;
      }

      if (this.hasCommitments) {
        delete editedProject.custom_project_id;
        delete editedProject.auto_generated_project_id;
      }

      this.store.dispatch(projectActions.updateProject({ project: editedProject }));
      return;
    }

    this.model.auto_generated_project_id = this.originalAutoId;
    this.store.dispatch(projectActions.createProject({ project: { ...this.model } }));
  }

  /**
   * If properties changed, set default project template and if needed ask the
   * user if he/she wants to reset the budget.
   */
  propertiesChanged(newPropertyId: number) {
    const selectedProperty = this.properties.find((property) => property.id === newPropertyId);

    if (this.hasCommitments && selectedProperty?.team_id !== this.model?.budget_template?.team_id) {
      this.notif.showError('Property change blocked due to commitments and team mismatch.');
      this.changeProperty(this.oldSelectedProperty);
      return;
    }

    if (
      selectedProperty?.team_id === this.model?.budget_template?.team_id ||
      !this.model?.budget_template
    ) {
      this.changeProperty(newPropertyId);
    } else {
      this.notif
        .showPopup(
          'Selected property does not have the same team as the selected budget template. Template will reset. Continue?',
        )
        .then((resp) => {
          if (resp) {
            this.changeProperty(newPropertyId);
            this.model.budget_template_id = null;
          } else {
            this.changeProperty(this.oldSelectedProperty);
          }
        });
    }
  }

  /**
   * Changes the property and filters and resets the budget template.
   */
  changeProperty(propertyId: number) {
    this.oldSelectedProperty = propertyId;
    this.model.property_id = propertyId;

    if (!this.isEdit) {
      this.updateProjectId({
        propertyId,
        year: this.model.start_date ? dayjs(this.model.start_date).year() : undefined,
      });
    }
  }

  delete() {
    this.notif.showPopup('Are you sure you want to delete this project?').then((answer) => {
      if (answer) {
        this.store.dispatch(projectActions.deleteProject({ id: this.model.id }));
      }
    });
  }

  updateProjectId({ propertyId, year }: { propertyId?: number; year?: number }) {
    if (!propertyId || !year) {
      return;
    }

    this.projectService
      .getNextProjectId({ property_id: propertyId, year })
      .subscribe((response) => {
        this.model.custom_project_id = response.next_custom_id;
        this.originalAutoId = response.next_custom_id;
      });
  }

  startDateChanged(date: string) {
    if (!this.isEdit) {
      this.updateProjectId({ propertyId: this.model.property_id, year: dayjs(date).year() });
    }
  }

  onCustomIdIconClick() {
    if (this.customIdEditable) {
      // if it was editable, reset to original
      this.model.custom_project_id = this.originalAutoId;
    }
    this.customIdEditable = !this.customIdEditable;
  }

  customIdChanged(customId: string) {
    if (customId && typeof customId === 'string') {
      this.model.custom_project_id = customId.toUpperCase();
    }
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
