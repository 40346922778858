<section id="hero" class="bg-grey_100 w-full flex justify-center items-center">
  <div
    class="landing-section relative py-14 flex flex-col justify-end overflow-hidden min-h-[56rem] max-xl:min-h-[48rem]"
  >
    <video
      class="absolute left-0 top-0 w-full h-full object-cover object-center opacity-30"
      #video
      (canplay)="video.play()"
      muted="true"
      autoplay="true"
      loop="true"
      disablePictureInPicture="true"
      (loadedmetadata)="
        video.muted = true; video.loop = true; video.autoplay = true; video.disablePictureInPicture = true
      "
    >
      <source src="assets/videos/landing-hero-bg.webm" type="video/webm" />
      <source src="assets/videos/landing-hero-bg.mp4" type="video/mp4" />

      <!-- Image placeholder -->
      <img
        src="assets/images/landing-hero-placeholder.webp"
        alt="Video placeholder"
        class="w-full h-full object-cover"
      />

      <!-- TODO: add img placeholder -->
      Your browser does not support the video tag.
    </video>
    <img
      class="absolute scale-[0.75] right-0 bottom-0 translate-x-[30%] translate-y-[30%]"
      src="assets/images/landing-lines.webp"
    />
    <div class="flex justify-between items-end z-10 max-lg:flex-col max-lg:items-start gap-4">
      <div class="relative flex flex-col gap-6 max-lg:gap-2">
        <h1 class="my-0 text-5xl font-semibold text-navy_700 max-w-[600px] max-lg:text-3xl">
          Manage construction projects with ease on one powerful platform
        </h1>
        <p class="my-0 text-xl leading-normal text-black_500 max-w-[750px] max-lg:text-base">
          BuildEvo simplifies project management with a secure platform that gives you real-time visibility and control
          over every project.
        </p>
      </div>
      <a
        (click)="tryBuildevo()"
        class="px-8 py-4 text-lg text-white text-nowrap no-underline bg-navy_700 rounded-[32px] flex items-center justify-center hover:bg-blue_500"
      >
        <span>Try BuildEvo</span>
        <i class="icon-arrow-round-right-filled ml-1.5 text-3xl"> </i>
      </a>
    </div>
  </div>
</section>
