<header #header class="header-section">
  <div class="landing-section flex relative justify-between items-center">
    <a routerLink="/" fragment="hero">
      <img src="assets/icons/buildevo_logo_full.svg" altText="BuildEvo Logo" class="h-6 w-[125px]" />
    </a>
    <nav class="flex justify-evenly items-center gap-16 max-xl:gap-8 max-lg:hidden text-black_500 text-nowrap">
      <a
        routerLink="/"
        fragment="features"
        [class.active]="currentFragment === 'features'"
        class="be-link text-base font-medium"
        >Features</a
      >
      <a
        routerLink="/"
        fragment="use-cases"
        [class.active]="currentFragment === 'use-cases'"
        class="be-link text-base font-medium"
        >Use Cases</a
      >
      <a
        routerLink="/"
        fragment="workflow"
        [class.active]="currentFragment === 'workflow'"
        class="be-link text-base font-medium"
        >How it works</a
      >
      <a
        routerLink="/"
        fragment="pricing"
        [class.active]="currentFragment === 'pricing'"
        class="be-link text-base font-medium"
        >Pricing</a
      >
      <a
        routerLink="/"
        fragment="about-us"
        [class.active]="currentFragment === 'about-us'"
        class="be-link text-base font-medium"
        >About Us</a
      >
    </nav>
    <div class="flex items-center justify-center text-black_500">
      <a (click)="redirectToLogin()" class="text-base text-center font-medium no-underline w-[80px]">Login</a>
    </div>
  </div>
</header>
