import { concatLatestFrom } from '@ngrx/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { MessagesApiService } from '../../services/messages-api.service';
import {
  catchError,
  debounceTime,
  exhaustMap,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  getMessageFilters,
  getProfilePicCache,
  getSelectedGroup,
  getSelectedGroupId,
  getThreadMessages,
  messagingFeatureSelector,
} from './messages.selectors';
import {
  ICreateMessageData,
  IGroupMessagesAndDrafts,
  IMessage,
  IMessageFilters,
  MESSAGING_VIEWS,
} from './messages.interfaces';
import { Observable, of } from 'rxjs';
import {
  addImageToCache,
  loadSingleProfilePicture,
  messagesActions,
  updateDraft,
} from './messages.actions';
import moment from 'moment/moment';
import { RestRequestService } from '../../restApi/rest-request.service';
import {
  DEFAULT_IMAGE,
  MESSAGES_NOT_FIRST_PAGE_LENGTH,
} from '../../framework/constants/messages.constants';
import { CurrentUserService } from '../../services/current-user.service';
import { MessagesStateService } from '../../services/messages-state.service';
import { NotificationsService } from '../../services/notifications.service';
import { DriveApiService } from '../../services/drive-api.service';

@Injectable()
export class MessagingEffects {
  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private messageApiService: MessagesApiService,
    private rest: RestRequestService,
    private userService: CurrentUserService,
    private messagesStateService: MessagesStateService,
    private notif: NotificationsService,
    private driveApi: DriveApiService,
  ) {}

  /**
   * It creates or updates a message in the store and on the backend too.
   * It uses debounceTime so you can call it frequently.
   * If a message is not a draft, first it saves locally and then updates it with the request response.
   */
  createMessage$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.createOrUpdateMessage),
      withLatestFrom(
        this.store.select(messagingFeatureSelector),
        this.store.select(getSelectedGroupId),
        this.userService.data$,
      ),
      // merge map combined with debounce time will take fewer dispatches at a determined interval,
      // including the final request as draft: false
      // also merge map will allow previous requests that did not finish yet to finish unlike switch map
      // finally using concatMap seems to be the best solution - it handles quite well if more messages are sent quickly
      mergeMap(([action, state, groupId, userData]) => {
        const foundDraft = this.findDraft(state, groupId);
        const body = action.message ? action.message : state.message;
        const dataToSend = {
          id: foundDraft ? foundDraft.id : undefined,
          group_id: groupId,
          body,
          is_draft: false,
          thread_id: state.thread_id,
        };
        const upsertRequest: Observable<IMessage | any> = this.getUpsertRequest(
          dataToSend,
          !!foundDraft,
        );

        if (isBodyEmpty(body) && !state.files.length) {
          console.log(`body empty: '${body}'`);
          this.notif.showError('Please add a message.');
          return of(null);
        }

        // if it is a new message, append immediately, not waiting the request
        const date = moment().toISOString();
        const message = {
          ...dataToSend,
          created_at: date, // for sorting purposes
          updated_at: date,
          thread: [],
          isLocalOnly: true,
          user: {
            id: userData.id, // it will be filled later because we don't know the other dates here.
          },
        };
        // delete  message.id;
        // it's important to call the dispatch here and not wait for the request to finish.
        this.store.dispatch(
          messagesActions.addGroupMessage({
            message,
            isDraft: false,
          }),
        );
        this.messagesStateService.addMessage$.next({
          messages: [message],
          shouldScrollToBottom: true,
        });
        return upsertRequest;
      }),
      // adding a message when the backend responds is handled in message state service via websockets
      map((message) => {
        if (message === null || message.error) {
          console.log('cancel message', message);
        }
        return messagesActions.cancelMessages();
      }),
    );
  });

  createDraft$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.createOrUpdateDraft, messagesActions.createOrUpdateMessage),
      debounceTime(10),
      concatLatestFrom(() => [
        this.store.select(messagingFeatureSelector),
        this.store.select(getSelectedGroupId),
      ]),
      // switchMap has a cancelling effect which is important here
      switchMap(([action, state, groupId]) => {
        if (!state.message || action === messagesActions.createOrUpdateMessage) {
          return of(null);
        }
        if (!groupId) {
          return of(null);
        }
        const foundDraft = this.findDraft(state, groupId);
        const body = action.message ? action.message : state.message;
        const dataToSend = {
          id: foundDraft ? foundDraft.id : undefined,
          group_id: groupId,
          body,
          is_draft: true,
          thread_id: state.thread_id,
        };
        return this.getUpsertRequest(dataToSend, !!foundDraft);
      }),
      map((message) => {
        if (message === null || message.error) {
          console.log('cancel message', message);
          return messagesActions.cancelMessages();
        }
        return updateDraft({ draft: message });
      }),
    );
  });

  loadMessages$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.loadMessages),
      tap(() => {
        this.store.dispatch(messagesActions.setIsLoading({ isLoading: true }));
      }),
      concatLatestFrom(() => this.store.select(getSelectedGroupId)),
      mergeMap(([_, groupId]) => {
        return this.messageApiService.getGroupMessages(groupId, { perPage: 30 });
      }),
      map((groupMessages) => {
        this.loadProfilePictures(groupMessages);
        console.log('got messages from backend', new Date().getTime());
        return messagesActions.setMessages({ groupMessagesAndDrafts: groupMessages });
      }),
    );
  });

  loadMessagesPaginated$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.loadMessagesPaginated),
      tap(() => {
        this.store.dispatch(messagesActions.setIsLoading({ isPaginationLoading: true }));
      }),
      withLatestFrom(this.store.select(getSelectedGroup), this.store.select(getMessageFilters)),
      mergeMap(([action, group, messageFilters]) => {
        const id = group.messages?.[group.messages?.length - 1]?.id;
        if (!id) {
          return of({ messages: undefined });
        }
        if (group.loadedAllPastMessages) {
          return of({ messages: undefined });
        }
        const filter: IMessageFilters = {
          offset: id,
          perPage: MESSAGES_NOT_FIRST_PAGE_LENGTH,
        };
        if (messageFilters.search) {
          filter.search = messageFilters.search;
        }
        return this.messageApiService.getGroupMessages(group.id, filter);
      }),
      map((groupMessages: { messages: IMessage[] }) => {
        // if (groupMessages?.messages === undefined) {
        //   return messagesActions.appendToMessages({ messages: [] });
        // }
        this.messagesStateService.addMessage$.next({
          messages: groupMessages.messages,
          shouldScrollToBottom: false,
        });
        return messagesActions.appendToMessages({ messages: groupMessages.messages });
      }),
    );
  });

  loadSingleProfilePicture$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.loadSingleProfilePicture),
      concatLatestFrom(() => this.store.select(getProfilePicCache)),
      exhaustMap(([action, profilePicturesCache]) => {
        if (!action.user) {
          return of(messagesActions.cancelMessages());
        }
        if (!action?.user?.profile_picture_id) {
          return of(
            messagesActions.addImageToCache({
              imageSrc: DEFAULT_IMAGE,
              user_id: action.user.id,
            }),
          );
        }
        if (
          profilePicturesCache[action.user.id] &&
          profilePicturesCache[action.user.id] !== DEFAULT_IMAGE
        ) {
          return of(messagesActions.cancelMessages());
        }
        return this.driveApi.getFileViewURL(action.user.profile_picture_id).then((imageSrc) => {
          return messagesActions.addImageToCache({ imageSrc, user_id: action.user.id });
        });
      }),
    );
  });

  reloadDraftMessages$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.reloadDrafts),
      concatLatestFrom(() => this.store.select(getSelectedGroupId)),
      mergeMap(([_, groupId]) => {
        return this.messageApiService.getGroupMessages(groupId);
      }),
      map((messagesState: IGroupMessagesAndDrafts) => {
        return messagesActions.setDrafts({ drafts: messagesState.drafts });
      }),
    );
  });

  createThreadMessages$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.createThreadMessage),
      withLatestFrom(
        this.store.select(messagingFeatureSelector),
        this.store.select(getThreadMessages),
      ),
      exhaustMap(([action, state, thread]) => {
        console.warn(action, state, thread);
        return this.messageApiService.createMessageThread({
          body: state.message,
          thread_id: thread.mainMessage.id,
        });
      }),
      map((message) => {
        this.store.dispatch(loadSingleProfilePicture({ user: message.user }));
        return messagesActions.addGroupMessage({ message });
        // return messagesActions.cancelMessages();
      }),
      catchError((err) => {
        console.warn('createThreadMessages$ err', err);
        return of(messagesActions.cancel());
      }),
    );
  });

  updateMessageReadStatus$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.updateMessageReadStatus),
      switchMap((action) =>
        this.messageApiService.updateMessageReadStatus(action.messageId, action.isRead).pipe(
          map((response) => {
            return messagesActions.messageStatusUpdatedInEffect({
              messageId: action.messageId,
              isRead: response.is_read,
            });
          }),
          catchError((err) => {
            console.warn('Network error when updating message read status', err);
            return of(messagesActions.cancelMessages());
          }),
        ),
      ),
    );
  });

  openThreadByMessageId$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.openThreadByMessageId),
      tap((action) => {
        this.store.dispatch(messagesActions.setIsLoading({ isLoading: true }));
        // this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW }));
      }),
      switchMap((action) => {
        return this.messageApiService.getMessageById(action.messageId).pipe(
          catchError((err) => {
            console.warn('Error when getting message by id', err);
            return of(null);
          }),
        );
      }),
      map((response: IMessage) => {
        if (!response) {
          return messagesActions.cancelMessages();
        }

        return messagesActions.setThread({
          thread: { mainMessage: response, replies: response.thread },
        });
      }),
    );
  });

  loadProfilePictures(groupMessages: IGroupMessagesAndDrafts) {
    this.store
      .select(getProfilePicCache)
      .pipe(first())
      .subscribe((cache) => {
        const loadedImages = new Set<number>(
          Object.keys(cache).map((key) => Number.parseInt(key, 10)),
        );
        groupMessages.messages.forEach((message) => {
          if (!message?.user) {
            console.warn('Message has no user.');
            return;
          }
          if (loadedImages.has(message.user.profile_picture_id)) {
            return;
          }
          if (!message?.user?.profile_picture_id) {
            this.store.dispatch(
              addImageToCache({ imageSrc: DEFAULT_IMAGE, user_id: message.user?.id }),
            );
            return;
          }
          loadedImages.add(message.user.profile_picture_id);
          this.driveApi.getFileViewURL(message.user.profile_picture_id).then(
            (imageSrc) => {
              this.store.dispatch(addImageToCache({ imageSrc, user_id: message.user.id }));
            },
            (error) => {
              console.warn('load profile picture error', error);
              this.store.dispatch(
                addImageToCache({ imageSrc: DEFAULT_IMAGE, user_id: message.user.id }),
              );
            },
          );
        });
      });
  }

  private findDraft(state, groupId) {
    const groupDrafts = state.drafts.filter((draft) => draft?.group_id === groupId);
    let foundDraft;
    if (state.selectedView === MESSAGING_VIEWS.DISCUSSION_VIEW) {
      foundDraft = groupDrafts?.find((draft) => !draft.thread_id);
    }
    if (state.selectedView === MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW) {
      foundDraft = groupDrafts?.find((draft) => draft?.thread_id === state?.thread_id);
    }
    return foundDraft;
  }

  private getUpsertRequest(
    dataToSend: ICreateMessageData,
    foundDraft: boolean,
  ): Observable<IMessage | any> {
    if (!dataToSend.id) {
      delete dataToSend.id;
    }
    return this.messageApiService.createMessageObs(dataToSend);
    // lets leave this here for now, delete later if patch is not needed
    // return foundDraft
    //   ? this.messageApiService.patchMessageObs(dataToSend)
    //   : this.messageApiService.createMessageObs(dataToSend);
  }
}

export const isBodyEmpty = (body) => {
  const checkBody = body
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/\s/g, '')
    .replace(/&nbsp;/g, '');
  return !body || body === '' || checkBody === '';
};
