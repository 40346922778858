import { Pipe, PipeTransform } from '@angular/core';

/**
 *  Returns a shortened version of a string in "mac-like" format,
 *  replaces the center of the string with '...'
 *
 *  ex. averylongfilename -> avery...filename
 */

@Pipe({
  name: 'shortenFileName',
  standalone: true,
})
export class ShortenFileNamePipe implements PipeTransform {
  transform(str: string | null | undefined, charLimit = 30): string {
    if (!str) return '';
    if (charLimit < 12) {
      console.warn(
        'ShortenFileNamePipe does not support a character limit under 12. It will return the original string.',
      );
      return str;
    }
    if (str.length <= charLimit) {
      return str;
    }

    charLimit = charLimit - 3; // disconsider the '...', since charLimit should be the number of characters in output string

    const charEnd = Math.floor(charLimit / 3); // number of characters at the end
    const charStart = charLimit - charEnd; // default: 17

    return `${str.substring(0, charStart)}` + '...' + `${str.substring(str.length - charEnd)}`;
  }
}
