import {
  AfterViewInit,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NotificationsService } from '../../../../services/notifications.service';
import { FormsModule, NgForm, NgModel } from '@angular/forms';
import { CurrentUserService } from '../../../../services/current-user.service';
import { AppState } from '../../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  commitmentCombinedSelectors,
  commitmentsSelectors,
} from '../../../../store/commitments/commitments.selectors';
import { commitmentsActions } from '../../../../store/commitments/commitments.actions';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import {
  COMPANY_DROPDOWN_SELECT_TYPES,
  CompanyDropdownComponent,
  ICompanyDropdownSelectEvent,
} from '../company-dropdown/company-dropdown.component';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import { take, takeUntil } from 'rxjs/operators';
import { CommitmentSidebarComponent } from '../commitment-sidebar/commitment-sidebar.component';
import { SpendEntryComponent } from '../spend-entry/spend-entry.component';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../../../constants/interaction-bar.constants';
import { fadeInGeneral } from '../../../../../assets/styles/animations';
import {
  COMMITMENT_APPROVAL_STATUS,
  ISidebarCommitmentItem,
  ISidebarContract,
} from '../../../../store/commitments/commitments.types';
import { StorageService } from '../../../../services/storage.service';
import { COMMITMENT_STATUS } from '../../commitments-interaction-bar-view/commitments-interaction-bar-view.utilities';
import { CommitmentSidebarTitleComponent } from '../commitment-sidebar-title/commitment-sidebar-title.component';
import { CommitmentsSidebarHelpComponent } from '../commitments-sidebar-help/commitments-sidebar-help.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { AsyncPipe, CommonModule } from '@angular/common';
import { UploadCommitmentsSidebarComponent } from '../upload-commitments-sidebar/upload-commitments-sidebar.component';
import { InputTextModule } from 'primeng/inputtext';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { OverlayGeneralComponent } from '../../../overlays/overlay-general/overlay-general.component';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { DropdownModule } from 'primeng/dropdown';

import { CommitmentEntryTextComponent } from '../commitment-entry-text/commitment-entry-text.component';
import { MoneyPipe } from '../../../../pipes/framework/money-short.pipe';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FloatingTextareaComponent } from '../../../inputs/floating-textarea/floating-textarea.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import dayjs from 'dayjs';

@Component({
  selector: 'app-contract-sidebar',
  templateUrl: './contract-sidebar.component.html',
  styleUrls: [
    './contract-sidebar.component.scss',
    '../commitment-sidebar/commitment-sidebar.component.scss',
  ],
  animations: [fadeInGeneral],
  standalone: true,
  imports: [
    CommitmentSidebarTitleComponent,
    CommitmentsSidebarHelpComponent,
    NgScrollbar,
    AsyncPipe,
    FormsModule,
    UploadCommitmentsSidebarComponent,
    InputTextModule,
    CdkOverlayOrigin,
    CommonModule,
    CompanyDropdownComponent,
    OverlayGeneralComponent,
    InputCalendarComponent,
    DropdownModule,
    SpendEntryComponent,
    CommitmentEntryTextComponent,
    MoneyPipe,
    InputTextareaModule,
    FloatingInputComponent,
    DropdownComponent,
    FloatingTextareaComponent,
    SimpleButtonComponent,
  ],
})
export class ContractSidebarComponent
  extends CommitmentSidebarComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('firstPageForm') formFirstPage: NgForm;
  @ViewChildren('spendEntries') spendEntryComponents: QueryList<SpendEntryComponent>;
  @ViewChild('picker') picker;

  isCompanyInput = false;
  spendEntries$ = this.store.select(commitmentsSelectors.getContractSpendEntries);
  spendEntriesData: ISidebarCommitmentItem[] = [];
  contract$ = this.store.select(commitmentsSelectors.getSideBarContract);
  totalContractCost$ = this.store.select(commitmentsSelectors.totalContractCostDescription);
  canAddContractSpendEntries$ = this.store.select(
    commitmentCombinedSelectors.canAddContractSpendEntries,
  );

  isDropdownShown = {
    company_name: false,
    contract_id: false,
    approval_status: false,
    service_provider: false,
    paid_status: false,
  };
  model: Partial<ISidebarContract> = {
    service_provider: {
      name: '',
      temporary_service_provider_id: null,
      service_provider_user_id: null,
    },
  };

  commitmentType = COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT;

  protected readonly COMMITMENT_APPROVAL_STATUS = COMMITMENT_APPROVAL_STATUS;

  showHelp: boolean;
  firstSpendEntryDate: string | null = null;

  constructor(
    protected store: Store<AppState>,
    protected notif: NotificationsService,
    protected storage: StorageService,
    protected user: CurrentUserService,
    protected interactionBar: InteractionBarStateService,
  ) {
    super(store, notif, storage, user, interactionBar);
  }

  ngAfterViewInit() {
    this.spendEntries$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((entries: ISidebarCommitmentItem[]) => {
        this.spendEntriesData = DeepCopyService.deepCopy(entries);
        this.setFirstSpendEntryDate();
      });

    this.contract$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      if (data) {
        this.model = DeepCopyService.deepCopy(data);
        this.isEdit = !!data.id;
      }
    });
  }

  setFirstSpendEntryDate() {
    const entriesWithStartDate = this.spendEntriesData.filter((entry) => entry.start_date);
    if (!entriesWithStartDate.length) {
      return;
    }

    const firstEntry = dayjs.min(entriesWithStartDate.map((entry) => dayjs(entry.start_date)));
    this.firstSpendEntryDate = firstEntry?.format('YYYY-MM-DD') ?? null;
  }

  selectedCompany(event: ICompanyDropdownSelectEvent, serviceProviderModel: NgModel) {
    switch (event.type) {
      case COMPANY_DROPDOWN_SELECT_TYPES.CHANGE_DROPDOWN: {
        // if new temporary SP is added
        serviceProviderModel.reset();
        this.model.service_provider.service_provider_user_id = null; // we don't link to any SP
        this.model.service_provider.temporary_service_provider_id = null; // we don't link to any temporary SP
        this.isCompanyInput = true;
        break;
      }
      case COMPANY_DROPDOWN_SELECT_TYPES.SELECT: {
        // if user selected an already existing SP (either temporary or not)
        this.model.service_provider = {
          name: event.data.company_name,
        };
        if (event.isTemporaryContractor) {
          this.model.service_provider.temporary_service_provider_id = event.data.id;
        } else {
          this.model.service_provider.service_provider_user_id = event.data.id;
        }
        this.updateContract();
        break;
      }
      default: {
        console.warn('Action not handled', event);
        break;
      }
    }
    this.isDropdownShown.service_provider = false;
  }

  revertToDropDown(event: MouseEvent, serviceProviderUserId: NgModel) {
    this.isCompanyInput = false;
    serviceProviderUserId.reset();
    event.stopPropagation();
  }

  getApprovalStatuses(): COMMITMENT_STATUS[] {
    const statuses = [COMMITMENT_STATUS.REVIEW, COMMITMENT_STATUS.DRAFT];
    if (this.user.isManager || this.model?.approval_status === COMMITMENT_STATUS.APPROVED) {
      statuses.push(COMMITMENT_STATUS.APPROVED);
    }
    if (this.user.isManager || this.model?.approval_status === COMMITMENT_STATUS.PENDING) {
      statuses.push(COMMITMENT_STATUS.PENDING);
    }
    return statuses;
  }

  addSpendEntry() {
    this.canAddContractSpendEntries$.pipe(take(1)).subscribe((canAdd) => {
      if (canAdd) {
        this.store.dispatch(commitmentsActions.addContractSpendEntry());
      }
    });
  }

  updateContractDate(date: string) {
    this.store.dispatch(
      commitmentsActions.updateSelectedContract({
        contract: {
          ...this.model,
          added_at: date,
        },
      }),
    );
  }

  updateContract() {
    this.store.dispatch(
      commitmentsActions.updateSelectedContract({ contract: DeepCopyService.deepCopy(this.model) }),
    );
  }

  saveOrModify() {
    if (this.model.id) {
      this.notif.showLoading('Updating contract...');
      this.store.dispatch(commitmentsActions.modifyContract());
      return;
    }
    this.notif.showLoading('Saving contract...');
    this.store.dispatch(commitmentsActions.saveContract());
  }

  needHelpClicked(showHelp: boolean) {
    this.showHelp = showHelp;
  }
}
