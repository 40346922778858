import { concatLatestFrom } from '@ngrx/operators';
import { Injectable } from '@angular/core';
import { ActivitiesApiServiceService } from '../../services/activities-api-service.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { activitiesActions } from './activities.actions';
import { exhaustMap, forkJoin, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationsService } from '../../services/notifications.service';
import {
  Activity,
  ACTIVITY_VIEW,
  ActivityFilters,
  ISidebarActivity,
  NO_PROJECT_ID,
} from './activities.constants';
import { activitiesSelector } from './activities.selectors';
import { Store } from '@ngrx/store';
import { INTERACTION_BAR_STATES } from '../../framework/constants/interaction-bar.constants';
import { commitmentsActions } from '../commitments/commitments.actions';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { Router } from '@angular/router';
import { settingsActions } from '../settings/settings.actions';
import dayjs from 'dayjs';
import { CurrentUserService } from '../../services/current-user.service';
import { PROJECT_VIEWS } from '../../framework/constants/view-project.constants';

@Injectable()
export class ActivitiesEffects {
  mapActivityToSidebar = {
    contract: INTERACTION_BAR_STATES.SIDEBAR_ADD_CONTRACT,
    invoice: INTERACTION_BAR_STATES.SIDEBAR_INVOICE,
    change: INTERACTION_BAR_STATES.SIDEBAR_CHANGE_ORDER,
    misc: INTERACTION_BAR_STATES.SIDEBAR_MISC_COST,
  };
  constructor(
    private actions: Actions,
    private apiService: ActivitiesApiServiceService,
    private notif: NotificationsService,
    private store: Store,
    private interactionBarState: InteractionBarStateService,
    private router: Router,
    private currentUserService: CurrentUserService,
  ) {}

  loadDataByView$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.loadDataByView),
      concatLatestFrom(() => this.store.select(activitiesSelector.getSelectedView)),
      map(([action, selectedView]) => {
        if (!this.currentUserService.isLoggedIn()) {
          return activitiesActions.cancel();
        }
        if (selectedView === ACTIVITY_VIEW.ACTIVITY_LOG) {
          return activitiesActions.loadActivitiesLogFirstPage();
        }
        if (selectedView === ACTIVITY_VIEW.ACTIVITIES) {
          return activitiesActions.loadActivitiesKanbanFirstPage();
        }
        if (selectedView === ACTIVITY_VIEW.CALENDAR) {
          return activitiesActions.loadActivitiesCalendarFirstPage();
        }
        console.warn('Dev note: loadDataByView action not supported yet');
        return activitiesActions.cancel();
      }),
    );
  });

  loadAllActivitiesFirstPage = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.loadActivitiesKanbanFirstPage),
      delay(10),
      tap(() => {
        this.store.dispatch(
          activitiesActions.setLoading({
            overdueProgress: true,
            openInvoices: true,
            openContracts: true,
          }),
        );
      }),
      concatLatestFrom(() => this.store.select(activitiesSelector.getFilters)),
      switchMap(([action, filters]) => {
        return forkJoin([
          this.apiService.getActivitiesByCategory('overdueProgress', 1, filters).pipe(
            catchError((err) => {
              setTimeout(
                () => this.notif.showError(err?.error?.message ?? 'An error occurred'),
                1000,
              );
              return of(null);
            }),
          ),
          this.apiService.getActivitiesByCategory('openInvoices', 1, filters).pipe(
            catchError((err) => {
              setTimeout(
                () => this.notif.showError(err?.error?.message ?? 'An error occurred'),
                1000,
              );
              return of(null);
            }),
          ),
          this.apiService.getActivitiesByCategory('openContracts', 1, filters).pipe(
            catchError((err) => {
              setTimeout(
                () => this.notif.showError(err?.error?.message ?? 'An error occurred'),
                1000,
              );
              return of(null);
            }),
          ),
        ]);
      }),
      map(([overdueProgress, openInvoices, openContracts]) => {
        if (overdueProgress === null || openInvoices === null || openContracts === null) {
          this.notif.showError('Failed to load activities');
        }
        return activitiesActions.successfullyLoadedActivities({
          isFirstPage: true,
          overdueProgress: overdueProgress ?? null,
          openInvoices: openInvoices ?? null,
          openContracts: openContracts ?? null,
          nextPage: {
            overdueProgress: overdueProgress?.length === 0 ? null : 2,
            openInvoices: openInvoices?.length === 0 ? null : 2,
            openContracts: openContracts?.length === 0 ? null : 2,
          },
        });
      }),
    );
  });

  loadActivitiesNextPage$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.loadActivitiesKanbanNextPage),
      tap((action) => {
        this.store.dispatch(activitiesActions.setLoading({ [action.activityType]: true }));
      }),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(activitiesSelector.getNextPage(action.activityType)),
            this.store.select(activitiesSelector.getFilters),
          ),
        ),
      ),
      switchMap(([action, nextPage, filters]) => {
        if (!nextPage) {
          return of(activitiesActions.setLoading({ [action.activityType]: false }));
        }
        return this.apiService.getActivitiesByCategory(action.activityType, nextPage, filters).pipe(
          map((activities) => {
            return activitiesActions.successfullyLoadedActivities({
              isFirstPage: false,
              [action.activityType]: activities,
              nextPage: {
                [action.activityType]: nextPage + 1,
              },
            });
          }),
        );
      }),
    );
  });

  loadActivityLogNextPage$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.loadActivitiesLogNextPage),
      concatLatestFrom(() => this.store.select(activitiesSelector.activitiesFeatureSelector)),
      tap(() => {
        this.store.dispatch(activitiesActions.setIsLoadingGeneral({ isLoading: true }));
      }),
      exhaustMap(([action, state]) => {
        return this.apiService
          .getAllActivitiesByPageAndFilters(state.filters, state.logPage + 1)
          .pipe(
            map((activityLog) => {
              return activitiesActions.successfullyLoadedActivityLog({
                logPage: state.logPage,
                logs: activityLog,
                resetLogs: false,
              });
            }),
            catchError(() => {
              this.notif.showError('Failed to load activity log');
              return of(activitiesActions.cancel());
            }),
          );
      }),
    );
  });

  loadActivityLogFirstPage$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.loadActivitiesLogFirstPage),
      delay(10),
      concatLatestFrom(() => this.store.select(activitiesSelector.activitiesFeatureSelector)),
      tap(() => {
        this.store.dispatch(activitiesActions.setIsLoadingGeneral({ isLoading: true }));
      }),
      exhaustMap(([action, state]) => {
        return this.apiService.getAllActivitiesByPageAndFilters(state.filters).pipe(
          map((activityLog) => {
            return activitiesActions.successfullyLoadedActivityLog({
              logPage: state.logPage,
              logs: activityLog,
              resetLogs: true,
            });
          }),
          catchError(() => {
            this.notif.showError('Failed to load activity log');
            return of(activitiesActions.cancel());
          }),
        );
      }),
    );
  });

  loadActivitiesCalendarFirstPage$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.loadActivitiesCalendarFirstPage),
      concatLatestFrom(() => this.store.select(activitiesSelector.getFilters)),
      tap((_) => {
        this.store.dispatch(activitiesActions.setIsLoadingGeneral({ isLoading: true }));
      }),
      switchMap(([action, filters]) => {
        return this.apiService.getAllActivitiesByPageAndFilters(filters, 1, 500).pipe(
          map((activities) => {
            return activitiesActions.successfullyLoadedActivitiesCalendar({
              activities,
              isFirstLoad: true,
              startDateGTE: filters.startDateGTE,
              startDateLT: filters.startDateLT,
            });
          }),
          tap(() => {
            // there might be a better solution for this setTimeout,
            // but it's needed to wait for the store to update with the activities start/end date values
            setTimeout(() => {
              // load 2 more months in background
              this.store.dispatch(
                activitiesActions.loadActivitiesCalendarMonthInBackground({
                  direction: -1,
                  months: 3,
                }),
              );
              this.store.dispatch(
                activitiesActions.loadActivitiesCalendarMonthInBackground({
                  direction: 1,
                  months: 3,
                }),
              );
            }, 200);
          }),
          catchError(() => {
            this.notif.showError('Failed to load activities calendar');
            return of(activitiesActions.cancel());
          }),
        );
      }),
    );
  });

  loadActivitiesCalendarNexMonth$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.loadActivitiesCalendarMonthInBackground),
      withLatestFrom(
        this.store.select(activitiesSelector.getFilters),
        this.store.select(activitiesSelector.getCalendarDateWindow),
      ),
      tap(([action]) => {
        console.log('[effect] loading next month', action);
        this.store.dispatch(activitiesActions.setIsLoadingGeneral({ isLoading: true }));
      }),
      mergeMap(([action, storedFilters, dateWindow]) => {
        const oldStartDate = dateWindow.startDateGTE;
        const oldEndDate = dateWindow.startDateLT;
        if (!oldStartDate || !oldEndDate) {
          console.warn('no dateWindow set in store, could not load activites');
          this.notif.showError('Failed to load some activities for calendar');
          return of(activitiesActions.cancel());
        }
        let filters: ActivityFilters;
        if (action.direction < 0) {
          filters = {
            ...storedFilters,
            startDateGTE: dayjs(oldStartDate).subtract(action.months, 'month').format('YYYY-MM-DD'),
            startDateLT: oldStartDate,
          };
        } else if (action.direction > 0) {
          filters = {
            ...storedFilters,
            startDateGTE: oldEndDate,
            startDateLT: dayjs(oldEndDate).add(action.months, 'month').format('YYYY-MM-DD'),
          };
        }

        this.store.dispatch(
          activitiesActions.refreshCalendarDateWindow({
            direction: action.direction,
            startDateLT: filters.startDateLT,
            startDateGTE: filters.startDateGTE,
          }),
        );

        return this.apiService.getAllActivitiesByPageAndFilters(filters, 1, 500).pipe(
          map((activities) => {
            return activitiesActions.successfullyLoadedActivitiesCalendar({
              activities,
              isFirstLoad: false,
              direction: action.direction,
            });
          }),
          catchError((error) => {
            console.warn('Failed to load activities', error);
            this.notif.showError('Failed to load somme activities for calendar');
            return of(activitiesActions.cancel());
          }),
        );
      }),
    );
  });

  addDailyActivity$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.addDailyActivity),
      exhaustMap((action) => {
        return this.apiService.addDailyActivity(action.activity).pipe(
          map((_) => {
            return activitiesActions.successfullyAddedDailyActivity();
          }),
          catchError((err) => {
            this.notif.showError(err?.error?.message ?? 'Failed to add activity');
            return of(activitiesActions.cancel());
          }),
        );
      }),
    );
  });

  deleteDailyActivity$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.deleteDailyActivity),
      tap(() => {
        this.store.dispatch(activitiesActions.setIsLoadingGeneral({ isLoading: true }));
      }),
      exhaustMap((action) => {
        return this.apiService.deleteDailyActivity(action.id).pipe(
          map((data) => {
            this.notif.showSuccess(data?.message ?? 'Daily activity deleted successfully!');
            return activitiesActions.loadDataByView();
          }),
          catchError(() => of(activitiesActions.cancel())),
        );
      }),
    );
  });

  updateDailyActivity$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.updateDailyActivity),
      exhaustMap((action) => {
        return this.apiService.updateActivity(action.activity, action.activity.id).pipe(
          map((_) => {
            return activitiesActions.successfullyUpdatedDailyActivity();
          }),
          catchError((err) => {
            this.notif.showError(err?.error?.message ?? 'Failed to update activity');
            return of(activitiesActions.cancel());
          }),
        );
      }),
    );
  });

  loadDailyActivitySidebar$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.loadActivityDailyLog),
      delay(10),
      map((action) => {
        return activitiesActions.selectDailyActivitySidebar({ activity: action.activity });
      }),
    );
  });

  filtersChanged$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.filtersChanged),
      // debounceTime(500),
      tap((action) => {
        this.store.dispatch(activitiesActions.saveFilters(action));
      }),
      tap(() => {
        this.store.dispatch(activitiesActions.loadDataByView());
      }),
      map(() => activitiesActions.cancel()),
    );
  });

  assignTeammate$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.assignTeammate),
      switchMap((action) => {
        return this.apiService.assignTeammateToActivity(action.activityId, action.teammateId).pipe(
          map((activity) => {
            this.notif.showSuccess(`Teammate ${action.teammateId ? 'assigned' : 'removed'}`);
            return activitiesActions.successfullyAssignedTeammate({ activity });
          }),
          catchError((err) => {
            this.notif.showError(err.error.message ?? 'Failed to assign teammate');
            return of(activitiesActions.cancel());
          }),
        );
      }),
    );
  });

  activitySelected$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.activitySelected),
      tap((action) => {
        const activity = action.activity;

        if (activity.type === 'daily') {
          const sidebarActivity = this.getSidebarActivity(activity);

          if (activity.completed !== undefined) {
            sidebarActivity.completed = activity.completed;
          }

          this.interactionBarState.announceState.next({
            action: INTERACTION_BAR_STATES.ACTIVITY_SIDEBAR,
          });
          this.store.dispatch(
            activitiesActions.loadActivityDailyLog({ activity: sidebarActivity }),
          );
          return;
        }

        if (['contract', 'invoice', 'change', 'misc'].includes(activity.type)) {
          // maybe a new commitment action would fit better here instead of multiple separate actions
          // TODO: not maybe, definitely
          // these actions are dispatched inside view project / refresh() function, so this is code duplication

          if (!activity?.project?.id) {
            this.notif.showError('Project is missing');
            return;
          }

          this.store.dispatch(
            settingsActions.loadBudgetTagTemplatesFromCommitments({
              projectId: activity?.project?.id,
            }),
          );
          this.store.dispatch(
            commitmentsActions.loadAllCommitments({ projectId: activity.project.id }),
          );
          this.store.dispatch(
            commitmentsActions.loadCommitmentItems({ projectId: activity.project.id }),
          );
          this.store.dispatch(
            commitmentsActions.setCommitmentProjectData({
              projectData: {
                id: activity.project.id,
                start_date: activity.project.start_date,
              },
            }),
          );
          this.store.dispatch(
            commitmentsActions.loadSidebarCommitment({
              id: activity.commitment.id,
              projectId: activity.project.id,
            }),
          );

          this.interactionBarState.openCommitments(this.mapActivityToSidebar[activity.type]);
          return;
        }

        if (['tracking', 'checklist'].includes(activity.type)) {
          // todo add checklist to []
          if (!activity.project?.id) {
            this.notif.showError('Activity does not have a project');
            return;
          }
          console.warn('Dev note: this type of redirect is not finished, see docs for more info');
          // TODO: after navigate, open the right tracking item / checklist item
          this.router.navigate(['webapp', 'projects', activity.project.id], {
            queryParams: {
              view: PROJECT_VIEWS.PROGRESS_TRACKING,
              trackingId: activity.progress_item.id,
            },
          });
          return;
        }

        this.notif.showError('Action not supported yet');
      }),
      map(() => activitiesActions.cancel()),
    );
  });

  activitySelectedFromNotification$ = createEffect(() => {
    return this.actions.pipe(
      ofType(activitiesActions.activitySelectedFromNotification),
      switchMap((action) => {
        return this.apiService.getActivityById(action.activityId).pipe(
          map((activity) => {
            return activitiesActions.activitySelected({ activity });
          }),
          catchError(() => {
            this.notif.showError('Failed to load activity');
            return of(activitiesActions.cancel());
          }),
        );
      }),
    );
  });

  getSidebarActivity(activity: Activity): ISidebarActivity {
    return {
      id: activity.id,
      title: activity.title,
      start_date: activity.start_date,
      end_date: activity.end_date,
      project_id: activity?.project?.id ?? NO_PROJECT_ID,
      assignee_id: activity?.assignee?.id ?? null,
      description: activity?.description ?? '',
    };
  }
}
