import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

interface Partner {
  imageUrl: string;
  imageHeight: number; // in rem
  name: string;
}

@Component({
  selector: 'app-partners-section',
  templateUrl: './partners-section.component.html',
  styleUrls: ['./partners-section.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PartnersSectionComponent implements OnInit {
  public duration = 30; // duration of a full carousel rotation in seconds
  public spread = 100; // controls how far apart the carousel items are from each other
  public multiplicationCount = 2; // 2 means the number of elements double, 3 means that they triple, etc.

  // returns a multiplied array of partners to simulate an infinite scroll effect in css
  public get partnersMultiplied() {
    let multipliedArray = [];
    for (let i = 0; i < this.multiplicationCount; i++) {
      multipliedArray = multipliedArray.concat(...this.partners);
    }
    return multipliedArray;
  }

  private partners: Partner[] = [
    {
      imageUrl: 'assets/images/landing-partners/sutter.webp',
      name: 'Sutter Health',
      imageHeight: 2.5,
    },
    {
      imageUrl: 'assets/images/landing-partners/montage.webp',
      name: 'Montage Health',
      imageHeight: 2,
    },
    {
      imageUrl: 'assets/images/landing-partners/alameda.webp',
      name: 'Alameda Health System Foundation',
      imageHeight: 2,
    },
    {
      imageUrl: 'assets/images/landing-partners/mason.webp',
      name: 'Mason Builders',
      imageHeight: 2,
    },
    {
      imageUrl: 'assets/images/landing-partners/porter.webp',
      name: 'Porter Consulting',
      imageHeight: 2.5,
    },
    {
      imageUrl: 'assets/images/landing-partners/masonic.webp',
      name: 'Masonic Homes of California',
      imageHeight: 3,
    },
  ];

  ngOnInit(): void {
    if (this.multiplicationCount * this.spread < 200) {
      throw new Error(
        'The duplication count and scale should be set to a value that will make the partners section at least 200% wide',
      );
    }

    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth <= parseFloat('640px')) {
      // TODO: use tailwindConfig.theme.screens['sm'] variable here when it's available
      this.spread = this.partners.length * 60;
    } else if (window.innerWidth <= parseFloat('1024px')) {
      // TODO: use tailwindConfig.theme.screens['lg'] variable here when it's available
      this.spread = 200;
    } else if (window.innerWidth <= parseFloat('2560px')) {
      // TODO: use tailwindConfig.theme.screens['lg'] variable here when it's available
      this.spread = 150;
    } else {
      this.spread = 100;
    }
  }
}
