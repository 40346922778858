import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { OverlayGeneralComponent } from '../overlay-general/overlay-general.component';
import { OPTIONS } from '../../constants/options-list.constants';
import { BehaviorSubject } from 'rxjs';
import { oldOptionOverlayPositions } from '../option-list.constants';
import { DefaultOptionsListComponent } from '../../default-options-list/default-options-list.component';
import { OptionsListStyledComponent } from '../options-list-styled/options-list-styled.component';
import { IStyledOption } from '../../constants/documents.constants';

@Component({
  selector: 'app-styled-options-list',
  standalone: true,
  imports: [
    CommonModule,
    CdkOverlayOrigin,
    DefaultOptionsListComponent,
    OverlayGeneralComponent,
    OptionsListStyledComponent,
  ],
  templateUrl: './styled-options-list.component.html',
  styleUrls: ['./styled-options-list.component.scss'],
})
export class StyledOptionsListComponent implements OnDestroy {
  @ContentChild('projectedContent') projectedContentTemplate: TemplateRef<any>;
  @ViewChild('optionsListGeneralComponent')
  optionsListGeneralComponent: OverlayGeneralComponent;
  @Input() listItems: Array<IStyledOption> = [];
  @Input() disabledActionList: Array<OPTIONS> = [];
  @Input() optionOverlayPositions = oldOptionOverlayPositions;
  @Input() hideContent = false;
  @Output() selectedOption = new EventEmitter();
  @Output() showOverlay$ = new BehaviorSubject<boolean>(false);

  registerSelectedOption(value: string) {
    this.selectedOption.emit(value);
    this.optionsListGeneralComponent.toggleShow();
  }

  ngOnDestroy() {
    this.showOverlay$.complete();
  }

  protected readonly OPTIONS = OPTIONS;
}
