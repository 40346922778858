import { Directive, ElementRef, HostListener } from '@angular/core';

// TODO: fix bug when dragging all the way to the sides

@Directive({
  selector: '[appDragScroll]',
  standalone: true,
})
export class DragScrollDirective {
  private isDown = false;
  private startX = 0;
  private scrollLeft = 0;

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.cursor = 'grab';
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.isDown = true;
    this.startX = event.clientX;
    this.scrollLeft = this.el.nativeElement.scrollLeft;
    this.el.nativeElement.style.cursor = 'grabbing';
  }

  @HostListener('mouseleave')
  @HostListener('mouseup')
  onMouseUp() {
    this.isDown = false;
    this.el.nativeElement.style.cursor = 'grab';
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDown) return;

    event.preventDefault();
    const x = event.clientX;
    const moveDistance = x - this.startX;

    this.el.nativeElement.scrollLeft = this.scrollLeft - moveDistance;
  }
}
