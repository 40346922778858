<div [formGroup]="messageForm" class="messageForm create h-full">
  <div #list class="form hide-underline">
    <mat-form-field
      #userTableTrigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      (click)="optionsListGeneralComponent.toggleShow()"
      class="borderless w-full"
      subscriptSizing="dynamic"
    >
      <app-overlay-general
        #optionsListGeneralComponent
        (showChange)="!!$event ? loadUsers() : ''"
        [trigger]="userTableTrigger"
        [withAnimation]="false"
      >
        <app-messaging-users-table
          [listItems]="itemOptions"
          [usersRelated]="usersData.usersRelated"
          [discussionMembers]="usersData.discussionMembers"
          (selectedOption)="registerOption($event); optionsListGeneralComponent.toggleShow()"
        ></app-messaging-users-table>
      </app-overlay-general>

      <span prefix class="icon-at-sign text-shade_2 font-normal font-sm"></span>
      <ng-container *ngFor="let user of usersData.discussionMembers">
        <span class="pr-5 text-shade_2 font-normal font-sm"> {{ user.name }} </span>
      </ng-container>
      <input
        tabindex="-1"
        class="participants"
        autocomplete="off"
        (click)="optionsListGeneralComponent.toggleShow(); $event.stopPropagation()"
        (ngModelChange)="loadUsers()"
        formControlName="participants"
        matInput
      />
      <mat-error *ngIf="messageForm.get('participants').invalid">
        {{ getErrorMessage(messageForm.get('participants')) }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="border-separator"></div>

  <div class="form hide-underline subject-input">
    <mat-form-field class="borderless w-full" subscriptSizing="dynamic">
      <input
        #subjectInput
        name="subject"
        formControlName="subject"
        placeholder="Group Name / Subject"
        matInput
        (ngModelChange)="updateMessagingGroup($event, 'name')"
      />
      <mat-error *ngIf="messageForm.get('subject').invalid">
        {{ getErrorMessage(messageForm.get('subject')) }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="border-separator"></div>

  <app-rich-text-editor
    [editorValue]="editorValue"
    (editorValueChange)="onEditorValueChange($event)"
    [userMentions]="usersData.discussionMembers"
    [projectMentions]="projectMentions$ | async"
    [allowFileUpload]="true"
    [filesToUpload]="filesToUpload$ | async"
    (filesToUploadChange)="registerFileUpload($event)"
    (removedFileToUpload)="removeFile($event)"
    enterKeyPressBehaviour="custom"
    (enterKeyPress)="footerService.emitSave()"
    class="rich-text-editor"
  ></app-rich-text-editor>
</div>
