import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { CustomOverlayService } from '../../../services/custom-overlay.service';
import { IMessagingMember } from '../../../store/messages/messages.interfaces';

export type IDefaultOverlayListItem = {
  id: number;
  name: string;
};

@Component({
  selector: 'app-mention-overlay',
  templateUrl: './mention-overlay.component.html',
  styleUrls: ['./mention-overlay.component.scss'],
  standalone: true,
  imports: [CommonModule, NgScrollbar],
})
export class MentionOverlayComponent {
  @Input() isLoading = false;

  users: IMessagingMember[];

  constructor(public overlayService: CustomOverlayService) {}

  selectItem(item: IDefaultOverlayListItem) {
    this.overlayService.closeOverlay();
    this.overlayService.emitOutputData(item);
  }
}
