import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PricingCardComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() price: number;
  @Input() period = '';
  @Input() buttonText = '';
  @Input() isDisabled = false;
  @Input() isLoading = false;
  @Input() showStartingAt = true;

  @Output() buttonClick = new EventEmitter<void>();
}
