<app-hidden-upload-input
  #hiddenFileInput
  (filesChange)="registerFileAdded($event)"
  (filesChangeRaw)="registerFileAddedRaw($event)"
  [allowMultipleFiles]="allowMultipleFiles"
></app-hidden-upload-input>

@if (useNgContent) {
  <ng-content></ng-content>
} @else {
  <div
    aria-hidden="true"
    (click)="hiddenFileInput.openFileUploadWindow()"
    [ngClass]="{ 'disabled color-cancel': disabled }"
    matTooltip="Upload File"
    class="flex align-baseline justify-center cursor-pointer text-color_secondary max-h-[30px]"
  >
    <span class="icon-upload"></span>
  </div>
}
