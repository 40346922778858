import { Component, Input, OnInit } from '@angular/core';
import {
  ITeamMember,
  ITeamProject,
  ITeamProperty,
  TEAM_PROP_PROJ_SIDEBAR_PAGES,
} from '../../../store/team-management/team-management.interfaces';
import { ArrowButtonComponent } from '../../buttons/arrow-button/arrow-button.component';
import { NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { SearchInputComponent } from '../../search-input/search-input.component';
import { TeamManagementService } from '../../../services/team-management.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { Router } from '@angular/router';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { PageLoadingComponent } from '../../page-loading/page-loading.component';

@Component({
  selector: 'app-shared-team-prop-proj-sidebar',
  standalone: true,
  imports: [ArrowButtonComponent, SearchInputComponent, NgScrollbar, PageLoadingComponent, NgIf],
  templateUrl: './shared-team-prop-proj-sidebar.component.html',
  styleUrl: './shared-team-prop-proj-sidebar.component.scss',
})
export class SharedTeamPropProjSidebarComponent implements OnInit {
  isLoading = true;
  @Input() set data(member: ITeamMember) {
    if (!member?.id) {
      return;
    }
    this.teamService.getTeamMember(member.id).subscribe((member) => {
      this.properties = member.properties;
      this.allProjects = member.projects;
      this.isLoading = false;
    });
  }
  allProjects: ITeamProject[] = [];

  protected readonly TEAM_PROP_PROJ_SIDEBAR_PAGES = TEAM_PROP_PROJ_SIDEBAR_PAGES;
  currentPage = TEAM_PROP_PROJ_SIDEBAR_PAGES.PROPERTIES;

  propertiesFiltered: ITeamProperty[];
  private _properties: ITeamProperty[];
  set properties(data: ITeamProperty[]) {
    this._properties = data;
    this.propertiesFiltered = data;
  }
  get properties(): ITeamProperty[] {
    return this._properties;
  }

  private _selectedProperty: ITeamProperty;
  set selectedProperty(property: ITeamProperty) {
    this._selectedProperty = property;
    this.projects = this.allProjects.filter((project) => project.property_id === property.id);
  }
  get selectedProperty(): ITeamProperty {
    return this._selectedProperty;
  }

  projectsFiltered: ITeamProject[];
  private _projects: ITeamProject[];
  propertyHovered = {};
  set projects(data: ITeamProject[]) {
    this._projects = data;
    this.projectsFiltered = data;
  }
  get projects(): ITeamProject[] {
    return this._projects;
  }

  constructor(
    private store: Store,
    private teamService: TeamManagementService,
    private router: Router,
    private interactionBarStateService: InteractionBarStateService,
  ) {}

  ngOnInit() {}

  filterData(textInput: string) {
    if (this.currentPage === TEAM_PROP_PROJ_SIDEBAR_PAGES.PROPERTIES) {
      this.propertiesFiltered = this.properties.filter((property) => {
        return property.name.toLowerCase().includes(textInput.toLowerCase());
      });
    }
    if (this.currentPage === TEAM_PROP_PROJ_SIDEBAR_PAGES.PROJECTS) {
      this.projectsFiltered = this.projects.filter((project) => {
        return project.title.toLowerCase().includes(textInput.toLowerCase());
      });
    }
  }

  setSelectedProperty(property: ITeamProperty) {
    this.selectedProperty = property;
    this.currentPage = TEAM_PROP_PROJ_SIDEBAR_PAGES.PROJECTS;
  }

  setSelectedProject(project: ITeamProject) {
    this.router.navigate(['webapp', 'projects', project.id]);
    this.interactionBarStateService.close();
    // todo: save
  }
}
