<input
  class="w-full h-full"
  name="input"
  #input="ngModel"
  #inputElement
  [(ngModel)]="model"
  (ngModelChange)="onModelChange($event)"
  [pattern]="pattern"
  [placeholder]="placeholder"
  [readOnly]="readonly"
  [required]="required"
  [type]="type"
  (keydown)="keyDown.emit($event)"
/>

<ng-content select="[icon]"></ng-content>
