import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PolicyComponent } from 'src/app/framework/dialogs/policy/policy.component';
import { TermsOfServicesComponent } from 'src/app/framework/dialogs/terms-of-services/terms-of-services.component';

@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class LandingFooterComponent {
  dialogConfig = {
    panelClass: 'tos-dialog-panel',
    backdropClass: 'tosBackdrop',
  };

  constructor(private dialog: MatDialog) {}

  openTermsOfService() {
    this.dialog.open(TermsOfServicesComponent, this.dialogConfig);
  }

  openPrivacyPolicy() {
    this.dialog.open(PolicyComponent, this.dialogConfig);
  }
}
