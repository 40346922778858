import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Intercom } from '@supy-io/ngx-intercom';
import { PricingCardComponent } from './pricing-card/pricing-card.component';
import { ISubscriptionPlan } from 'src/app/framework/constants/subscription.constants';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-pricing-section',
  templateUrl: './pricing-section.component.html',
  styleUrls: ['./pricing-section.component.scss'],
  standalone: true,
  imports: [CommonModule, PricingCardComponent],
})
export class PricingSectionComponent implements OnInit {
  constructor(
    private intercom: Intercom,
    public router: Router,
    private subscriptionService: SubscriptionService,
  ) {}

  basicPlan: ISubscriptionPlan;
  premiumPlan: ISubscriptionPlan;
  arePlansLoading = false;

  ngOnInit() {
    this.loadPlans();
  }

  loadPlans() {
    this.arePlansLoading = true;
    this.subscriptionService.getAllPlans().subscribe({
      next: (plans) => {
        this.basicPlan = plans.find((plan) => plan.name.toLowerCase().includes('basic'));
        this.premiumPlan = plans.find((plan) => plan.name.toLowerCase().includes('premium'));
      },
      complete: () => {
        this.arePlansLoading = false;
      },
    });
  }

  openIntercom() {
    this.intercom.show();
  }
}
