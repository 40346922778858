import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CUSTOM_OVERLAY_VIEWS, CustomOverlayService } from '../../services/custom-overlay.service';
import { ImgPreviewComponent } from '../img-preview/img-preview.component';
import { OptionsListStyledComponent } from '../overlays/options-list-styled/options-list-styled.component';
import { MentionOverlayComponent } from './mention-overlay/mention-overlay.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-overlay',
  templateUrl: './custom-overlay.component.html',
  styleUrls: ['./custom-overlay.component.scss'],
  standalone: true,
  imports: [NgIf, MentionOverlayComponent, OptionsListStyledComponent, ImgPreviewComponent],
})
export class CustomOverlayComponent implements OnInit, OnDestroy {
  data: any;
  selectedView: CUSTOM_OVERLAY_VIEWS;
  VIEWS = CUSTOM_OVERLAY_VIEWS;

  isDestroyed$ = new Subject<boolean>();

  constructor(private customOverlayService: CustomOverlayService) {}

  ngOnInit(): void {
    this.customOverlayService.data$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      this.data = data;
    });
    this.customOverlayService.selectedView$.pipe(takeUntil(this.isDestroyed$)).subscribe((view) => {
      this.selectedView = view;
    });
  }

  registerSelectedOption($event: any) {
    this.customOverlayService.closeOverlay();
    this.customOverlayService.emitOutputData($event);
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
