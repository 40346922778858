import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgForm,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';

// NOTE: this component is copied from floating-input component and partially repurposed
// WARNING: This component is not fully tested and should be used with caution
@Component({
  selector: 'app-placeholder-input',
  templateUrl: './placeholder-input.component.html',
  styleUrls: ['./placeholder-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, InputTextModule, ReactiveFormsModule],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PlaceholderInputComponent),
    },
  ],
})
export class PlaceholderInputComponent implements OnDestroy {
  @ViewChild('input') input: NgModel;
  @ViewChild('inputElement') inputElement: ElementRef;

  @Input() model = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() type: 'tel' | 'text' | 'password' | 'number' | 'email' = 'text';
  @Input() placeholder = '';
  @Input() maxlength: string | number = null;
  @Input() minlength: string | number = null;
  @Input() autoComplete = true;
  @Input() verticalSpacing = true;
  /**
   * validate input against a given pattern
   */
  @Input() pattern = null;

  @Output() modelChange = new EventEmitter<string>();

  get control(): AbstractControl {
    if (this.ngControl) {
      return this.ngControl.control;
    }
    return this.input?.control;
  }
  @Output() keyDown = new EventEmitter<KeyboardEvent>();
  isDestroyed$ = new Subject();
  ngControl: NgControl;

  onModelChange(value: string) {
    this.modelChange.emit(value);
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
