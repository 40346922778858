<app-splash-screen
  *ngIf="!(dataTable$ | async)?.length && !(isLoading$ | async) && !(isAnyFilterActive$ | async)"
  title="Currently you’re not tracking progress for any items"
  class="flex-1"
>
  <div
    (click)="openTrackItemSidebar()"
    class="bg-color_secondary text-white rounded px-8 py-4 mt-5 mb-4 cursor-pointer"
  >
    Add Items to Track
  </div>
</app-splash-screen>

<app-dynamic-projected-table
  [ngClass]="{ hidden: !(dataTable$ | async)?.length && !(isLoading$ | async) && !(isAnyFilterActive$ | async) }"
  [emptyTableMessage]="'There is no content that matches your filter option.'"
  [isLoading]="isLoading$ | async"
  [isEmpty]="!(dataTable$ | async)?.length"
  [wasDataLoadedOnce]="wasDataLoadedOnce"
  #dynamicProjectedTable
>
  <div tableHeader class="grid-table">
    <div class="grid-row header-row bg-shade_6">
      <app-checkbox-dropdown
        class="item"
        (selectedOptionsChange)="filterItemsByProgressType($event)"
      ></app-checkbox-dropdown>
      <div class="item"></div>
      <div class="item font-semibold text-sm text-color_primary">% Progress</div>
      <div class="item font-semibold text-sm text-color_primary">Due Date</div>
      <div class="flex items-center justify-end pr-2.5 col-span-3">
        <app-add-button (click)="openTrackItemSidebar()"> Add Item to Track </app-add-button>
      </div>
    </div>
  </div>

  <div projectedTable class="grid-table">
    <app-expansion-panel
      [expanded]="expandedItems.get(progressItem.id)"
      (expandedChange)="expandedItems.set(progressItem.id, $event)"
      *ngFor="let progressItem of dataTable$ | async; trackBy: trackById"
      [rowStyle]="tableGridStyle"
    >
      <div header-row #tableRow class="grid-table">
        <div class="grid-row header-row">
          <div class="item title">
            <app-faded-text>
              {{ progressItem.title }}
            </app-faded-text>
            <app-un-styled-options-list
              class="flex items-center justify-center"
              (click)="$event.stopPropagation()"
              [listItems]="itemOptions"
              (selectedOption)="registerItemOption($event, progressItem)"
              #unStyledOptionsList
            >
              <ng-template #projectedContent>
                <span
                  (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow()"
                  class="icon-options-inverted cursor-pointer"
                ></span>
              </ng-template>
            </app-un-styled-options-list>
          </div>

          <app-activity-card-assign-button
            size="small"
            [activity]="itemActivity(progressItem)"
          ></app-activity-card-assign-button>

          <div class="item flex flex-col gap-1 progress">
            <app-progress-bar-projectable
              [percent]="progressItem.work_percentage"
              [allowBgColorChange]="false"
              percentWidth="big"
              fillClass="bg-shade_1"
              text="WORK"
            >
            </app-progress-bar-projectable>

            <app-progress-bar-projectable
              [percent]="progressItem.due_percentage"
              [isNegative]="progressItem.due_days < 0 && !progressItem?.checklist?.completed"
              [allowBgColorChange]="!!progressItem?.checklist?.completed"
              percentWidth="big"
              fillClass="bg-color_accent_shade_2"
              text="DUE"
            >
              <app-days-count size="big" [dayCount]="progressItem.due_days" after-content></app-days-count>
            </app-progress-bar-projectable>
          </div>
          <div class="item text-xs font-normal text-color_primary">{{ progressItem.end_date }}</div>
          <div class="item pr-0">
            <span
              *ngIf="progressItem?.is_due"
              [hideDelay]="100"
              [pTooltip]="tooltipContent"
              class="icon-alert-exclamation text-color_red_light flex justify-center items-center"
              tooltipPosition="right"
            ></span>
            <ng-template #tooltipContent>
              @for (item of progressItem.checklist.items; track item.id) {
                @if (item.due_days < 0) {
                  <div class="flex flex-column gap-5 w-max mb-2 last:mb-0">
                    <div class="flex items-center text-white font-semibold text-xs capitalize text-size_xs">
                      {{ item.name }}
                    </div>
                    <div class="flex items-center text-color_red_light uppercase font-semibold text-size_xs">
                      {{ Math.abs(item.due_days) }} Days overdue
                    </div>
                  </div>
                }
              }
            </ng-template>
          </div>

          <div class="separator"></div>

          <div class="item pl-5">
            {{ progressItem?.total_files }}
            @if (progressItem?.total_files === 1) {
              file
            } @else {
              files
            }
          </div>
        </div>
      </div>

      <div content-row class="grid-table">
        @for (item of progressItem?.checklist?.items; let checklistItemIndex = $index; track item.id) {
          <div class="parent-row">
            <div class="grid-row content-row pt-2">
              <div class="item subtitle">
                <app-faded-text>
                  <div class="hover:bg-shade_7">
                    {{ item.name }}
                  </div>
                </app-faded-text>
                <div
                  class="flex items-center justify-center text-shade_4"
                  (click)="toggleItemCompletion(progressItem, checklistItemIndex)"
                  [ngClass]="{
                    '!text-color_accept': !!item?.completed && !currentlyUpdating.has(item.id),
                    'icon-box-checked cursor-pointer': !currentlyUpdating.has(item.id),
                    'icon-refresh animate-spin': currentlyUpdating.has(item.id),
                    'opacity-50 cursor-not-allowed': disableUpdates
                  }"
                ></div>
              </div>

              <app-activity-card-assign-button
                size="small"
                [activity]="checklistItemActivity(progressItem, item)"
              ></app-activity-card-assign-button>

              <div class="item progress">
                <app-progress-bar-projectable
                  [percent]="item.due_percentage"
                  [isNegative]="item.due_days < 0 && !item?.completed"
                  [allowBgColorChange]="item?.completed"
                  percentWidth="big"
                  fillClass="bg-color_accent_shade_2"
                  text="DUE"
                >
                  <app-days-count size="big" [dayCount]="item.due_days" after-content></app-days-count>
                </app-progress-bar-projectable>
              </div>
              <div class="item text-xs font-normal text-color_primary">{{ item.end_date }}</div>

              <div
                (click)="uploadWindowComponent.openWindow()"
                class="item flex justify-center items-center cursor-pointer h-full box-border pr-0"
              >
                <app-upload-window
                  class="flex items-center"
                  #uploadWindowComponent
                  (chosenFiles)="registerFileUpload($event, item.id)"
                  [allowMultipleFiles]="true"
                  [useNgContent]="true"
                >
                  <div class="icon-attachment-square text-color_secondary"></div>
                </app-upload-window>
              </div>

              <div class="separator second-row row-start-1 row-end-3 col-start-6"></div>

              <div class="row-start-1 row-end-3 col-start-7 item pl-5 pt-5 pb-5 h-full box-border">
                @if (item?.files?.length) {
                  <div class="grid grid-cols-3 gap-x-2.5 gap-y-1.5 pr-4">
                    <div
                      [showDelay]="300"
                      [pTooltip]="file.name"
                      tooltipPosition="bottom"
                      *ngFor="let file of item.files"
                      class="file"
                    >
                      <div class="overflow-hidden text-ellipsis">
                        {{ file.name }}
                      </div>
                      <app-un-styled-options-list
                        (selectedOption)="registerFileSelected($event, file)"
                        [listItems]="fileOptions"
                        class="flex items-center justify-center"
                        #fileOption
                      >
                        <ng-template #projectedContent>
                          <span
                            (click)="fileOption.optionsListGeneralComponent.toggleShow()"
                            class="icon-options-inverted text-base cursor-pointer"
                          ></span>
                        </ng-template>
                      </app-un-styled-options-list>
                    </div>
                  </div>
                } @else {
                  <div class="text-shade_2 font-semibold text-sm flex items-center">No files</div>
                }
              </div>
            </div>
            <div class="grid-row content-row auto-rows pb-2">
              <div
                [ngClass]="{ 'cursor-not-allowed': disableUpdates || currentlyUpdating.size > 0 }"
                class="item note col-span-5 box-border pb-5 pr-5"
              >
                <div
                  [ngClass]="{ disabled: disableUpdates || currentlyUpdating.size > 0 }"
                  (click)="textAreaElement.focus()"
                  class="textarea-wrapper cursor-text"
                >
                  <textarea
                    class="flex"
                    #textAreaElement
                    rows="1"
                    cdkTextareaAutosize
                    [disabled]="disableUpdates || currentlyUpdating.size > 0"
                    [id]="'note_' + item.id"
                    [name]="'note_' + item.id"
                    [(ngModel)]="item.note"
                    (change)="updateItemNote(progressItem, checklistItemIndex, $event.target.value)"
                    placeholder="Optional Note (reporting will include top 5 lines)"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </app-expansion-panel>
  </div>

  <div tableFooter class="grid-table totals">
    <div class="grid-row">
      <div class="item">{{ (getTotals$ | async)?.totalItems }} Items</div>
      <div class="item"></div>
      <div class="item progress">
        <app-progress-bar-projectable
          [percent]="(getTotals$ | async)?.work_percentage"
          [allowBgColorChange]="false"
          percentWidth="big"
          fillClass="bg-shade_1"
          text="WORK"
        >
        </app-progress-bar-projectable>
      </div>
    </div>
  </div>
</app-dynamic-projected-table>
