<div class="custom-overlay-wrapper">
  <app-mention-overlay *ngIf="selectedView === VIEWS.DEFAULT"></app-mention-overlay>
  <app-options-list-styled
    *ngIf="selectedView === VIEWS.STYLED_OPTIONS_LIST"
    (selectedOption)="registerSelectedOption($event)"
    [listItems]="data?.listItems ?? []"
    [disabledList]="data?.disabledList ?? []"
  ></app-options-list-styled>
  <app-img-preview *ngIf="selectedView === VIEWS.CROPPER" [data]="data"></app-img-preview>
</div>
