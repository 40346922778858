import { CommonModule, Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil, timer } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink],
})
export class LandingHeaderComponent implements AfterViewInit, OnDestroy {
  isDestroyed$ = new Subject();
  currentFragment: string | null;

  intersectionObserver: IntersectionObserver;
  scrollToTimestamp = 0;

  headerHeight = 0;
  @ViewChild('header') set header(el: ElementRef) {
    this.headerHeight = el.nativeElement.clientHeight;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private platformService: PlatformService,
    private notif: NotificationsService,
    private location: Location,
  ) {}

  ngAfterViewInit(): void {
    this.adjustScrollPosition(); // adjusts navbar scrolling to exclude the header height
    this.reactToManualScroll(); // highlights the right menu item when scrolling by scroll wheel
  }

  redirectToLogin() {
    if (this.platformService.isIOS()) {
      this.openIOSApp();
    } else if (this.platformService.isAndroid()) {
      this.notif.showError(
        'Android is not supported yet. Try our app on IOS or on a desktop browser.',
      );
    } else {
      this.router.navigate(['auth/login'], { fragment: undefined });
    }
  }

  private reactToManualScroll() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const enoughTimeHasPassed = Date.now() - this.scrollToTimestamp > 1000;
          if (entry.isIntersecting && enoughTimeHasPassed) {
            this.setActiveFragment(entry.target.id);
          }
        });
      },
      {
        root: null, // Relative to document viewport
        rootMargin: `-10% 0px -80% 0px`, // Margin around the root
        threshold: 0, // Trigger when any part of the section hits the line
      },
    );

    // detect <section> elements with an id attribute
    document.querySelectorAll('section[id]').forEach((section) => {
      this.intersectionObserver.observe(section);
    });
  }

  // TODO: scroll to fragment breaks if user refreshes url without changing the fragment, since no nav event is fired
  private adjustScrollPosition(): void {
    // Detect fragment changes even when clicking the same link again
    this.router.events.pipe(takeUntil(this.isDestroyed$)).subscribe((event) => {
      if (event.type === 15) {
        this.scrollToFragment();
      }
    });
  }

  private scrollToFragment(): void {
    const fragment = this.route.snapshot.fragment; // Get the current fragment

    if (fragment) {
      this.setActiveFragment(fragment);
      this.scrollToTimestamp = Date.now();
      // run just after the built-in scrolling to make sure to overwrite it
      timer(10).subscribe(() => {
        const targetElement = document.getElementById(fragment);

        if (targetElement && this.headerHeight) {
          const elementPosition = targetElement.offsetTop;

          // Adjust scroll position by subtracting the header height
          window.scrollTo({
            top: elementPosition - this.headerHeight,
            behavior: 'smooth',
          });
        }
      });
    }
  }

  private setActiveFragment(fragment: string | null) {
    this.currentFragment = fragment;
    if (fragment === null) {
      this.location.replaceState(this.location.path().split('#')[0]);
    } else {
      this.location.replaceState(`${this.location.path()}#${fragment}`);
    }
  }
  private openIOSApp() {
    const appScheme = 'skillhop://';
    window.location.href = appScheme;
  }

  ngOnDestroy(): void {
    this.intersectionObserver.disconnect();
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
