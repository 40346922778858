<div class="default-overlay-wrapper">
  <ng-scrollbar *ngIf="!isLoading" class="std-scrollbar" orientation="vertical" appearance="native">
    <div
      aria-hidden="true"
      *ngFor="let item of overlayService.data$ | async; let i = index"
      (click)="selectItem(item)"
      class="item"
      [class.keyboardSelection]="overlayService.keyboardSelectionIsActive && overlayService.keyboardSelection === i"
    >
      {{ item.name }}
    </div>
  </ng-scrollbar>
  <div class="h-100 flex justify-center items-center" *ngIf="isLoading">
    <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
  </div>
</div>
