<section id="workflow" class="flex justify-center items-center bg-blue_500">
  <div class="landing-section my-16 max-lg:my-8">
    <div class="flex justify-between items-center mb-10">
      <h2 class="text-2xl font-semibold text-white">How BuildEvo Works</h2>
      <div class="flex items-center max-lg:hidden">
        <button class="arrow-button" (click)="prevStep()" [disabled]="currentStepIndex() === 0 ? true : null">
          <i class="icon-arrow-round-left"></i>
        </button>
        <button
          class="arrow-button"
          (click)="nextStep()"
          [disabled]="currentStepIndex() === workflowSteps.length - 1 ? true : null"
        >
          <i class="icon-arrow-round-right"></i>
        </button>
      </div>
    </div>
    <div class="max-lg:overflow-x-auto">
      <div class="flex gap-8 max-lg:w-fit">
        @for (step of workflowSteps; track step.index) {
          <div
            *ngIf="isRelevant()(step.index)"
            class="flex flex-col items-center justify-end max-lg:!basis-auto max-lg:!min-w-[350px] max-lg:!block"
            [ngClass]="{
              'basis-1/4': step.index !== currentStepIndex(),
              'basis-1/2': step.index === currentStepIndex()
            }"
          >
            <img [src]="step.imageUrl" [alt]="step.imageAlt" class="w-full select-none pointer-events-none" />
            <div
              class="w-full text-white mt-4 max-lg:!opacity-100"
              [ngClass]="{
                'opacity-40': step.index !== currentStepIndex()
              }"
            >
              <div class="bg-[currentColor] h-1 rounded-md"></div>
              <div class="flex justify-between items-start mt-4">
                <div>
                  <h3 class="my-0 text-lg font-semibold">
                    {{ step.title }}
                  </h3>
                  <p class="mt-1 mb-0 text-lg leading-normal h-24">
                    {{ step.description }}
                  </p>
                </div>
                <span
                  class="text-lg font-semibold text-white text-nowrap max-lg:!block"
                  *ngIf="step.index === currentStepIndex()"
                  >STEP {{ step.index + 1 }}</span
                >
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</section>
