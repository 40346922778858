<app-hidden-upload-input
  #simpleUploadUiComponent
  (filesChange)="onFileInputChange($event)"
  [allowMultipleFiles]="allowMultipleFiles"
></app-hidden-upload-input>

<!-- Custom content projected from outside -->
<ng-container *ngIf="projectedContentTemplate; else defaultContent">
  <ng-container *ngTemplateOutlet="projectedContentTemplate"></ng-container>
</ng-container>

<!-- Default content if no content projection from outside -->
<ng-template #defaultContent>
  <app-default-upload-ui
    (fileDropped)="onFileDropped($event)"
    (openWindow)="simpleUploadUiComponent.openFileUploadWindow()"
    (removeFile)="onRemoveFile($event)"
    [disableUpload]="disableUpload"
    [files]="files"
    [hideRemoveFileIcon]="hideRemoveFileIcon"
  ></app-default-upload-ui>
</ng-template>
