<div class="messages-threads-view-wrapper h-100 flex flex-col">
  <div class="flex-1 min-h-0">
    <ng-scrollbar
      *ngIf="!(isLoading$ | async)"
      #scrollbar
      class="std-scrollbar"
      orientation="vertical"
      appearance="native"
    >
      <div [ngClass]="{ 'mr-3': scrollbar.isVerticallyScrollable() }">
        <div class="main-message-body pt-4 pb-3">
          <app-message-item [hideReplies]="true" [message]="threadMessages?.mainMessage"></app-message-item>
        </div>
        <div
          *ngFor="let threadMessage of threadMessages?.replies; trackBy: trackByFn"
          class="py-5 pl-10 border-b border-solid border-shade_5 first-child:py-0"
        >
          <app-message-item [hideReplies]="true" [message]="threadMessage"></app-message-item>
        </div>
      </div>
    </ng-scrollbar>

    <div class="h-100 flex justify-center items-center" *ngIf="isLoading$ | async">
      <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
    </div>
  </div>

  <div class="border-separator"></div>
  @if (!draftLoaded) {
    <div class="py-2 flex w-full items-center justify-center h-[244px]">
      <div class="notify-msg animate-pulsating-border">
        <div class="icon-logo-box-hex"></div>
      </div>
    </div>
  } @else {
    <app-rich-text-editor
      [editorValue]="editorValue"
      (editorValueChange)="onEditorValueChange($event)"
      [userMentions]="usersData.discussionMembers"
      [projectMentions]="projectMentions$ | async"
      [allowFileUpload]="true"
      [filesToUpload]="filesToUpload$ | async"
      (filesToUploadChange)="registerFileUpload($event)"
      (removedFileToUpload)="removeFile($event)"
      enterKeyPressBehaviour="custom"
      (enterKeyPress)="footerService.emitSave()"
      class="max-h-96"
    ></app-rich-text-editor>
  }
</div>
