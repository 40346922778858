<mat-sidenav-container class="menu-container" fullscreen>
  <div [ngClass]="{ 'shift-menu-bar-trigger': isMobileViewOpen }" class="menu-bar-trigger">
    <app-arrow-button-box
      (click)="isMobileViewOpen = !isMobileViewOpen"
      [direction]="isMobileViewOpen ? 'left' : 'right'"
      thickness="light"
    ></app-arrow-button-box>
    <!--      [isFilled]="isMobileViewOpen"-->
    <!--    <button (click)="isMobileViewOpen = !isMobileViewOpen" class="btn-small" mat-button>-->
    <!--      <span class="icon-options"></span>-->
    <!--    </button>-->
  </div>
  <mat-sidenav
    [disableClose]="true"
    [ngClass]="{ 'mobile-open': isMobileViewOpen }"
    class="menu-bar"
    mode="side"
    opened
  >
    <div class="menu-items">
      <a *ngIf="user.isLoggedIn()" [routerLink]="['/landing']" class="logo">
        <img src="assets/icons/buildevo_logo_white.svg" alt="Buildevo Logo" />
      </a>

      <div *ngIf="user.data" class="menu-options">
        <div class="" [ngClass]="{ hidden: !user.isManager }">
          <a
            (click)="clearLineItems()"
            matTooltipPosition="right"
            matTooltipClass="menu-tooltip"
            [matTooltip]="'Add'"
            [ngClass]="{ hidden: !user.isManager, active: activeButton === MENU_PAGES.ADD }"
            class="btn icon"
            [matMenuTriggerFor]="createMenu"
          >
            <div class="flex flex-col items-center gap-0.5">
              <span class="icon-add-block-square"></span>
              <span class="text-white">Add</span>
            </div>
          </a>
          <mat-menu class="mat-menu-container open-below" #createMenu xPosition="after" yPosition="below">
            <button (click)="openActivitySidebar()" class="menu-btn" mat-menu-item>Add Daily Activity</button>
            <button (click)="openProjectSetup()" class="menu-btn" mat-menu-item>Add Project</button>
          </mat-menu>
        </div>
        <a
          (click)="setActive(MENU_PAGES.ACTIVITIES)"
          [routerLink]="['/webapp/activities']"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="'Activities'"
          class="btn icon"
          [ngClass]="{ active: activeButton === MENU_PAGES.ACTIVITIES }"
          *ngIf="user.isManager"
        >
          <div class="flex flex-col items-center gap-0.5">
            <span class="icon-activities items-center"></span>
            <span class="text-white">Activities</span>
          </div>
        </a>

        <a
          (click)="setActive(MENU_PAGES.PROJECTS)"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="'Projects'"
          [routerLink]="['/webapp/projects']"
          class="btn icon"
          [ngClass]="{ active: activeButton === MENU_PAGES.PROJECTS }"
        >
          <div class="flex flex-col items-center gap-0.5">
            <span class="icon-projects"></span>
            <span class="text-white">Projects</span>
          </div></a
        >
        <a
          (click)="setActive(MENU_PAGES.CASHFLOW)"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="'Cashflow'"
          [ngClass]="{
            hidden: !user.isManager,
            active: activeButton === MENU_PAGES.CASHFLOW
          }"
          [routerLink]="['/webapp/cashflow']"
          class="btn icon"
        >
          <div class="flex flex-col items-center gap-0.5">
            <span class="icon-cashflow"></span>
            <span class="text-white">Cashflow</span>
          </div>
        </a>
        <a
          (click)="setActive(MENU_PAGES.CONTRACTORS)"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="isApproved ? 'Service Providers' : 'Pending Account Verification'"
          [ngClass]="{
            hidden: !user.isManager,
            'menu-disabled': !isApproved,
            active: activeButton === MENU_PAGES.CONTRACTORS
          }"
          [routerLink]="['/webapp/contractors']"
          class="btn icon"
        >
          <div class="flex flex-col items-center gap-0.5">
            <span class="icon-service-providers"></span>
            <span class="text-white">Contacts</span>
          </div>
        </a>
        <a
          (click)="setActive(MENU_PAGES.DOCUMENTS)"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="isApproved ? 'BuildEvo Drive' : 'Pending Account Verification'"
          [routerLink]="['/webapp/documents']"
          class="btn icon"
          [ngClass]="{ 'menu-disabled': !isApproved, active: activeButton === MENU_PAGES.DOCUMENTS }"
        >
          <div class="flex flex-col items-center gap-0.5">
            <span class="icon-drive"></span>
            <span class="text-white">Storage</span>
          </div>
        </a>
        <a
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="'Coming Soon'"
          class="btn icon disp-none"
          ><span class="icon-approvals"></span
        ></a>
        <a
          *ngIf="isManager"
          matTooltip="Generate Report"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          class="btn icon"
          [matMenuTriggerFor]="reportingMenu"
          [ngClass]="{ 'menu-disabled': !isApproved, active: activeButton === MENU_PAGES.PRINT }"
        >
          <div class="flex flex-col items-center gap-0.5">
            <span class="icon-print-report-2"></span>
            <span class="text-white">Reporting</span>
          </div>
        </a>
        <mat-menu class="mat-menu-container open-below" #reportingMenu xPosition="after" yPosition="below">
          <button [ngClass]="{ disabled: disablePrintPage }" (click)="printPageReport()" class="menu-btn" mat-menu-item>
            Generate Page Report
          </button>
          <button (click)="openReports()" class="menu-btn" mat-menu-item>Generate Full Report</button>
          <button (click)="openInvoiceReports()" class="menu-btn" mat-menu-item>Invoice Log</button>
        </mat-menu>
      </div>

      <div [ngClass]="{ 'visibility-hidden': !user.isLoggedIn() }" class="menu-options bottom-items">
        <a
          (click)="openDrawer(INTERACTION_BAR_STATES.NOTIFICATIONS)"
          [ngClass]="{ active: drawerState?.action === INTERACTION_BAR_STATES.NOTIFICATIONS }"
          class="btn"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="'Notifications'"
        >
          <span class="icon-bell"></span>
          <div
            *ngIf="(barState.notifCount | async) > 0 || unreadStatus.has_unseen_notifications"
            class="unread-bullet"
          ></div>
        </a>
        <a
          (click)="openMessages()"
          [ngClass]="{ active: drawerState?.action === INTERACTION_BAR_STATES.MESSAGES }"
          class="btn"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="'Messages'"
        >
          <span class="icon-messages"></span>
          <div *ngIf="(hasUnreadMessages$ | async) || unreadStatus.has_unread_messages" class="unread-bullet"></div>
        </a>
        <a
          (click)="openIntercom()"
          class="btn"
          matTooltipPosition="right"
          matTooltipClass="menu-tooltip"
          [matTooltip]="'Helpdesk and documentation'"
        >
          <span class="icon-question-mark"></span>
        </a>
        <button
          matTooltipPosition="right"
          [matTooltip]="user.isLoggedIn() ? 'Settings' : 'Authentication'"
          class="btn icon settings cursor-pointer"
          [matMenuTriggerFor]="settingsMenu"
        >
          <div *ngIf="user.data ? user?.data?.profile_picture_url : false" class="profile-pic">
            <img
              src="{{ user.data ? user?.data?.profile_picture_url : '' }}"
              [ngClass]="{ 'disp-none': picLoading }"
              (load)="profilePicLoaded()"
            />
            <span class="icon-refresh animate-spin pic-loading" *ngIf="picLoading"></span>
          </div>
          <span *ngIf="user.isLoggedIn() && !user?.data?.profile_picture_url" class="icon-settings"></span>
          <span *ngIf="!user.isLoggedIn()" class="icon-logo-box-hex"></span>
        </button>

        <mat-menu class="mat-menu-container settings" #settingsMenu="matMenu" xPosition="after" yPosition="above">
          <a
            (click)="setActive(MENU_PAGES.SETTINGS)"
            *ngIf="user.isLoggedIn() && user.isManager"
            [routerLink]="['/webapp/settings/properties']"
            class="menu-btn menu-property"
            mat-menu-item
          >
            <span class="menu-button-container">
              <span class="icon-properties"></span>
              <span> Properties </span>
            </span>
          </a>
          <a
            (click)="setActive(MENU_PAGES.SETTINGS)"
            *ngIf="isManager && user.isLoggedIn()"
            [routerLink]="['/webapp/teams']"
            class="menu-btn menu-property"
            mat-menu-item
          >
            <span class="menu-button-container">
              <span class="icon-team"></span>
              <span> Team </span>
            </span>
          </a>
          <a
            (click)="setActive(MENU_PAGES.SETTINGS)"
            *ngIf="isManager && user.isLoggedIn()"
            [routerLink]="['/webapp/spend-templates']"
            class="menu-btn menu-property"
            mat-menu-item
          >
            <span class="menu-button-container">
              <span class="icon-templates"></span>
              <span> Templates </span>
            </span>
          </a>
          <a
            (click)="setActive(MENU_PAGES.SETTINGS)"
            *ngIf="user.isLoggedIn()"
            [routerLink]="['/webapp/settings']"
            class="menu-btn menu-account"
            mat-menu-item
          >
            <span class="menu-button-container">
              <span class="icon-account"></span>
              <span> Account </span>
            </span>
          </a>
          <button *ngIf="user.isLoggedIn()" (click)="logout()" class="menu-btn menu-logout" mat-menu-item>
            <span class="menu-button-container">
              <span class="icon-logout"></span>
              <span> Logout </span>
            </span>
          </button>
          <button *ngIf="!user.isLoggedIn()" [routerLink]="['/auth/login']" class="menu-btn menu-logout" mat-menu-item>
            Login
          </button>
          <button
            *ngIf="!user.isLoggedIn()"
            [routerLink]="['/auth/register']"
            class="menu-btn menu-logout"
            mat-menu-item
          >
            Register Account
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-sidenav>

  <ng-content></ng-content>
</mat-sidenav-container>
