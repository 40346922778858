<div>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@tabler/icons-webfont@latest/dist/tabler-icons.min.css" />
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap" rel="stylesheet" />
  <link href="https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@400&display=swap" rel="stylesheet" />

  <app-landing-header></app-landing-header>
  <app-hero-section></app-hero-section>
  <app-features-section></app-features-section>
  <app-use-cases-section></app-use-cases-section>
  <app-workflow-section></app-workflow-section>
  <app-pricing-section></app-pricing-section>
  <app-partners-section></app-partners-section>
  <app-about-section></app-about-section>
  <app-landing-footer></app-landing-footer>
</div>
