<div [@fadeInGeneral] class="help-container mt-4 mb-4">
  <ng-container *ngIf="type === COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT">
    <div class="title">Adding a Contract</div>
    <p>
      In this section you can add contracts that you have with each vendor or service provider. While it is optional to
      upload the actual document, we highly recommend that you do so in order to keep one source of truth for all
      parties involved.
    </p>

    <p>
      In addition to uploading your contract, you can add details ourlining the scope of work, in terms of the
      description, as well as the allocation of the expected spend.
    </p>
    <p>
      Having the spend detail broken down will help you later track the progress of the contract as well as the spend
      activity, so you know where your money is being spent.
    </p>
    <p>
      One last point here, you as the manager can delegate this task to the vendor to perform, if you would like. Simply
      invite the vendor to the project to allow him access to this feature. Note: the vendors are restricted to the
      information that pertains to them.
    </p>
    <p>
      If you have more questions about this area or you need more specific help, please reach out to us at
      <a href="mailto:contact@buildevo.com" class="email">contact&#64;buildevo.com</a>.
    </p>
  </ng-container>

  <ng-container *ngIf="type === COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER">
    <div class="title">Adding a Change Order</div>

    <p>
      Similar to adding a contract, a “change order” is a change to a contract. This section allows you and your vendors
      to submit such changes to track either changes in scope that impact project budgets or changes to the schedule
      that impact the completion deadline of a project, without any cost impacts.
    </p>
    <p>
      The details inputted here are to help the managers track the exact details behind the change in terms of the why,
      the how much, and the when.
    </p>
    <p>
      If you need more help with this section, please reach out to us and we’ll be happy to assist,
      <a href="mailto:contact@buildevo.com" class="email">contact&#64;buildevo.com</a>.
    </p>
  </ng-container>
</div>
