import { Injectable } from '@angular/core';

/**
 * This abstract class contains the common logic for all the pages/tabs of the Add Project flow. <br/>
 * There are default values, if no custom logic is needed.
 */
@Injectable()
export abstract class AddProjectBase {
  protected constructor() {}

  /**
   * Called when a child page should update itself. <br/>
   * Currently used only on Spend Distribution to update scrollbars and content.
   */
  update(): void {}

  /**
   * Indicates if a page contains valid data and we can navigate further.
   */
  async isValid(): Promise<boolean> {
    // default value, should be override if custom logic is needed
    return true;
  }

  /**
   * Indicates if we can go to the previous page. <br/>
   * Should be false if there is some validation problem (budget is locked);
   */
  async canGoBack(): Promise<boolean> {
    // default value, should be override if custom logic is needed
    return true;
  }
}
