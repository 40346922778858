import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class HeroSectionComponent {
  constructor(
    private platformService: PlatformService,
    private router: Router,
    private notif: NotificationsService,
  ) {}

  tryBuildevo() {
    if (this.platformService.isAndroid()) {
      this.notif.showError(
        'Android is not supported yet. Try our app on IOS or on a desktop browser.',
      );
    } else if (this.platformService.isIOS()) {
      this.openIOSAppstore();
    } else {
      this.router.navigate(['/auth/register']);
    }
  }

  private openIOSAppstore() {
    const appStoreUrl = 'https://apps.apple.com/ro/app/skillhop/id1576722080';
    window.location.href = appStoreUrl;
  }
}
