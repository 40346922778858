import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

interface ImageInfo {
  src: string;
  alt: string;
}

@Component({
  selector: 'app-feature-card',
  standalone: true,
  imports: [CommonModule],
  template: `
    <article class="h-full rounded-3xl">
      <div class="flex flex-col gap-4 mb-8">
        <img
          [src]="image.src"
          [alt]="image.alt"
          class="w-full rounded select-none pointer-events-none"
        />
      </div>
      <h3 class="mb-1 text-lg font-semibold text-black_500 select-none">{{ title }}</h3>
      <p class="mt-0 text-lg leading-normal text-grey_900 select-none">{{ description }}</p>
    </article>
  `,
})
export class FeatureCardComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() image: ImageInfo;
}
