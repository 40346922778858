import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { interval, startWith, Subject, switchMap, takeUntil } from 'rxjs';

interface WorkflowStep {
  index: number;
  title: string;
  description: string;
  imageUrl: string;
  imageAlt: string;
}

@Component({
  selector: 'app-workflow-section',
  templateUrl: './workflow-section.component.html',
  styleUrls: ['./workflow-section.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class WorkflowSectionComponent implements OnInit, OnDestroy {
  private isDestroyed$ = new Subject<void>();
  private reset$ = new Subject<void>();

  currentStepIndex = signal(0);

  workflowSteps: WorkflowStep[] = [
    {
      index: 0,
      title: 'Quick Setup',
      description: 'Establish your foundation with customizable templates and team invitations.',
      imageUrl: 'assets/images/landing-workflow/1.webp',
      imageAlt: 'Setup Process',
    },
    {
      index: 1,
      title: 'Project Initiation',
      description: 'Create projects and set budgets with ease.',
      imageUrl: 'assets/images/landing-workflow/2.webp',
      imageAlt: 'Project Initiation',
    },
    {
      index: 2,
      title: 'Collaboration',
      description: 'Invite service providers and upload contracts securely.',
      imageUrl: 'assets/images/landing-workflow/3.webp',
      imageAlt: 'Service Providers',
    },
    {
      index: 3,
      title: 'Execution',
      description: 'Manage documents, changes, and invoices throughout the project lifecycle.',
      imageUrl: 'assets/images/landing-workflow/4.webp',
      imageAlt: 'Manage Projects',
    },
    {
      index: 4,
      title: 'Analysis',
      description:
        'Access clear data, generate comprehensive reports, and make informed decisions.',
      imageUrl: 'assets/images/landing-workflow/5.webp',
      imageAlt: 'Analysis',
    },
  ];

  ngOnInit() {
    this.reset$
      .pipe(
        startWith(0), // Start immediately upon component load
        switchMap(() => interval(5000)), // Restart interval on reset trigger
        takeUntil(this.isDestroyed$),
      )
      .subscribe(() => {
        // TODO: fix this causing scroll
        this.nextStep();
      });
  }

  isRelevant = computed(() => {
    const stepIndex = this.currentStepIndex();
    const lastIndex = this.workflowSteps.length - 1;

    if (stepIndex === 0) {
      return (index: number) => index >= 0 && index <= 2;
    } else if (stepIndex === lastIndex) {
      return (index: number) => index >= lastIndex - 2 && index <= lastIndex;
    } else {
      return (index: number) => index >= stepIndex - 1 && index <= stepIndex + 1;
    }
  });

  nextStep(): void {
    const newIndex = (this.currentStepIndex() + 1) % this.workflowSteps.length;
    if (newIndex !== this.currentStepIndex()) {
      this.currentStepIndex.set(newIndex);
      this.reset$.next(); // Reset timer only when index changes
    }
  }

  prevStep(): void {
    const newIndex =
      (this.currentStepIndex() - 1 + this.workflowSteps.length) % this.workflowSteps.length;
    if (newIndex !== this.currentStepIndex()) {
      this.currentStepIndex.set(newIndex);
      this.reset$.next(); // Reset timer only when index changes
    }
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
