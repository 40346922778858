<section id="pricing" class="flex justify-center items-center text-black_500 bg-grey_100">
  <div class="landing-section flex flex-col py-20 max-lg:py-14 max-lg:items-center">
    <h2 class="mb-3 text-2xl font-semibold w-full">Choose a plan that's right for you</h2>
    <p class="mb-10 text-lg w-full max-w-[43.75rem]">
      Our tiered pricing structure reflects the depth of features and usage patterns of different user types, ensuring
      you only pay for the capabilities you need.
    </p>
    <div class="grid gap-8 grid-cols-[repeat(3,1fr)] w-full justify-items-center max-lg:grid-cols-[1fr] max-lg:gap-4">
      <app-pricing-card
        title="Owners"
        subtitle="Volume discounts available"
        [price]="basicPlan?.stripe_monthly_price ?? ''"
        period="/ user per month"
        buttonText="Start free trial"
        [showStartingAt]="true"
        [isLoading]="arePlansLoading"
        (buttonClick)="router.navigate(['auth', 'register'], { queryParams: { selected_plan: 'basic' } })"
      ></app-pricing-card>

      <!-- premiumPlan?.stripe_monthly_price -->
      <app-pricing-card
        title="Contractors"
        subtitle="Advanced features for high usage"
        [price]="399"
        period="/ user per month"
        buttonText="Coming soon"
        [isDisabled]="true"
        [showStartingAt]="false"
        [isLoading]="arePlansLoading"
        (buttonClick)="router.navigate(['auth', 'register'], { queryParams: { selected_plan: 'premium' } })"
      ></app-pricing-card>

      <app-pricing-card
        title="System Integrations"
        subtitle="Seamless tool connectivity"
        price="999"
        period=""
        buttonText="Coming soon"
        [isDisabled]="true"
        [showStartingAt]="true"
        [isLoading]="arePlansLoading"
        (buttonClick)="openIntercom()"
      ></app-pricing-card>
    </div>
  </div>
</section>
