<div class="p-8 bg-white text-black_500 rounded-xl shadow-[0_2px_5px_rgba(0,0,0,0.1)]">
  <div class="mb-8">
    <h3 class="text-lg font-bold">{{ title }}</h3>
    <p class="text-lg text-grey_900 h-14">{{ subtitle }}</p>
  </div>
  <div class="mb-6 h-[3.75rem]">
    <span [class.invisible]="!showStartingAt" class="mb-2 text-sm text-grey_900">Starting at</span>
    @if (isLoading) {
      <div class="animate-pulse h-5 bg-grey_800 rounded-md dark:bg-gray-700 w-full max-w-36 mt-2"></div>
    } @else {
      <div class="flex gap-2 items-baseline text-3xl">
        <span class="font-semibold text-black_500">{{ '$' + price }}</span>
        <span [class.invisible]="period === ''" class="text-sm text-grey_900">{{ period }}</span>
      </div>
    }
  </div>
  <button class="be-button" [disabled]="isDisabled" (click)="buttonClick.emit()">
    {{ buttonText }}
  </button>
</div>
