import { Component, inject, OnDestroy } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { MemoizedSelector, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { REST_DRIVE_FILES } from 'src/app/restApi/RestRoutes';
import { MessagingState } from 'src/app/store/messages/messages.reducer';
import { selectMentionProjects } from 'src/app/store/projects/projects.selectors';
import { MessagingFooterService } from '../../../services/messaging-footer.service';
import { PresignedFileUploadService } from '../../../services/presigned-file-upload.service';
import { AppState } from '../../../store/app-state';
import { addFiles, cleanFiles, removeFile } from '../../../store/messages/messages.actions';
import { IMessage, IMessagingUserListData } from '../../../store/messages/messages.interfaces';
import {
  getDiscussionMembers,
  getFilesToUpload,
  getLastNonDraftSentMessageId,
  getSearchedMessagingMembers,
} from '../../../store/messages/messages.selectors';
import { IFilesToUpload } from '../discussions-view/discussions-view.component';

@Component({
  template: ``,
  standalone: true,
})
/**
 * This component is not meant to be used alone.
 * Instead, it should be extended by custom messaging components.
 * It contains the common variables and functions of the child components.
 */
export abstract class DiscussionViewCommonComponent implements OnDestroy {
  private _editorValue = '';
  get editorValue() {
    return this._editorValue;
  }

  private presignedFileUploadService = inject(PresignedFileUploadService);
  protected footerService = inject(MessagingFooterService);
  protected store = inject<Store<AppState>>(Store);

  usersData: IMessagingUserListData = {
    usersRelated: [],
    discussionMembers: [],
  };
  itemOptions: string[];
  showRelatedUsers = false;

  public REST_DRIVE_FILES = REST_DRIVE_FILES;
  uploadMetaData: { message_id: number };
  shouldScrollToBottom = false;

  searchedMessagingMembers$ = this.store.select(getSearchedMessagingMembers);
  discussionMembers$ = this.store.select(getDiscussionMembers);

  // projects that appear as mentions in the RTE
  projectMentions$ = this.store.select(selectMentionProjects);

  lastNonDraftSentMessageId$ = this.store.select(getLastNonDraftSentMessageId);
  filesToUpload$ = this.store.select(getFilesToUpload);
  isDestroyed$: Subject<boolean> = new Subject<boolean>();

  draftLoaded = false;

  abstract saveDraft(message: string);

  onEditorValueChange(value: string) {
    this._editorValue = value;
    this.saveDraft(value);
  }

  /**
   * Load draft message if exists once.
   * @private
   */
  protected handleDraft(
    draftSelector: MemoizedSelector<object, IMessage, (s1: MessagingState) => IMessage>,
  ) {
    this.store
      .select(draftSelector)
      .pipe(takeUntil(this.isDestroyed$), take(1))
      .subscribe((draft) => {
        this.draftLoaded = true; // TODO: would be nice to rely only on the draft value or have a special selector for this
        this._editorValue = draft?.body ?? '';
      });
  }

  protected handleSaveEvent() {
    this.footerService.save.pipe(takeUntil(this.isDestroyed$)).subscribe((savePressed) => {
      if (savePressed) {
        this.shouldScrollToBottom = true;
        this._editorValue = '';
      }
    });
  }

  protected handleChatMembers() {
    // TODO: make searchedMessagingMembers$ and discussionMembers$ an array in the store
    // this way the selector always creates a new array every time a keystroke is pressed and this func is called
    combineLatest([this.searchedMessagingMembers$, this.discussionMembers$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([usersRelated, discussionMembers]) => {
        this.itemOptions = usersRelated.map((user) => user.name);
        const filteredUsersRelated = usersRelated.filter(
          (user) => !discussionMembers.find((discMember) => discMember.user_id === user.user_id),
        );
        // console.log('chat members changed', discussionMembers);
        this.usersData = {
          usersRelated: this.showRelatedUsers ? filteredUsersRelated : discussionMembers,
          discussionMembers,
        };
      });
  }

  protected handleUpload(onSuccess?: () => void) {
    this.lastNonDraftSentMessageId$
      .pipe(
        concatLatestFrom(() => this.filesToUpload$),
        takeUntil(this.isDestroyed$),
      )
      .subscribe(([id, filesToUpload]) => {
        this.uploadMetaData = { message_id: id };
        if (filesToUpload.length) {
          const files = filesToUpload.map((f) => f.file);
          setTimeout(() => {
            this.presignedFileUploadService
              .uploadMultipleFilesToS3(
                files,
                files.map((_) => this.uploadMetaData),
              )
              .subscribe({
                next: (data) => {
                  console.log('upload success', data);
                  onSuccess();
                },
                error: (error) => {
                  console.error('upload error', error);
                },
              });
            this.store.dispatch(cleanFiles());
          }, 0);
        }
      });
  }

  registerFileUpload(filesToUpload: IFilesToUpload[]) {
    this.store.dispatch(addFiles({ files: filesToUpload }));
  }

  removeFile(event: { file: IFilesToUpload; index: number }) {
    this.store.dispatch(removeFile(event));
  }

  ngOnDestroy() {
    this.draftLoaded = false;
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
