import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';

/*
  This component inserts an svg dynamically into the DOM, such that it can be styled with CSS.
*/
@Component({
  selector: 'app-svg',
  template: `<div [innerHTML]="svg | async"></div>`,
  standalone: true,
  imports: [CommonModule],
})
export class SvgComponent implements OnInit {
  @Input({ required: true }) src;
  svg: Observable<SafeHtml>;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.svg = this.getSVG(this.src);
  }

  private getSVG(url: string): Observable<SafeHtml> {
    return this.http
      .get(url, { responseType: 'text' })
      .pipe(map((svg: string) => this.sanitizer.bypassSecurityTrustHtml(svg)));
  }
}
