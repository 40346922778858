<section id="features" class="flex justify-center items-center py-20 bg-grey_100">
  <div class="landing-section px-0">
    <h2 class="landing-x-padding mb-10 text-2xl font-bold">Powerful Features to Keep Your Projects on Track</h2>
    <div class="overflow-x-auto scrollbar-hide" appDragScroll>
      <div class="landing-x-padding features-grid">
        <app-feature-card
          *ngFor="let feature of features"
          [title]="feature.title"
          [description]="feature.description"
          [image]="feature.image"
        ></app-feature-card>
      </div>
    </div>
  </div>
</section>
